import React, { useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { GlobalContext } from "contexts/GlobalStore";
import { UserContext } from "contexts/UserStore.js";
import { BackEmblem } from "../BackEmblem/BackEmblem.js";
import { CreateNew } from "./CreateNew.js";
import { SelectStatus } from "./SelectStatus.js";
import { SelectType } from "./SelectType.js";
import { UserSettings } from "./UserSettings.js";
import { Contact } from "./Contact.js";
import { NavButton } from "./NavButton.js";
import { NavButtonMD } from "./NavButtonMD.js";
import { NavButtonContact } from "./NavButtonContact.js";
import "./Sidebar.scss";

export const Sidebar = (props) => {
    const history = useHistory();
    const { sidebarBehavior, setSidebarBehavior, pageName, goToHome } = useContext(GlobalContext);
    const { userState, isAuthenticated } = useContext(UserContext);
    const [disableOnMouseLeave, setDisableOnMouseLeave] = useState(false);

    /**
     * HANDLE CONTENT
     * @description changes sidebar behaviour in global store
     * @param {string} newContent
     */
    const handleContent = (newContent) => {
        if (newContent === sidebarBehavior.content) {
            return;
        }
        setDisableOnMouseLeave(true);
        setSidebarBehavior((prev) => ({
            ...prev,
            content: newContent,
        }));
        delayDisableMouseLeave();
    };

    const delayDisableMouseLeave = useCallback(
        _.debounce(async (obj) => {
            setDisableOnMouseLeave(false);
        }, 250),
        [],
    );

    /**
     * ON MOUSE LEAVE FROM SIDEBAR
     * @description if mouse leave over sidebar, sidebar has to turn condensed version
     * @description An additional function can be added here to block onMouseOver immediately. It may help to user.
     */
    const onMouseLeaveAction = () => {
        if (disableOnMouseLeave || sidebarBehavior.position === "mobile") {
            return;
        }

        if (
            sidebarBehavior.position !== "narrow" &&
            sidebarBehavior.position !== "landing" &&
            pageName !== "homePage"
        ) {
            return;
        } else if (pageName === "aboutPage" && sidebarBehavior.content !== "about") {
            handleContent("about");
        } else if (
            ((pageName && pageName.split("/")[0] === "handlePage") ||
                pageName === "listingPage" ||
                pageName === "homePage") &&
            sidebarBehavior.content !== "none"
        ) {
            handleContent("none");
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (!isAuthenticated()) {
        return null;
    }

    return (
        <div
            id="sidebar"
            className={"layout_sidebar " + sidebarBehavior.position + " " + sidebarBehavior.content}
            onMouseLeave={() => {
                onMouseLeaveAction();
            }}
        >
            {/* CLOSE X ICON FOR MOBILE MENU */}
            <img
                className="sidebar_mobileMenuCloseIcon"
                src={require("../../img/formCloseWhite.svg")}
                alt=""
                onClick={() => {
                    setSidebarBehavior({ position: "mobile", content: "hide" });
                }}
            />

            {/* VERTICAL LINE */}
            <div className="sidebar_verticalLine"></div>

            {/* SIDEBAR SUB CONTENTS -- for Select Status for Select Type for User Settings or for Contact */}
            <div className="sidebar_2ndBackground">
                <CreateNew />
                <SelectStatus />
                <SelectType />
                <UserSettings />
                <Contact />
            </div>

            {/* BACKEMBLEM */}
            <BackEmblem svgClass="onSidebar" />

            {/* SIDEBAR HEADERZONE STARTED */}
            <div className="sidebar_headerZone">
                {/* LOGOS */}
                <div className="sidebar_logoZone">
                   <img
                       className="sidebar_logo"
                       style={pageName === "homePage" ? { pointerEvents: "none" } : {}}
                       onClick={() => {
                           goToHome();
                       }}
                       src={require("../../img/logoNegative.svg")}
                       alt="emc med data dm+d"
                   />  
                   <img
                       className="sidebar_logo_iconSize"
                       style={pageName === "homePage" ? { pointerEvents: "none" } : {}}
                       onClick={() => {
                           goToHome();
                       }}
                       src={require("../../img/logoNegative_iconSize.svg")}
                       alt="emc med data dm+d"
                   />                  
                </div>

                {/* SIDEBAR NAVIGATION BUTTONS */}
                {pageName === "homePage" ? null : (
                    <NavButton
                        id="button1"
                        text="BACK TO<br/>HOME"
                        icon="home.svg"
                        onClick={() => {
                            if (pageName === "homePage") {
                                handleContent("none");
                            } else {
                                history.push("/");
                            }
                        }}
                    />
                )}
                <NavButton
                    id="button2"
                    text="CREATE NEW<br/>SUBMISSION"
                    icon="plus.svg"
                    onClick={() => {
                        handleContent("createNew");
                    }}
                />
                <NavButton
                    id="button3"
                    text="SELECT<br/>STATUS"
                    icon="arrow.svg"
                    onClick={() => {
                        handleContent("selectStatus");
                    }}
                />
                <NavButton
                    id="button4"
                    text="SELECT<br/>TYPE"
                    icon="label.svg"
                    onClick={() => {
                        handleContent("selectType");
                    }}
                />
            </div>
            {/* SIDEBAR HEADERZONE ENDED */}

            {/* SIDEBAR FOOTERZONE STARTED */}
            <div className="sidebar_footerZone">
                <NavButtonMD
                    id="button5"
                    icon="meddata.svg"
                    onClick={() => {
                        window.open("https://www.emcmeddata.com", "_blank");
                    }}
                />
                <NavButton
                    id="button6"
                    text="About & Legal"
                    icon="hammer.svg"
                    onClick={() => {
                        if (pageName === "aboutPage") {
                            handleContent("none");
                        } else {
                            // MOVED TO EXTERNAL VERSION FOR THIS VERSION
                            // history.push("/About&Legal");

                            window.open(
                                "https://www.datapharm.com/products/emc-indemand",
                                "_blank",
                            );
                        }
                    }}
                />
                <NavButton
                    id="button7"
                    text="Help"
                    icon="help.svg"
                    onClick={() => {
                        window.open(
                            "https://support.datapharm.com/knowledge",
                            "_blank",
                        );
                    }}
                />
                <NavButton
                    id="button8"
                    text={
                        "<span>" +
                        userState.userName +
                        "<br/>(" +
                        userState.selectedCompany?.name +
                        ")</span><span>User Settings</span>"
                    }
                    icon="user.svg"
                    onClick={() => {
                        handleContent("userSettings");
                    }}
                />
                <NavButtonContact
                    id="button9"
                    icon="contact.svg"
                    onClick={() => {
                        window.open("https://www.datapharm.com/contact/", "_blank");
                    }}
                />
            </div>
            {/* SIDEBAR FOOTERZONE ENDED */}
        </div>
    );
};
