import React, { useState, useLayoutEffect, useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { useHistory } from "react-router-dom";
import { CheckBox } from "./CheckBox.js";
import { Button } from "../FormElements/Button/Button.js";

export const SelectType = (props) => {
    const { setSidebarBehavior } = useContext(GlobalContext);
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState({
        NewProduct: { name: "New Product", checked: true },
        NewPack: { name: "New Pack", checked: false },
        UpdateProduct: { name: "Update Product", checked: false },
        UpdatePack: { name: "Update Pack", checked: false },
        MassPriceUpdate: { name: "Mass Price Update", checked: false },
    });
    const [selectedCount, setSelectedCount] = useState(0);
    let history = useHistory();

    /**
     * @function handleSelect -> converts all selection checked or unchecked depending on param
     * @description if param is true, all options will be checked, if false, all will be unchecked
     * @param {boolean}
     *
     * @function changeSelection -> convert selected option checked or unchecked.
     * @param {string} selectedOption
     */
    const handleSelect = (trueOrFalse) => {
        let checked = false; // DESELECT ALL
        if (trueOrFalse) {
            checked = true;
        } // SELECT ALL

        let newArray = {};

        if (selectedCheckBoxes) {
            Object.entries(selectedCheckBoxes).map(([key, item]) => {
                newArray[key] = { name: item.name, checked: checked };
                return null;
            });

            setSelectedCheckBoxes(newArray);
        }
    };

    const changeSelection = (selectedOptions) => {
        setSelectedCheckBoxes((prev) => ({
            ...prev,
            [selectedOptions]: {
                name: selectedCheckBoxes[selectedOptions].name,
                checked: !selectedCheckBoxes[selectedOptions].checked,
            },
        }));
    };

    useLayoutEffect(() => {
        let newSelectedCount = 0;
        if (selectedCheckBoxes) {
            Object.entries(selectedCheckBoxes).map(([key, item]) => {
                if (item.checked) {
                    newSelectedCount++;
                }

                return null;
            });

            setSelectedCount(newSelectedCount);
        }
    }, [selectedCheckBoxes]);

    /**
     * @function filterStatus
     * @description collect all selected options and send to the page
     * @description NOT FINISHED YET.
     */
    const filterType = () => {
        let newArray = [];

        if (selectedCheckBoxes) {
            Object.entries(selectedCheckBoxes).map(([key, item]) => {
                if (item.checked) {
                    newArray.push(key);
                }
                return null;
            });

            history.push({
                pathname: "/list",
                search: "?" + new URLSearchParams({ type: newArray }).toString(),
            });
            setSidebarBehavior({ position: "narrow", content: "none" });
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="sidebarContent selectTypeContent">
            <div className="sidebarContent_header">
                Select Type
                <div>
                    <span
                        onClick={() => {
                            handleSelect(true);
                        }}
                    >
                        Select All
                    </span>{" "}
                    -{" "}
                    <span
                        onClick={() => {
                            handleSelect(false);
                        }}
                    >
                        Deselect All
                    </span>
                </div>
            </div>
            <div className="sidebarContent_line"></div>
            <div className="sidebarContent_links">
                {!selectedCheckBoxes
                    ? null
                    : Object.entries(selectedCheckBoxes).map(([key, item]) => {
                          return (
                              <CheckBox
                                  key={key}
                                  text={item.name}
                                  checked={item.checked}
                                  onChange={() => {
                                      changeSelection(key);
                                  }}
                              />
                          );
                      })}

                <Button
                    buttonType={selectedCount === 0 ? "white deactive" : "white"}
                    text="VIEW"
                    onClick={() => {
                        filterType();
                    }}
                    additionalStyle={{ marginRight: 0 }}
                />
            </div>
        </div>
    );
};
