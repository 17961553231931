import React from "react";
import { Button } from "../FormElements/Button/Button.js";

export const Contact = (props) => {
    return (
        <div
            className="sidebarContent contactContent"
            style={{ whiteSpace: "normal" }}
        >
            <div className="sidebarContent_header">
                <img
                    className="sidebarContent_logo"
                    src={require("img/dataPharmLogo2.png")}
                    alt=""
                />
            </div>
            <div className="sidebarContent_line"></div>

            <div className="sidebarContent_links" style={{ minWidth: 275 }}>
                Datapharm runs emc (electronic medicines compendium), the UK’s
                leading provider of trusted medical information, and provides
                regulatory and compliance software solutions to the
                pharmaceutical industry.
                <span
                    className="font_subLink"
                    style={{ color: "white", marginBottom: 30 }}
                    onClick={() => {
                        window.open("https://www.datapharm.com/", "_blank");
                    }}
                >
                    More About Us
                </span>
            </div>
            <div className="sidebarContent_line"></div>
            <div className="sidebarContent_links" style={{ minWidth: 275 }}>
                You can contact with us if you have questions and problems with
                your account or if you need help about emc InDemand.
                <Button
                    buttonType="white"
                    text="CONTACT"
                    onClick={() => {
                        window.open(
                            "https://www.google.com/search?q=contact",
                            "_blank",
                        );
                    }}
                    additionalStyle={{ marginRight: 0 }}
                />
            </div>
        </div>
    );
};
