import _ from "lodash";

/**
 * hasToBeFilled
 * @description Mandotory Check
 * @returns true || errorText
 */
export const hasToBeFilled = (props) => {
    let errorText = "Field has to be filled.";
    const { DATA, typeIdentifier } = props;
    if (!DATA) {
        return errorText;
    }
    let typeOfData = typeIdentifier(DATA);

    if (typeOfData === "array" || typeOfData === "string") {
        if (DATA.length > 0) {
            return true;
        } else {
            return errorText;
        }
    } else if (typeOfData === "object") {
        if (Object.keys(DATA).length > 0) {
            return true;
        } else {
            return errorText;
        }
    }

    return false;
};

/**
 * hasToBeFilled
 * @description Mandotory Check
 * @returns true || errorText
 */
export const hasToBeFilledIfVisible = (props) => {
    let errorText = "Field has to be filled.";
    const { DATA, typeIdentifier, hideFromUI } = props;

    if (hideFromUI) {
        return true;
    }

    if (!DATA) {
        return errorText;
    }
    let typeOfData = typeIdentifier(DATA);

    if (typeOfData === "array" || typeOfData === "string") {
        if (DATA.length > 0) {
            return true;
        } else {
            return errorText;
        }
    } else if (typeOfData === "object") {
        if (Object.keys(DATA).length > 0) {
            return true;
        } else {
            return errorText;
        }
    }

    return false;
};

/**
 * hasToBeFilledDate
 * @description Mandotory Check for DATE INPUTS
 * @returns true || errorText
 */
export const hasToBeFilledDate = (props) => {
    let errorText = "Date has to be filled.";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    } else {
        return true;
    }
};

/**
 * hasToBeTodayOrFuture
 * @description Effective date can not be in the past.
 * @returns true || errorText
 */
const today = new Date();
const todayDay = today.getDate();
const todayMonth = today.getMonth();
const todayYear = today.getFullYear();
export const hasToBeTodayOrFuture = (props) => {
    let errorText = "The Date has to be today or in future.";
    const { DATA } = props;

    const prependZero = (value) => {
        return ("0" + value).slice(-2);
    };

    if (!DATA || _.isEmpty(DATA.year) || _.isEmpty(DATA.month) || _.isEmpty(DATA.day)) {
        return errorText;
    }

    let currentData = Number(
        DATA.year.value +
            "" +
            prependZero(DATA.month.value) +
            "" +
            prependZero(DATA.day.value),
    );
    let todayData = Number(
        todayYear +
            "" +
            prependZero(todayMonth + 1) +
            "" +
            prependZero(todayDay),
    );

    if (currentData < todayData) {
        return errorText;
    }

    return true;
};

/**
 * unithasToBeFilled
 * @description Mandotory Check for unit selector
 * @returns true || errorText
 */
export const hasToBeFilledUnit = (props) => {
    let errorText = "Field has to be filled.";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }

    if (
        Object.keys(DATA).length === 0 ||
        !DATA.input ||
        !DATA.unit ||
        !DATA.unit.value ||
        !DATA.unit.label
    ) {
        return errorText;
    } else {
        return true;
    }
};

/**
 * unithasToBeFilled
 * @description Mandotory Check for unit selector
 * @returns true || errorText
 */
export const hasToBeFilledUnitIfVisible = (props) => {
    let errorText = "Field has to be filled.";
    const { DATA, hideFromUI } = props;

    if (hideFromUI) {
        return true;
    }

    if (!DATA) {
        return errorText;
    }

    if (
        Object.keys(DATA).length === 0 ||
        !DATA.input ||
        !DATA.unit ||
        !DATA.unit.value ||
        !DATA.unit.label
    ) {
        return errorText;
    } else {
        return true;
    }
};

/**
 * hasToBeDocOrPDF
 * @description checks if file type is not a DOC DOCX or PDF
 * @returns true || errorText
 */
export const hasToBeDocOrPDF = (props) => {
    let errorText = "File type has to be doc, docx or pdf";
    const { DATA } = props;

    if (!DATA || (!DATA.fileProps && !DATA.fileUrl)) {
        return errorText;
    } else {
        let fileType;
        if (DATA.fileProps) {
            fileType = DATA.fileProps[0].name.split(".");
        } else if (DATA.fileUrl) {
            fileType = DATA.label.split(".");
        }
        fileType = fileType[fileType.length - 1].toLowerCase();
        if (fileType === "doc" || fileType === "docx" || fileType === "pdf") {
            return true;
        } else {
            return errorText;
        }
    }
};

/**
 * hasToBeNumber
 * @description Checks if value is a number or not
 * @returns true || errorText
 */
export const hasToBeNumber = (props) => {
    let errorText = "Value has to be a number";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }

    if (/^-?\d+$/.test(DATA)) {
        return true;
    } else {
        return errorText;
    }
};

/**
 * hasToBePrice
 * @description Checks if value is a number or not
 * @returns true || errorText
 */
export const hasToBePrice = (props) => {
    let errorText = "Price must be a whole number (in pence).";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }

    if (/^(?!0+$)[0-9]{1,10}$/.test(DATA)) {
        return true;
    } else {
        return errorText;
    }
};

/**
 * thereMustBeAnIngAtLeast
 * @description in Ingredients zone, there must be an ing box, minimum
 * @returns true || errorText
 */
export const thereMustBeAnIngAtLeast = (props) => {
    let errorText = "An ingredient has to be added";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }
    if (Object.keys(DATA).length > 0) {
        return true;
    }

    return errorText;
};

/**
 * thereMustBeAnPackAtLeast
 * @description in Pack Details zone, there must be an pack box, minimum
 * @returns true || errorText
 */
export const thereMustBeAnPackAtLeast = (props) => {
    let errorText = "A pack has to be added";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }
    if (Object.keys(DATA).length > 0) {
        return true;
    }

    return errorText;
};

/**
 * GTIN CODES CONTROL
 * @description gtin codes has to be 13 or 14 cha long.
 * @returns true || errorText
 */
 export const gtinCodesControl = (props) => {
    let errorText = "GTIN is invalid. Only valid 13 or 14 digits GTINs are accepted.";
    const { DATA } = props;
    if (!DATA) {
        return errorText;
    }
    let errorStatus = false;

    DATA.forEach((item) => {
        if (!item || item.length < 13 || item.length > 14 || !(/^\d+$/.test(item))) {
            errorStatus = true;
        }else if(item || item.length === 13 || item.length === 14 || (/^\d+$/.test(item))){
            var lastDigit = Number(item.substring(item.length - 1));
            var checkSum = 0;
            if (isNaN(lastDigit)) { return false; } // not a valid upc/ean
          
            var arr = item.substring(0,item.length - 1).split("").reverse();
            var oddTotal = 0, evenTotal = 0;
          
            for (var i=0; i<arr.length; i++) {
              if (isNaN(arr[i])) { return false; } // can't be a valid upc/ean we're checking for
          
              if (i % 2 === 0) { oddTotal += Number(arr[i]) * 3; }
              else { evenTotal += Number(arr[i]); }
            }
            checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10;
          
            // true if they are equal
            if(checkSum !== lastDigit){
                errorStatus = true;
            }
        }
    });

    if (!errorStatus) {
        return true;
    } else {
        return errorText;
    }
};


/**
 * HANDLE DATA for INGREDIENT API
 * @description handles data coming from BE
 * @returns [data,count]
 */
export const handleDataForIngredientApi = (props) => {
    const { DATA } = props;
    let newData = [];
    DATA.data.map((item) => {
        newData.push({ label: item.name, value: item.id });
        return null;
    });
    return newData;
};

/**
 * thereMustBeAPriceUpdateAtLeast
 * @description in Mass Price Update zone, there must be an new price update
 * @returns true || errorText
 */
export const thereMustBeAPriceUpdateAtLeast = (props) => {
    let errorText = "A price update has to be added";
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }

    if(Object.keys(DATA).filter((key) => typeof(DATA[key].newPrice) !== "undefined" && DATA[key].newPrice !== null && DATA[key].newPrice > 0).length > 0) {
        return true;
    }

    return errorText;
};

/**
 * max of 50 price packs
 * @description in Mass Price Update zone, there must be an new price update
 * @returns true || errorText
 */
 export const maxofPriceInput = (props) => {
    let massPriceTopValue = process.env.REACT_APP_MASS_PRICE_TOP_VALUE;
    let errorText = `Maximum input of ${massPriceTopValue} only`;
    const { DATA } = props;

    if (!DATA) {
        return errorText;
    }
    if(Object.keys(DATA).filter((key) => typeof(DATA[key].newPrice) !== "undefined" && DATA[key].newPrice !== null && DATA[key].newPrice > 0).length <= parseInt(massPriceTopValue)) {
        return true;
    }

    return errorText;
};

/**
 * Unit and Value should both have value
 * @description Mandotory Check for unit selector
 * @returns true || errorText
 */
 export const UnitValueValidation = (props) => {
    let errorText = "Field has to be filled.";
    const { DATA } = props;

    if (DATA && DATA.input && !DATA.unit ) {
        return errorText;
    }else if(DATA && !DATA.input && DATA.unit){
        return errorText;
    }
    else {
        return true;
    }
};

/**
 * shouldBeNumber
 * @description Mandotory Check for unit selector
 * @returns true || errorText
 */
 export const shouldBeNumber = (props) => {
   let errorText = "Input should be number";
   const { DATA} = props;
   
   if(DATA && DATA.input !== "" && !(/^[0-9]*$/.test(DATA.input))) {
       return errorText;
   }

   return true;
};
/**
 * acceptDecimal
 * @description Mandotory Check for unit selector
 * @returns true || errorText
 */
 export const acceptDecimal = (props) => {
    let errorText = "Input should be numerical";
    const { DATA} = props;
    
    if(DATA && DATA.input !== "" && !(/^\d+(\.\d{1,10})?$/.test(DATA.input))) {
        return errorText;
    }
 
    return true;
 };

 /**
 * isFileSizeWithinRange
 * @description checks the file size if between 1B and 10MB
 * @returns true || errorText
 */
export const isFileSizeWithinRange = (props) => {
    let errorText = "File size must be between 1B and 10MB.";
    const { DATA } = props;

    if (!DATA || (!DATA.fileProps && !DATA.fileUrl)) {
        return errorText;
    }

    if(DATA.fileProps && (DATA.fileProps[0].size < 1 || DATA.fileProps[0].size > 10485760)) { // equivalent to 10MB
        return errorText;
    }

    return true;
};