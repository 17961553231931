import React, { useContext } from "react";

import { UserContext } from "contexts/UserStore.js";
import { GlobalContext } from "contexts/GlobalStore.js";
import { FeatureFlagsStore } from "contexts/FeatureContext";

import "./DescriptionLink.scss";

export const DescriptionLink = (props) => {
    const { viewMode } = props;
    const { userState } = useContext(UserContext);
    const { headerTitles } = useContext(GlobalContext);
    const flags = React.useContext(FeatureFlagsStore);
    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    
    const supportemail = flags["enableNhsBsaEmailAddress"] ? "indemandppdsupport@nhsbsa.nhs.uk" : "nhsbsa.indemandppdsupport@nhs.net";

    if (viewMode === "view") {
        return null;
    }
    return (
        <div className="form_descText">
            To request an ingredient be added to the dm+d please <a href={"mailto:" + supportemail + "?cc=" + supportemail + "&subject=Datapharm in-demand request to add a new ingredient name&body=Please complete this email and send directly to the NHS BSA to request a new ingredient be added to the dm+d, for . A copy will be sent to Datapharm as a record for support purposes.%0D%0A%0D%0AThank you%0D%0A%0D%0ARequested for:%0D%0ANew Product: " + headerTitles.productName + "%0D%0ACompany: " + userState.selectedCompany.name + "%0D%0AUser: " + userState.userName + "%0D%0A%0D%0APlease enter your ingredient name request below:%0D%0A%0D%0A---------------------------%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A---------------------------"}>click here</a>. Once the ingredient has been added to the dm+d, you will be able to make your submission to the NHS BSA
        </div>

    );
    
};
