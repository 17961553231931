import { Seperator } from "components/FormElements/Seperator/Seperator.js";
import { Description } from "components/FormElements/Description/Description.js";
import { DescriptionLink } from "components/FormElements/DescriptionLink/DescriptionLink.js";
import { TextInput } from "components/FormElements/TextInput/TextInput.js";
import { MultipleTextInput } from "components/FormElements/MultipleTextInput/MultipleTextInput.js";
import { SelectBox } from "components/FormElements/SelectBox/SelectBox.js";
import { MultipleSelectBox } from "components/FormElements/MultipleSelectBox/MultipleSelectBox.js";
import { DateInput } from "components/FormElements/DateInput/DateInput.js";
import { DataGrid } from "components/FormElements/DataGrid/DataGrid.js";
import { UnitSelect } from "components/FormElements/UnitSelect/UnitSelect.js";
import { RadioButton } from "components/FormElements/RadioButton/RadioButton.js";
import { FileInput } from "components/FormElements/FileInput/FileInput.js";
import { Button } from "components/FormElements/Button/Button.js";
import { Ingredients } from "components/FormElements/Ingredients/Ingredients.js";
import { Packs } from "components/FormElements/Packs/Packs.js";
import _ from "lodash";

import {
    hasToBeFilled,
    hasToBeFilledDate,
    hasToBeDocOrPDF,
    thereMustBeAnIngAtLeast,
    thereMustBeAnPackAtLeast,
    handleDataForIngredientApi,
    hasToBeTodayOrFuture,
    hasToBeFilledUnit,
    hasToBePrice,
    gtinCodesControl,
    hasToBeFilledIfVisible,
    hasToBeFilledUnitIfVisible,
    thereMustBeAPriceUpdateAtLeast,
    maxofPriceInput,
    UnitValueValidation,
    shouldBeNumber,
    acceptDecimal,
    isFileSizeWithinRange
} from "./validationRules.js";
import {
    yesNoNA,
    yesNo,
    defaultDateOptions,
    trueFalse,
} from "./selectBoxOptions.js";

//-------------------------------------------------------------
// 		NEW PRODUCT
//-------------------------------------------------------------

export const NewProduct = ({formulations, licensedRoutes, flavours, controlledDrugCategories, currentLicensing, restrictions, bops, units, legalCategories}) => ({
    seperatorIntro: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Details",
    },
    name: {
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Product Name",
        placeHolder: "Type Product Name",
        informationText:
            "The product name is usually the trade name of the product + strength + form e.g. BrandX 50mg tablets or BrandX 150mg/15ml solution for injection ampoules.",
        validationRules: [hasToBeFilled],
    },
    relatedVMP: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Related VMP",
        placeHolder: "Type to search a VMP",
        apiNameForOptionValue: "callVMP",
        handleData: handleDataForIngredientApi,
        informationText:"Virtual Medicinal Product (VMP) name is most commonly the generic name + strength + form, e.g. paracetamol 500mg capsule. If the medicine in this form is new to the dm+d, the VMP may not yet exist. Please choose \"Not listed\" if that is the case.",     
        validationRules: [hasToBeFilled],
    },
    formulation: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Formulation",
        placeHolder: "Type and Select",
        optionValue: formulations,
        informationText: "Formulation is the dose form of the product e.g. cream, drops, tablet.",
        validationRules: [hasToBeFilled],
    },
    licensedRoutes: {
        componentTypeName: "MultipleSelectBox",
        componentType: MultipleSelectBox,
        fieldName: "Licensed Routes",
        placeHolder: "Type and Select",
        optionValue: licensedRoutes,
        informationText: "Select only the licensed route of administration as listed on the SPC.",
        validationRules: [hasToBeFilled],
    },

    /**
     * EFFECTIVE DATE
     */

    seperatorEffectiveDate: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Effective Date",
    },
    descriptionEffectiveDate: {
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Please enter the effective date for changes. If the date that you choose is within 2 weeks of the current date, please note that the NHS BSA cannot commit to have actioned your request by this date.",
    },
    effectiveDate: {
        componentTypeName: "DateInput",
        componentType: DateInput,
        fieldName: "Effective Date",
        optionValue: defaultDateOptions,
        informationText:
            "The effective date is the date your submission should be added or removed from the dm+d database. The change will be seen on the dm+d browser up to 5 working days later.",
        validationRules: [hasToBeFilledDate, hasToBeTodayOrFuture],
    },

    /**
     * INGREDIENTS
     */

    seperatorIngredients: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Ingredients",
    },
    descriptionIngredients: {
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "For each active ingredient, please complete all the appropriate fields below. Then click 'Save Ingredient' to add the ingredient to your product.",
    },
    descriptionNewIngredients: {
        componentTypeName: "DescriptionLink",
        componentType: DescriptionLink,
        // placeHolder:"",
    },
    ingredients: {
        componentTypeName: "Ingredients",
        componentType: Ingredients,
        fieldName: "Ingredients",
        validationRules: [thereMustBeAnIngAtLeast],
        removeButtonForIngsAndPacks: (obj, additionalData) => {
            let data = obj.data;
            let current = _.omit(data.ingredients.currentValue, additionalData.keyName);

            if (Object.keys(current).length > 0) {
                obj.setData({
                    ...data,
                    ingredients: {
                        ...data.ingredients,
                        currentValue: current,
                        errorText: obj.findError("ingredients", current),
                    },
                });
            } else {
                obj.setData({
                    ...data,
                    ingredients: {
                        ...data.ingredients,
                        currentValue: current,
                        errorText: obj.findError("ingredients", current),
                    },
                    saveIngredientButton: {
                        ...data.saveIngredientButton,
                        hideFromUI: false,
                    },
                    addIngredientButton: {
                        ...data.addIngredientButton,
                        hideFromUI: true,
                    },
                    bops: {
                        ...data.bops,
                        hideFromUI: false,
                    },
                    ingredientName: {
                        ...data.ingredientName,
                        hideFromUI: false,
                    },
                    boss: {
                        ...data.boss,
                        hideFromUI: false,
                    },
                    numerator: {
                        ...data.numerator,
                        hideFromUI: false,
                    },
                    denominator: {
                        ...data.denominator,
                        hideFromUI: false,
                    },
                });
            }
        },
    },
    ingredientName: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Ingredient Name",
        placeHolder: "Type to search ingredients",
        apiNameForOptionValue: "callIngredients",
        handleData: handleDataForIngredientApi,
        validationRules: [hasToBeFilled],
        informationText: "Ingredient means the active ingredient(s) as listed on the SPC.",
        dontSendToApi: true,
        hideFromUI: false,
    },
    bops: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Basis of Pharmaceutical Strength (BOPS)",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: bops,
        informationText:
            "The BOPS indicates which part of the active ingredient the strength is based on – whether the strength is based on the whole active ingredient (based on ingredient substance) or whether the strength is based on just the base part of the active ingredient (based on base substance). See the glossary in the Help section for more guidance.",
        dontSendToApi: true,
        hideFromUI: false,
        onChange: (obj, bopsCurrentValue) => {
            let data = obj.data;

            data.boss = {
                ...data.boss,
                currentValue: null,
                // set BoSS to optional field if the selected value is “Based on Ingredient Substance”
                validationRules: bopsCurrentValue.value === "0001" ? null : [hasToBeFilled],
            };
            data.boss.errorText = obj.findError("boss", null);
        }
    },
    boss: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Basis of Substance Strength (BoSS)",
        placeHolder: "Type to search ingredients",
        apiNameForOptionValue: "callIngredients",
        handleData: handleDataForIngredientApi,
        validationRules: [hasToBeFilled],
        informationText:
            "If you have selected ‘based on base substance’ in the box above, click on the \"Lookup\" button, type in all or part of the BOSS and click \"Search\".  Select the exact BOSS match.",
        dontSendToApi: true,
        hideFromUI: false,
    },
    numerator: {
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Numerator Strength",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilledUnit,acceptDecimal],
        optionValue: units,
        informationText:
            "Type the product strength in the first box e.g. 50. Select the units of strength in the second box e.g. mg ",
        dontSendToApi: true,
        hideFromUI: false,
    },
    denominator: {
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Denominator Strength",
        placeHolder: "Type and Select",
        validationRules: [UnitValueValidation,acceptDecimal], 
        optionValue: units,
        informationText:
            "For 'per unit strength' products (50mg per 1 ml) type the denominator strength in the first box e.g. ‘1’ and select units of strength in the second box e.g. ‘ml’. Please enter products per unit measure (per 1 gram or per 1ml). See the Help section glossary for more guidance.",
        dontSendToApi: true,
        hideFromUI: false,
        errorText: []
    },
    saveIngredientButton: {
        componentTypeName: "Button",
        componentType: Button,
        hideFromUI: false,
        dontSendToApi: true,
        text: "SAVE INGREDIENT",
        buttonType: "deactive", // "deactive" || ""
        onClick: (obj, additionalData) => {
            let data = obj.data;
            if (
                data?.ingredientName?.errorText?.length !== 0 ||
                data?.bops?.errorText?.length !== 0 ||
                data?.boss?.errorText?.length !== 0 ||
                data?.numerator?.errorText?.length !== 0 ||
                data?.denominator?.errorText?.length !== 0
            ) {
                return;
            }

            let newIngredientsCurrentValue = {
                ...data.ingredients.currentValue,
                [data.ingredientName.currentValue.value]: {
                    ingredientName: { currentValue: data.ingredientName.currentValue },
                    bops: { currentValue: data.bops.currentValue },
                    boss: { currentValue: data.boss.currentValue },
                    numerator: { currentValue: data.numerator.currentValue },
                    denominator: { currentValue: data.denominator.currentValue },
                },
            };

            if (!obj.settings.isThereAnyChange) {
                obj.setSettings({ ...obj.settings, isThereAnyChange: true });
            }

            obj.setData({
                ...data,
                ingredients: {
                    ...data.ingredients,
                    currentValue: newIngredientsCurrentValue,
                    errorText: obj.findError("ingredients", newIngredientsCurrentValue),
                },
                saveIngredientButton: {
                    ...data.saveIngredientButton,
                    hideFromUI: true,
                },
                addIngredientButton: {
                    ...data.addIngredientButton,
                    hideFromUI: false,
                },
                bops: {
                    ...data.bops,
                    currentValue: null,
                    errorText: obj.findError("bops", null),
                    hideFromUI: true,
                },
                ingredientName: {
                    ...data.ingredientName,
                    currentValue: null,
                    errorText: obj.findError("ingredientName", null),
                    hideFromUI: true,
                },
                boss: {
                    ...data.boss,
                    currentValue: null,
                    errorText: obj.findError("boss", null),
                    hideFromUI: true,
                },
                numerator: {
                    ...data.numerator,
                    currentValue: null,
                    errorText: obj.findError("numerator", null),
                    hideFromUI: true,
                },
                denominator: {
                    ...data.denominator,
                    currentValue: null ,
                    errorText: obj.findError("denominator", null),
                    hideFromUI: true,
                },
            });
        },
    },
    addIngredientButton: {
        componentTypeName: "Button",
        componentType: Button,
        hideFromUI: true,
        dontSendToApi: true,
        text: "NEW INGREDIENT",
        onClick: (obj, additionalData) => {
            let data = obj.data;

            obj.setData({
                ...data,
                saveIngredientButton: {
                    ...data.saveIngredientButton,
                    hideFromUI: false,
                },
                addIngredientButton: {
                    ...data.addIngredientButton,
                    hideFromUI: true,
                },
                bops: {
                    ...data.bops,
                    hideFromUI: false,
                },
                ingredientName: {
                    ...data.ingredientName,
                    hideFromUI: false,
                },
                boss: {
                    ...data.boss,
                    hideFromUI: false,
                },
                numerator: {
                    ...data.numerator,
                    hideFromUI: false,
                },
                denominator: {
                    ...data.denominator,
                    hideFromUI: false,
                },
            });
        },
    },

    /**
     * EXCIPIENTS
     */

    seperatorExcipients: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Excipients",
    },
    productFlavour: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Product Flavour",
        placeHolder: "Type and Select",
        optionValue: flavours,
        informationText: "If applicable, select any product flavourings from the list.",
    },
    glutenFreeProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Gluten Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText: "Is your product gluten free?",
        validationRules: [hasToBeFilled],
    },
    preservativeFreeProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Preservative Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText:
            "If your product is an eye drop, is it free from preservatives? If your product is not an eye drop, select not applicable.",
        validationRules: [hasToBeFilled],
    },
    sugarFreeProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Sugar Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText:
            "If this product has prolonged contact in the mouth, is it sugar free? See glossary for examples.",
        validationRules: [hasToBeFilled],
    },
    cfcFreeProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "CFC Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText:
            "If this product has prolonged contact in the mouth, is it sugar free? See glossary for examples.",
        validationRules: [hasToBeFilled],
    },

    /**
     * LICENCE DETAILS
     */

    seperatorLicense: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Licence Details",
    },
    controlledDrugCategory: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Controlled Drug Category",
        placeHolder: "Type and Select",
        optionValue: controlledDrugCategories,
        informationText:
            "Some drugs have restrictions on them according to the Misuse of Drugs Act 1971 and Misuse of Drugs Regulations. The SPC will state any restrictions and the controlled drug schedule.",
        validationRules: [hasToBeFilled],
    },
    currentLicensingAuthority: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Current Licensing Authority",
        placeHolder: "Type and Select",
        optionValue: currentLicensing,
        informationText: "For medicines registered by the MHRA or EMA, select Medicines-MHRA.",
        validationRules: [hasToBeFilled],
    },
    restrictionsOnAvailability: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Restrictions on Availability",
        placeHolder: "Type and Select",
        optionValue: restrictions,
        informationText:
            "Some products have restrictions on them e.g. only available through hospitals or through specific doctors or pharmacists. The glossary gives full definitions.",
        validationRules: [hasToBeFilled],
    },
    emaAdditionalMonitoring: {
        componentTypeName: "RadioButton",
        componentType: RadioButton,
        fieldName: "EMA Additional Monitoring",
        placeHolder: "Type and Select",
        optionValue: yesNo,
        informationText:
            "This medicinal product is subject to additional monitoring. Its documentation carries a black triangle.",
        validationRules: [hasToBeFilled],
    },

    /**
     * PACK DETAILS
     */

    seperatorPacks: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Pack Details",
    },
    descriptionPacks: {
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Use the fields below to specify each pack. Click ‘Save Pack’ to add more packs to the products.",
    },
    packs: {
        isASection: true,
        componentTypeName: "Packs",
        componentType: Packs,
        fieldName: "Packs",
        validationRules: [thereMustBeAnPackAtLeast],
        removeButtonForIngsAndPacks: (obj, additionalData) => {
            let data = obj.data;
            let current = _.omit(data.packs.currentValue, additionalData.keyName);

            if (Object.keys(current).length > 0) {
                obj.setData({
                    ...data,
                    packs: {
                        ...data.packs,
                        currentValue: current,
                        errorText: obj.findError("packs", current),
                    },
                });
            } else {
                obj.setData({
                    ...data,
                    packs: {
                        ...data.packs,
                        currentValue: current,
                        errorText: obj.findError("packs", current),
                    },
                    savePackButton: {
                        ...data.savePackButton,
                        hideFromUI: false,
                    },
                    addPackButton: {
                        ...data.addPackButton,
                        hideFromUI: true,
                    },

                    quantity: {
                        ...data.quantity,
                        hideFromUI: false,
                    },
                    legalCategory: {
                        ...data.legalCategory,
                        hideFromUI: false,
                    },
                    subpackInfo: {
                        ...data.subpackInfo,
                        hideFromUI: false,
                    },
                    gtinCodes: {
                        ...data.gtinCodes,
                        hideFromUI: false,
                    },
                    calendarPack: {
                        ...data.calendarPack,
                        hideFromUI: false,
                    },
                    hospitalPack: {
                        ...data.hospitalPack,
                        hideFromUI: false,
                    },
                    limitedStability: {
                        ...data.limitedStability,
                        hideFromUI: false,
                    },
                    price: {
                        ...data.price,
                        hideFromUI: false,
                    },
                });
            }
        },
    },
    quantity: {
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Quantity",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilledUnit,shouldBeNumber],
        informationText:
            "Quantity/Unit means the number and type of ‘units’ in the pack e.g. 28 tablets or 5 cartridges. Type a numerical quantity in the first box. Select the units from the drop down list. E.g. ‘28’ and ‘tablet’ or ‘10’ and ‘ml’ or ‘5’ and ‘cartridge.",
        optionValue: units,
        dontSendToApi: true,
        hideFromUI: false,
    },
    legalCategory: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Legal Category",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: legalCategories,
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "The legal category of this specific product pack. Use ‘not applicable’ for all non-medicine packs e.g. appliances.",
    },
    subpackInfo: {
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Subpack Information",
        placeHolder: "Type",
        validationRules: [hasToBeFilled],
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "Subpack information explains how the product is packaged e.g. the number of strips of tablets in a pack. For a 28 tablet pack in 2 strips, enter 2x14. For a 28 tablet pack in 1 strip enter 1x28. For a single pack of 1 vial, 1 ampoule, 1 tube or 1 tub, enter not applicable.",
    },
    gtinCodes: {
        componentTypeName: "MultipleTextInput",
        componentType: MultipleTextInput,
        fieldName: "GTIN Codes",
        placeHolder: "Type and add",
        validationRules: [hasToBeFilled, gtinCodesControl],
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "This is a mandatory field - include between 1 and 20 GTIN codes. Use the drop down list to increase the number of GTIN fields.",
    },
    calendarPack: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Calendar Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "A calendar pack is a blister or strip pack showing the days of the week or month against each of the several units in the pack.",
    },
    hospitalPack: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Hospital Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "A hospital pack is one that is only made available through hospital prescribing.",
    },
    limitedStability: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Limited Stability",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "Some preparations require a diluent/vehicle to be added to them. If the resultant liquid preparation has a stability of 13 days or less, mark the preparation as limited stability.",
    },
    price: {
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Price (in pence)",
        placeHolder: "Type",
        validationRules: [hasToBeFilled, hasToBePrice],
        dontSendToApi: true,
        hideFromUI: false,
        informationText:
            "The price of this specific product pack in pence, sterling.  For example enter £3.62 as 362. For new product packs that are awaiting price confirmation, please enter 1.  See ‘entering data’ section of the user manual for more details.",
    },
    savePackButton: {
        componentTypeName: "Button",
        componentType: Button,
        hideFromUI: false,
        dontSendToApi: true,
        text: "SAVE PACK",
        buttonType: "deactive", // "deactive" || ""
        onClick: (obj, additionalData) => {
            let data = obj.data;

            const NAMES_TO_CHECK = [
                "quantity",
                "legalCategory",
                "subpackInfo",
                "gtinCodes",
                "calendarPack",
                "hospitalPack",
                "limitedStability",
                "price",
            ];

            const totalErrors = NAMES_TO_CHECK.reduce((total, name) => {
                const field = data[name];
                const errorText = field?.errorText;
                const count = errorText?.length || 0;

                return total + count;
            }, 0);

            if (totalErrors > 0) {
                return;
            }

            let newPacksCurrentValue = {
                ...data.packs.currentValue,
                [data.quantity.currentValue.input + "" + data.quantity.currentValue.unit.label]: {
                    quantity: { currentValue: data.quantity.currentValue },
                    legalCategory: { currentValue: data.legalCategory.currentValue },
                    subpackInfo: { currentValue: data.subpackInfo.currentValue },
                    gtinCodes: { currentValue: data.gtinCodes.currentValue },
                    calendarPack: { currentValue: data.calendarPack.currentValue },
                    hospitalPack: { currentValue: data.hospitalPack.currentValue },
                    limitedStability: { currentValue: data.limitedStability.currentValue },
                    price: { currentValue: data.price.currentValue },
                },
            };

            if (!obj.settings.isThereAnyChange) {
                obj.setSettings({ ...obj.settings, isThereAnyChange: true });
            }

            obj.setData({
                ...data,
                packs: {
                    ...data.packs,
                    currentValue: newPacksCurrentValue,
                    errorText: obj.findError("packs", newPacksCurrentValue),
                },
                savePackButton: {
                    ...data.savePackButton,
                    hideFromUI: true,
                },
                addPackButton: {
                    ...data.addPackButton,
                    hideFromUI: false,
                },

                quantity: {
                    ...data.quantity,
                    currentValue: null,
                    errorText: obj.findError("quantity", null),
                    hideFromUI: true,
                },
                legalCategory: {
                    ...data.legalCategory,
                    currentValue: null,
                    errorText: obj.findError("legalCategory", null),
                    hideFromUI: true,
                },
                subpackInfo: {
                    ...data.subpackInfo,
                    currentValue: null,
                    errorText: obj.findError("subpackInfo", null),
                    hideFromUI: true,
                },
                gtinCodes: {
                    ...data.gtinCodes,
                    currentValue: null,
                    errorText: obj.findError("gtinCodes", null),
                    hideFromUI: true,
                },
                calendarPack: {
                    ...data.calendarPack,
                    currentValue: null,
                    errorText: obj.findError("calendarPack", null),
                    hideFromUI: true,
                },
                hospitalPack: {
                    ...data.hospitalPack,
                    currentValue: null,
                    errorText: obj.findError("hospitalPack", null),
                    hideFromUI: true,
                },
                limitedStability: {
                    ...data.limitedStability,
                    currentValue: null,
                    errorText: obj.findError("limitedStability", null),
                    hideFromUI: true,
                },
                price: {
                    ...data.price,
                    currentValue: null,
                    errorText: obj.findError("price", null),
                    hideFromUI: true,
                },
            });
        },
    },
    addPackButton: {
        componentTypeName: "Button",
        componentType: Button,
        hideFromUI: true,
        dontSendToApi: true,
        text: "NEW PACK",
        onClick: (obj, additionalData) => {
            let data = obj.data;

            obj.setData({
                ...data,
                savePackButton: {
                    ...data.savePackButton,
                    hideFromUI: false,
                },
                addPackButton: {
                    ...data.addPackButton,
                    hideFromUI: true,
                },

                quantity: {
                    ...data.quantity,
                    hideFromUI: false,
                },
                legalCategory: {
                    ...data.legalCategory,
                    hideFromUI: false,
                },
                subpackInfo: {
                    ...data.subpackInfo,
                    hideFromUI: false,
                },
                gtinCodes: {
                    ...data.gtinCodes,
                    hideFromUI: false,
                },
                calendarPack: {
                    ...data.calendarPack,
                    hideFromUI: false,
                },
                hospitalPack: {
                    ...data.hospitalPack,
                    hideFromUI: false,
                },
                limitedStability: {
                    ...data.limitedStability,
                    hideFromUI: false,
                },
                price: {
                    ...data.price,
                    hideFromUI: false,
                },
            });
        },
    },

    /**
     * SMPC FILE
     */

    seperatorSMPC: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "SmPC File",
    },
    smpcFile: {
        componentTypeName: "FileInput",
        componentType: FileInput,
        fieldName: "SmPC File",
        validationRules: [hasToBeDocOrPDF, isFileSizeWithinRange],
    },
});

//-------------------------------------------------------------
// 		NEW PACK
//-------------------------------------------------------------

export const NewPack = ({units, legalCategories}) => ({
    seperatorIntro: {
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Details",
    },
    relatedProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Related Product",
        placeHolder: "Type to search a product",
        apiNameForOptionValue: "callAMP",
        handleData: handleDataForIngredientApi, // in some cases, there are different object keys in API. this func checks and converts them.
        informationText:
            "The product name is usually the trade name of the product + strength + form e.g. BrandX 50mg tablets or BrandX 150mg/15ml solution for injection ampoules.",
        validationRules: [hasToBeFilled],
    },

    /**
     * EFFECTIVE DATE
     */

    seperatorEffectiveDate: {
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Effective Date",
    },
    descriptionEffectiveDate: {
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Please enter the effective date for changes. If the date that you choose is within 2 weeks of the current date, please note that the NHS BSA cannot commit to have actioned your request by this date.",
    },
    effectiveDate: {
        componentTypeName: "DateInput",
        componentType: DateInput,
        fieldName: "Effective Date",
        optionValue: defaultDateOptions,
        informationText:
            "The effective date is the date your submission should be added or removed from the dm+d database. The change will be seen on the dm+d browser up to 5 working days later.",
        validationRules: [hasToBeFilledDate, hasToBeTodayOrFuture],
    },

    /**
     * PACK DETAILS
     */
    seperatorPackDetails: {
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Pack Details",
    },
    quantity: {
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Quantity",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilledUnit,shouldBeNumber],
        informationText:
            "Quantity/Unit means the number and type of ‘units’ in the pack e.g. 28 tablets or 5 cartridges. Type a numerical quantity in the first box. Select the units from the drop down list. E.g. ‘28’ and ‘tablet’ or ‘10’ and ‘ml’ or ‘5’ and ‘cartridge.",
        optionValue: units,
    },
    legalCategory: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Legal Category",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: legalCategories,
        informationText:
            "The legal category of this specific product pack. Use ‘not applicable’ for all non-medicine packs e.g. appliances.",
    },
    subpackInfo: {
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Subpack Information",
        placeHolder: "Type",
        validationRules: [hasToBeFilled],
        informationText:
            "Subpack information explains how the product is packaged e.g. the number of strips of tablets in a pack. For a 28 tablet pack in 2 strips, enter 2x14. For a 28 tablet pack in 1 strip enter 1x28. For a single pack of 1 vial, 1 ampoule, 1 tube or 1 tub, enter not applicable.",
    },
    gtinCodes: {
        componentTypeName: "MultipleTextInput",
        componentType: MultipleTextInput,
        fieldName: "GTIN Codes",
        placeHolder: "Type and add",
        validationRules: [hasToBeFilled, gtinCodesControl],
        informationText:
            "This is a mandatory field - include between 1 and 20 GTIN codes. Use the drop down list to increase the number of GTIN fields.",
    },
    calendarPack: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Calendar Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        informationText:
            "A calendar pack is a blister or strip pack showing the days of the week or month against each of the several units in the pack.",
    },
    hospitalPack: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Hospital Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        informationText:
            "A hospital pack is one that is only made available through hospital prescribing.",
    },
    limitedStability: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Limited Stability",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        informationText:
            "Some preparations require a diluent/vehicle to be added to them. If the resultant liquid preparation has a stability of 13 days or less, mark the preparation as limited stability.",
    },
    price: {
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Price (in pence)",
        placeHolder: "Type",
        validationRules: [hasToBeFilled, hasToBePrice],
        informationText:
            "The price of this specific product pack in pence, sterling.  For example enter £3.62 as 362. For new product packs that are awaiting price confirmation, please enter 1.  See ‘entering data’ section of the user manual for more details.",
    },

    /**
     * SMPC FILE
     */

    seperatorSMPC: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "SmPC File",
    },

    smpcFile: {
        componentTypeName: "FileInput",
        componentType: FileInput,
        fieldName: "SmPC File",
        validationRules: [hasToBeDocOrPDF, isFileSizeWithinRange],
    },
});

//-------------------------------------------------------------
// 		UPDATE PACK
//-------------------------------------------------------------

export const UpdatePack = ({units, legalCategories}) => ({
    relatedProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Select a Product",
        placeHolder: "Type to search a product",
        apiNameForOptionValue: "callAMP",
        handleData: handleDataForIngredientApi,
        informationText:
            "The product name is usually the trade name of the product + strength + form e.g. BrandX 50mg tablets or BrandX 150mg/15ml solution for injection ampoules.",
        validationRules: [hasToBeFilled],
    },

    relatedPack: {
        internalState: 2,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Select a Pack",
        placeHolder: "Type to search a pack",
        validationRules: [hasToBeFilled],
    },

    /**
     * EFFECTIVE DATE
     */

    seperatorEffectiveDate: {
        internalState: 3,
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Effective Date",
    },
    descriptionEffectiveDate: {
        internalState: 3,
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Please enter the effective date for changes. If the date that you choose is within 2 weeks of the current date, please note that the NHS BSA cannot commit to have actioned your request by this date.",
    },
    effectiveDate: {
        internalState: 3,
        componentTypeName: "DateInput",
        componentType: DateInput,
        fieldName: "Effective Date",
        optionValue: defaultDateOptions,
        informationText:
            "The effective date is the date your submission should be added or removed from the dm+d database. The change will be seen on the dm+d browser up to 5 working days later.",
        validationRules: [hasToBeFilledDate, hasToBeTodayOrFuture],
    },
    discontinuePack: {
        internalState: 3,
        componentTypeName: "RadioButton",
        componentType: RadioButton,
        fieldName: "Discontinue Pack?",
        placeHolder: "Type and Select",
        optionValue: yesNo,
        validationRules: [hasToBeFilled],
    },
    discontinueReason: {
        internalState: 3,
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Discontinue Reason",
        placeHolder: "Reason for discontinuation",
        informationText: "",
        validationRules: [hasToBeFilledIfVisible],
        hideFromUI: true,
    },
    discontinueQuantity: {
        internalState: 3,
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Quantity",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilledUnitIfVisible,shouldBeNumber],
        informationText:
            "Quantity/Unit means the number and type of ‘units’ in the pack e.g. 28 tablets or 5 cartridges. Type a numerical quantity in the first box. Select the units from the drop down list. E.g. ‘28’ and ‘tablet’ or ‘10’ and ‘ml’ or ‘5’ and ‘cartridge.",
        optionValue: units,
    },

    singlePriceUpdate: {
        internalState: 4,
        componentTypeName: "RadioButton",
        componentType: RadioButton,
        fieldName: "Hide me from UI. I'm just for API.",
        placeHolder: "",
        currentValue: {
            label: "False",
            value: false,
        },
        inComingSavedValue: {},
        changedFieldNames: [],
        optionValue: trueFalse,
        hideFromUI: true,
    },

    /**
     * PACK DETAILS
     */
    seperatorPackDetails: {
        internalState: 4,
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Pack Details",
    },
    quantity: {
        internalState: 4,
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Quantity",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilledUnit,shouldBeNumber],
        informationText:
            "Quantity/Unit means the number and type of ‘units’ in the pack e.g. 28 tablets or 5 cartridges. Type a numerical quantity in the first box. Select the units from the drop down list. E.g. ‘28’ and ‘tablet’ or ‘10’ and ‘ml’ or ‘5’ and ‘cartridge.",
        optionValue: units,
    },
    legalCategory: {
        internalState: 4,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Legal Category",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: legalCategories,
        informationText:
            "The legal category of this specific product pack. Use ‘not applicable’ for all non-medicine packs e.g. appliances.",
    },
    subpackInfo: {
        internalState: 4,
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Subpack Information",
        placeHolder: "Type",
        validationRules: [hasToBeFilled],
        informationText:
            "Subpack information explains how the product is packaged e.g. the number of strips of tablets in a pack. For a 28 tablet pack in 2 strips, enter 2x14. For a 28 tablet pack in 1 strip enter 1x28. For a single pack of 1 vial, 1 ampoule, 1 tube or 1 tub, enter not applicable.",
    },
    gtinCodes: {
        internalState: 4,
        componentTypeName: "MultipleTextInput",
        componentType: MultipleTextInput,
        fieldName: "GTIN Codes",
        placeHolder: "Type and add",
        validationRules: [hasToBeFilled, gtinCodesControl],
        informationText:
            "This is a mandatory field - include between 1 and 20 GTIN codes. Use the drop down list to increase the number of GTIN fields.",
    },
    calendarPack: {
        internalState: 4,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Calendar Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        informationText:
            "A calendar pack is a blister or strip pack showing the days of the week or month against each of the several units in the pack.",
    },
    hospitalPack: {
        internalState: 4,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Hospital Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        informationText:
            "A hospital pack is one that is only made available through hospital prescribing.",
    },
    limitedStability: {
        internalState: 4,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Limited Stability",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        informationText:
            "Some preparations require a diluent/vehicle to be added to them. If the resultant liquid preparation has a stability of 13 days or less, mark the preparation as limited stability.",
    },
    price: {
        internalState: 4,
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Price (in pence)",
        placeHolder: "Type",
        validationRules: [hasToBeFilled, hasToBePrice],
        informationText:
            "The price of this specific product pack in pence, sterling.  For example enter £3.62 as 362. For new product packs that are awaiting price confirmation, please enter 1.  See ‘entering data’ section of the user manual for more details.",
    },

    /**
     * SMPC FILE
     */

    seperatorSMPC: {
        isASection: true,
        internalState: 4,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "SmPC File",
    },

    smpcFile: {
        componentTypeName: "FileInput",
        componentType: FileInput,
        internalState: 4,
        fieldName: "SmPC File",
        validationRules: [hasToBeDocOrPDF, isFileSizeWithinRange],
    },
});

//-------------------------------------------------------------
// 		UPDATE PRODUCT
//-------------------------------------------------------------

export const UpdateProduct = ({formulations, licensedRoutes, flavours, controlledDrugCategories, currentLicensing, restrictions, bops, units, legalCategories}) => ({
    relatedProduct: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Existing Product Name",
        placeHolder: "Type to search a product",
        apiNameForOptionValue: "callAMP",
        handleData: handleDataForIngredientApi,
        informationText:
            "The product name is usually the trade name of the product + strength + form e.g. BrandX 50mg tablets or BrandX 150mg/15ml solution for injection ampoules.",
        validationRules: [hasToBeFilled],
    },

    /**
     * EFFECTIVE DATE
     */

    seperatorEffectiveDate: {
        internalState: 2,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Effective Date",
    },
    descriptionEffectiveDate: {
        internalState: 2,
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Please enter the effective date for changes. If the date that you choose is within 2 weeks of the current date, please note that the NHS BSA cannot commit to have actioned your request by this date.",
    },
    effectiveDate: {
        internalState: 2,
        componentTypeName: "DateInput",
        componentType: DateInput,
        fieldName: "Effective Date",
        informationText:
            "The effective date is the date your submission should be added or removed from the dm+d database. The change will be seen on the dm+d browser up to 5 working days later.",
        optionValue: defaultDateOptions,
        validationRules: [hasToBeFilledDate, hasToBeTodayOrFuture],
    },
    discontinueProduct: {
        internalState: 2,
        componentTypeName: "RadioButton",
        componentType: RadioButton,
        fieldName: "Discontinue Product?",
        placeHolder: "Type and Select",
        optionValue: yesNo,
        validationRules: [hasToBeFilled],
        // uniqueDispatch: "updateProductSubmission_handleDiscontinueProduct",
    },
    discontinueReason: {
        internalState: 2,
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Discontinue Reason",
        placeHolder: "Reason for discontinuation",
        informationText: "",
        validationRules: [hasToBeFilledIfVisible],
        hideFromUI: true,
    },

    /**
     * DETAILS
     */

    seperatorIntro: {
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Details",
        internalState: 3,
    },
    name: {
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Product Name",
        internalState: 3,
        // alwaysNonEditable: true,
        placeHolder: "Type New Product Name",
        informationText:
            "Changing the product name is optional. The product name is usually the trade name of the product + strength + form e.g. BrandX 50mg tablets or BrandX 150mg/15ml solution for injection ampoules.",
    },
    relatedVMP: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        internalState: 3,
        alwaysNonEditable: true,
        fieldName: "Related VMP",
        placeHolder: "Type to search a VMP",
        apiNameForOptionValue: "callVMP",
        handleData: handleDataForIngredientApi,
        informationText:
            "Virtual Medicinal Product (VMP) name is most commonly the generic name + strength + form, e.g. paracetamol 500mg capsule.",
        validationRules: [hasToBeFilled],
    },
    formulation: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Formulation",
        internalState: 3,
        alwaysNonEditable: true,
        optionValue: formulations,
    },

    licensedRoutes: {
        internalState: 3,
        componentTypeName: "MultipleSelectBox",
        componentType: MultipleSelectBox,
        fieldName: "Licensed Routes",
        placeHolder: "Type and Select",
        optionValue: licensedRoutes,
        informationText: "Select only the licensed route of administration as listed on the SPC.",
        validationRules: [hasToBeFilled],
    },

    /**
     * INGREDIENTS
     */

    seperatorIngredients: {
        isASection: true,
        internalState: 3,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Ingredients",
    },
    ingredients: {
        internalState: 3,
        alwaysNonEditable: true,
        componentTypeName: "Ingredients",
        componentType: Ingredients,
        fieldName: "Ingredients",
        validationRules: [thereMustBeAnIngAtLeast],
        removeButtonForIngsAndPacks: () => {},
    },
    ingredientName: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        internalState: 3,
        apiNameForOptionValue: "callIngredients",
        handleData: handleDataForIngredientApi,
        validationRules: [hasToBeFilled],
        dontSendToApi: true,
        hideFromUI: true,
    },
    bops: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        internalState: 3,
        validationRules: [hasToBeFilled],
        optionValue: bops,
        dontSendToApi: true,
        hideFromUI: true,
    },
    boss: {
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        internalState: 3,
        apiNameForOptionValue: "callIngredients",
        handleData: handleDataForIngredientApi,
        validationRules: [hasToBeFilled],
        dontSendToApi: true,
        hideFromUI: true,
    },
    numerator: {
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        internalState: 3,
        validationRules: [hasToBeFilledUnit],
        optionValue: units,
        dontSendToApi: true,
        hideFromUI: true,
    },
    denominator: {
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        internalState: 3,
        validationRules: [hasToBeFilledUnit],
        optionValue: units,
        dontSendToApi: true,
        hideFromUI: true,
    },

    /**
     * EXCIPIENTS
     */

    seperatorExcipients: {
        internalState: 3,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Excipients",
    },
    productFlavour: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Product Flavour",
        placeHolder: "Type and Select",
        optionValue: flavours,
        informationText: "If applicable, select any product flavourings from the list.",
    },
    glutenFreeProduct: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Gluten Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText: "Is your product gluten free?",
        validationRules: [hasToBeFilled],
    },
    preservativeFreeProduct: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Preservative Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText:
            "If your product is an eye drop, is it free from preservatives? If your product is not an eye drop, select not applicable.",
        validationRules: [hasToBeFilled],
    },
    sugarFreeProduct: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Sugar Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText:
            "If this product has prolonged contact in the mouth, is it sugar free? See glossary for examples.",
        validationRules: [hasToBeFilled],
    },
    cfcFreeProduct: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "CFC Free Product",
        placeHolder: "Type and Select",
        optionValue: yesNoNA,
        informationText:
            "If this product has prolonged contact in the mouth, is it sugar free? See glossary for examples.",
        validationRules: [hasToBeFilled],
    },

    /**
     * LICENCE DETAILS
     */

    seperatorLicense: {
        internalState: 3,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Licence Details",
    },
    controlledDrugCategory: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Controlled Drug Category",
        placeHolder: "Type and Select",
        optionValue: controlledDrugCategories,
        informationText:
            "Some drugs have restrictions on them according to the Misuse of Drugs Act 1971 and Misuse of Drugs Regulations. The SPC will state any restrictions and the controlled drug schedule.",
        validationRules: [hasToBeFilled],
    },
    currentLicensingAuthority: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Current Licensing Authority",
        placeHolder: "Type and Select",
        optionValue: currentLicensing,
        informationText: "For medicines registered by the MHRA or EMA, select Medicines-MHRA.",
        validationRules: [hasToBeFilled],
    },
    restrictionsOnAvailability: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Restrictions on Availability",
        placeHolder: "Type and Select",
        optionValue: restrictions,
        informationText:
            "Some products have restrictions on them e.g. only available through hospitals or through specific doctors or pharmacists. The glossary gives full definitions.",
        validationRules: [hasToBeFilled],
    },
    emaAdditionalMonitoring: {
        internalState: 3,
        componentTypeName: "RadioButton",
        componentType: RadioButton,
        fieldName: "EMA Additional Monitoring",
        placeHolder: "Type and Select",
        optionValue: yesNo,
        informationText:
            "This medicinal product is subject to additional monitoring. Its documentation carries a black triangle.",
        validationRules: [hasToBeFilled],
    },

    /**
     * PACK DETAILS
     */

    seperatorPacks: {
        internalState: 3,
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Pack Details",
    },
    descriptionPacks: {
        internalState: 3,
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "",
    },
    packs: {
        internalState: 3,
        componentTypeName: "Packs",
        componentType: Packs,
        fieldName: "Packs",
        validationRules: [thereMustBeAnPackAtLeast],
    },
    quantity: {
        internalState: 3,
        componentTypeName: "UnitSelect",
        componentType: UnitSelect,
        fieldName: "Quantity",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilledUnit],
        informationText:
            "Quantity/Unit means the number and type of ‘units’ in the pack e.g. 28 tablets or 5 cartridges. Type a numerical quantity in the first box. Select the units from the drop down list. E.g. ‘28’ and ‘tablet’ or ‘10’ and ‘ml’ or ‘5’ and ‘cartridge.",
        optionValue: units,
        dontSendToApi: true,
        hideFromUI: true,
    },
    legalCategory: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Legal Category",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: legalCategories,
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "The legal category of this specific product pack. Use ‘not applicable’ for all non-medicine packs e.g. appliances.",
    },
    subpackInfo: {
        internalState: 3,
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Subpack Information",
        placeHolder: "Type",
        validationRules: [hasToBeFilled],
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "Subpack information explains how the product is packaged e.g. the number of strips of tablets in a pack. For a 28 tablet pack in 2 strips, enter 2x14. For a 28 tablet pack in 1 strip enter 1x28. For a single pack of 1 vial, 1 ampoule, 1 tube or 1 tub, enter not applicable.",
    },
    gtinCodes: {
        internalState: 3,
        componentTypeName: "MultipleTextInput",
        componentType: MultipleTextInput,
        fieldName: "GTIN Codes",
        placeHolder: "Type and add",
        validationRules: [hasToBeFilled, gtinCodesControl],
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "This is a mandatory field - include between 1 and 20 GTIN codes. Use the drop down list to increase the number of GTIN fields.",
    },
    calendarPack: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Calendar Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "A calendar pack is a blister or strip pack showing the days of the week or month against each of the several units in the pack.",
    },
    hospitalPack: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Hospital Pack",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "A hospital pack is one that is only made available through hospital prescribing.",
    },
    limitedStability: {
        internalState: 3,
        componentTypeName: "SelectBox",
        componentType: SelectBox,
        fieldName: "Limited Stability",
        placeHolder: "Type and Select",
        validationRules: [hasToBeFilled],
        optionValue: yesNo,
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "Some preparations require a diluent/vehicle to be added to them. If the resultant liquid preparation has a stability of 13 days or less, mark the preparation as limited stability.",
    },
    price: {
        internalState: 3,
        componentTypeName: "TextInput",
        componentType: TextInput,
        fieldName: "Price (in pence)",
        placeHolder: "Type",
        validationRules: [hasToBeFilled, hasToBePrice],
        dontSendToApi: true,
        hideFromUI: true,
        informationText:
            "The price of this specific product pack in pence, sterling.  For example enter £3.62 as 362. For new product packs that are awaiting price confirmation, please enter 1.  See ‘entering data’ section of the user manual for more details.",
    },
    savePackButton: {
        internalState: 3,
        componentTypeName: "Button",
        componentType: Button,
        hideFromUI: true,
        dontSendToApi: true,
        text: "SAVE PACK",
        buttonType: "deactive", // "deactive" || ""
        onClick: (obj, additionalData) => {
            let data = obj.data;

            const NAMES_TO_CHECK = [
                "quantity",
                "legalCategory",
                "subpackInfo",
                "gtinCodes",
                "calendarPack",
                "hospitalPack",
                "limitedStability",
                "price",
            ];

            const totalErrors = NAMES_TO_CHECK.reduce((total, name) => {
                const field = data[name];
                const errorText = field?.errorText;
                const count = errorText?.length || 0;

                return total + count;
            }, 0);

            if (totalErrors > 0) {
                return;
            }

            let newPacksCurrentValue = {
                ...data.packs.currentValue,
                [data.quantity.currentValue.input + "" + data.quantity.currentValue.unit.label]: {
                    quantity: { currentValue: data.quantity.currentValue },
                    legalCategory: { currentValue: data.legalCategory.currentValue },
                    subpackInfo: { currentValue: data.subpackInfo.currentValue },
                    gtinCodes: { currentValue: data.gtinCodes.currentValue },
                    calendarPack: { currentValue: data.calendarPack.currentValue },
                    hospitalPack: { currentValue: data.hospitalPack.currentValue },
                    limitedStability: { currentValue: data.limitedStability.currentValue },
                    price: { currentValue: data.price.currentValue },
                },
            };

            if (!obj.settings.isThereAnyChange) {
                obj.setSettings({ ...obj.settings, isThereAnyChange: true });
            }

            obj.setData({
                ...data,
                packs: {
                    ...data.packs,
                    currentValue: newPacksCurrentValue,
                    errorText: obj.findError("packs", newPacksCurrentValue),
                },
                savePackButton: {
                    ...data.savePackButton,
                    hideFromUI: true,
                },
                addPackButton: {
                    ...data.addPackButton,
                    hideFromUI: false,
                },

                quantity: {
                    ...data.quantity,
                    currentValue: null,
                    errorText: obj.findError("quantity", null),
                    hideFromUI: true,
                },
                legalCategory: {
                    ...data.legalCategory,
                    currentValue: null,
                    errorText: obj.findError("legalCategory", null),
                    hideFromUI: true,
                },
                subpackInfo: {
                    ...data.subpackInfo,
                    currentValue: null,
                    errorText: obj.findError("subpackInfo", null),
                    hideFromUI: true,
                },
                gtinCodes: {
                    ...data.gtinCodes,
                    currentValue: null,
                    errorText: obj.findError("gtinCodes", null),
                    hideFromUI: true,
                },
                calendarPack: {
                    ...data.calendarPack,
                    currentValue: null,
                    errorText: obj.findError("calendarPack", null),
                    hideFromUI: true,
                },
                hospitalPack: {
                    ...data.hospitalPack,
                    currentValue: null,
                    errorText: obj.findError("hospitalPack", null),
                    hideFromUI: true,
                },
                limitedStability: {
                    ...data.limitedStability,
                    currentValue: null,
                    errorText: obj.findError("limitedStability", null),
                    hideFromUI: true,
                },
                price: {
                    ...data.price,
                    currentValue: null,
                    errorText: obj.findError("price", null),
                    hideFromUI: true,
                },
            });
        },
    },

    /**
     * SMPC FILE
     */

    seperatorSMPC: {
        internalState: 3,
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "SmPC File",
    },
    smpcFile: {
        componentTypeName: "FileInput",
        componentType: FileInput,
        internalState: 3,
        fieldName: "SmPC File",
        validationRules: [hasToBeDocOrPDF, isFileSizeWithinRange],
    },
});

//-------------------------------------------------------------
// 		MASS PRICE UPDATE
//-------------------------------------------------------------

export const MassPriceUpdate = {
    /**
     * EFFECTIVE DATE
     */

    seperatorEffectiveDate: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Effective Date",
    },

    descriptionEffectiveDate: {
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Please enter the effective date for changes. If the date that you choose is within 2 weeks of the current date, please note that the NHS BSA cannot commit to have actioned your request by this date.",
    },
    
    effectiveDate: {
        componentTypeName: "DateInput",
        componentType: DateInput,
        fieldName: "Effective Date",
        optionValue: defaultDateOptions,
        informationText:
            "The effective date is the date your submission should be added or removed from the dm+d database. The change will be seen on the dm+d browser up to 5 working days later.",
        validationRules: [hasToBeFilledDate, hasToBeTodayOrFuture],
    },

    seperatorIntro: {
        isASection: true,
        componentTypeName: "Seperator",
        componentType: Seperator,
        placeHolder: "Prices",
    },

    descriptionPrices: {
        componentTypeName: "Description",
        componentType: Description,
        placeHolder:
            "Please make up to 50 Price Changes using the form below"
    },

    prices: {
        internalState: 1,
        componentTypeName: "DataGrid",
        componentType: DataGrid,
        fieldName: "Prices",
        placeHolder: "Pack Prices",
        validationRules: [thereMustBeAPriceUpdateAtLeast,maxofPriceInput]
    },
};
