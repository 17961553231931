import React, { useState, useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { SelectBox } from "../SelectBox/SelectBox.js";
import { TextInput } from "../TextInput/TextInput.js";

export const UnitSelect = (props) => {
    const { data, keyName, updateData, viewMode } = props;
    const {
        fieldName,
        currentValue,
        informationText,
        optionValue,
        errorText,
        validationRules,
    } = data;
    const { turnArrayToLines } = useContext(GlobalContext);

    const [firstFocus, setFirstFocus] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    /**
     * TOGGLE FIRST FOCUS
     */
    const toggleFirstFocus = () => {
        if (!firstFocus) {
            setFirstFocus(true);
        }
    };

    /**
     * UPDATE DATA_LOCAL
     */
    const updateData_local = (fieldName, newDataObj, propName) => {
        if (!propName) {
            propName = "currentValue";
        }

        if (fieldName === "input") {
            updateData(keyName, {
                ...currentValue,
                input: newDataObj,
            });
        } else if (fieldName === "unit") {
            updateData(keyName, {
                ...currentValue,
                unit: newDataObj,
            });
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (data.dontSendToApi && viewMode === "view") {
        return null;
    } else if (viewMode === "view") {
        return (
            <div className="view_single">
                <div>{fieldName}</div>
                <div>
                    <span>
                        {currentValue?.input && currentValue?.unit
                            ? currentValue.input + " " + currentValue.unit.label
                            : ""}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div id={keyName} className={"form_unitSelect " + (errorText?.length > 0 ? "error" : "")}>
            {fieldName ? (
                <label
                    className={
                        "form_label" +
                        (validationRules?.length > 0 &&
                        errorText?.length > 0 &&
                        (!currentValue || !currentValue.unit || !currentValue.input)
                            ? " onLoadControlError"
                            : "")
                    }
                >
                    {fieldName}
                </label>
            ) : null}

            <div className="form_dateSelectContainer">
                <div style={{ width: "50%" }}>
                    <TextInput
                        keyName={"input"}
                        updateData={updateData_local}
                        viewMode={viewMode}
                        data={{
                            placeHolder: "Value",
                            savedValue: "",
                            currentValue: currentValue?.input,
                        }}
                        additionalStyle={{ marginBottom: 0 }}
                        setIsFocused2={(boo) => {
                            setIsFocused(boo);
                            if (boo) {
                                toggleFirstFocus();
                            }
                        }}
                    />
                </div>
                <div style={{ width: "50%" }}>
                {fieldName === "Denominator Strength" ? 
                (
                    <span 
                        className="clearButton" 
                        onClick={() => {
                            updateData(keyName, {
                                ...currentValue,
                                input: '',
                                unit: '',
                            });
                        }}>
                        Clear
                    </span>
                )
                : null}
                    <SelectBox
                        keyName={"unit"}
                        updateData={updateData_local}
                        viewMode={viewMode}
                        data={{
                            placeHolder: "Unit",
                            savedValue: "",
                            optionValue: optionValue,
                            currentValue: currentValue?.unit,
                        }}
                        additionalStyle={{ marginBottom: 0 }}
                        setIsFocused2={(boo) => {
                            setIsFocused(boo);
                            if (boo) {
                                toggleFirstFocus();
                            }
                        }}
                    />
                </div>
            </div>

            {informationText ? (
                <div className={isFocused ? "form_infoText_on" : "form_infoText"}>
                    {informationText}
                </div>
            ) : null}
            {firstFocus && errorText?.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
        </div>
    );
};
