import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";

export const HeaderActions = (props) => {
    const { headerActionsScrollYPosition } = props;
    const {
        pageName,
        headerTitles,
        callSaveAndContinue,
        setCallSaveAndContinue,
        callPreSection,
        setCallPreSection,
        callNextSection,
        setCallNextSection,
    } = useContext(GlobalContext);
    const history = useHistory();

    /**
     * GO BACK with useHistory
     */
    const goBack = () => {
        history.goBack();
    };

    /**
     *  SCROLL TO ACTIONS ZONE
     */
    const scrollToAction = () => {
        document.getElementById("userActionsZone").scrollIntoView({
            behavior: "smooth",
        });
    };

    //-------------------------------------------------------------
    // 		RETURN FOR ABOUT
    //-------------------------------------------------------------
    if (pageName === "aboutPage") {
        return (
            <div
                className="listing_subActions"
                style={{
                    top: headerActionsScrollYPosition,
                    paddingTop: headerActionsScrollYPosition === -50 ? 60 : 70,
                }}
            >
                <div
                    onClick={() => {
                        goBack();
                    }}
                >
                    BACK
                </div>
            </div>
        );
    }

    //-------------------------------------------------------------
    // 		RETURN FOR HANDLE PAGE
    //-------------------------------------------------------------
    else if (pageName.split("/")[0] === "handlePage") {
        return (
            <div
                className="listing_subActions"
                style={{
                    top: headerActionsScrollYPosition,
                    paddingTop: headerActionsScrollYPosition === -50 ? 60 : 70,
                }}
            >
                <div className="listing_subActions2">
                    <div
                        onClick={() => {
                            goBack();
                        }}
                    >
                        BACK
                    </div>
                    <div
                        onClick={() => {
                            scrollToAction();
                        }}
                    >
                        ACTIONS
                    </div>
                    {headerTitles.currentAction !== "view" ||
                    (headerTitles.status !== "Draft" &&
                        headerTitles.status !== "ReadyForAuthorisation") ? null : (
                        <Link to={"/edit/" + headerTitles.id}>EDIT</Link>
                    )}
                </div>
                <div className="listing_subActions3"></div>
                <div className="listing_subActions4">
                    {headerTitles.currentAction !== "view" ||
                    (headerTitles.status !== "Draft" &&
                        headerTitles.status !== "ReadyForAuthorisation") ? (
                        <React.Fragment>
                            <img
                                className="arrowSVG"
                                src={require("img/upArrow.svg")}
                                alt=""
                                title="Go To Previous Section (Upwards Arrow)"
                                onClick={() => {
                                    setCallPreSection(!callPreSection);
                                }}
                            />
                            <img
                                className="arrowSVG"
                                src={require("img/save.svg")}
                                alt=""
                                title="Save & Continue (Ctrl + S)"
                                onClick={() => {
                                    setCallSaveAndContinue(!callSaveAndContinue);
                                }}
                            />
                            <img
                                className="arrowSVG"
                                src={require("img/downArrow2.svg")}
                                alt=""
                                title="Go To Next Section (Downwards Arrow)"
                                onClick={() => {
                                    setCallNextSection(!callNextSection);
                                }}
                            />
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }

    //-------------------------------------------------------------
    // 		RETURN FOR LISTING PAGE
    //-------------------------------------------------------------
    else if (pageName === "listingPage") {
        return (
            <div
                className="listing_subActions"
                style={{
                    top: headerActionsScrollYPosition,
                    paddingTop: headerActionsScrollYPosition === -50 ? 60 : 70,
                }}
            >
                <div
                    onClick={() => {
                        goBack();
                    }}
                >
                    BACK
                </div>
                {history.location.search === "" ||
                headerTitles.submissionType === "All Submissions" ? null : (
                    <div
                        onClick={() => {
                            history.push("/list");
                        }}
                    >
                        CLEAR {headerTitles.submissionType === "Search" ? "SEARCH" : "FILTER"}
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
};
