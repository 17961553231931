import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { PackBox } from "../PackBox/PackBox.js";

export const Packs = (props) => {
    const { data, keyName, additionalStyle, viewMode, removeButtonForIngsAndPacks } = props;
    const { errorText, alwaysNonEditable, currentValue } = data;
    const { turnArrayToLines } = useContext(GlobalContext);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (data === {}) {
        return null;
    } else {
        return (
            <div id={keyName} style={additionalStyle}>
                {/* LIST OF ADDED PACKS */}
                {!currentValue && viewMode === "view" ? (
                    <div style={{ marginLeft: 40 }}>There isn't any pack.</div>
                ) : !currentValue ? null : Object.entries(currentValue).length > 0 ? (
                    Object.entries(currentValue).map(([key, item]) => {
                        return (
                            <PackBox
                                key={key}
                                keyName={key}
                                viewMode={viewMode}
                                currentAll={item}
                                remove={(additionalData) => {
                                    removeButtonForIngsAndPacks(additionalData);
                                }}
                            />
                        );
                    })
                ) : null}

                {/* ERROR TEXT */}
                {errorText &&
                errorText.length > 0 &&
                viewMode !== "view" &&
                alwaysNonEditable !== true ? (
                    <div className="form_errorText_on" style={{ marginBottom: 30 }}>
                        {turnArrayToLines(errorText)}
                    </div>
                ) : null}
            </div>
        );
    }
};
