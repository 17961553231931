import React, { useLayoutEffect, useState, useContext } from "react";
import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";
import useEventListener from "@use-it/event-listener";
import _ from "lodash";

import { GlobalContext } from "contexts/GlobalStore.js";
import { UserContext } from "contexts/UserStore.js";
import { Button } from "components/FormElements/Button/Button.js";
import { Seperator } from "components/FormElements/Seperator/Seperator.js";
import { ApprovalZone } from "components/FormElements/ApprovalZone/ApprovalZone.js";
import { FetchAPI } from "components/APIConnections/APIConnections.js";
import { IsLoading } from "components/IsLoading/IsLoading.js";

export const UserActions = (props) => {
    const {
        data,
        errorFieldNameClicked,
        settings,
        setSettings,
        sections,
        convertCurrentValueToSavedValue,
        silentUpdateData,
        areTheyEqual,
    } = props;
    const {
        ID,
        action,
        status,
        submissionType,
        submissionID,
        internalState,
        isThereAnyChange,
    } = settings;
    const {
        isNotEmpty,
        callSaveAndContinue,
        callPreSection,
        callNextSection,
        headerTitles,
        triggerGlobalAlert,
        showErrorLogsOnConsole,
    } = useContext(GlobalContext);
    const { userState } = useContext(UserContext);
    const [allErrorFields, setAllErrorFields] = useState([]);
    let history = useHistory();

    const [propsForApprovalState, setPropsForApprovalState] = useState();
    const [localIsLoading, setLocalIsLoading] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [promptWhen, setPromptWhen] = useState(true);

    /**
     * FIND ALL ERROR FIELDS
     */
    useLayoutEffect(() => {
        if (!data) {
            return;
        }
        let newAllErrorFields = [];

        Object.entries(data).map(([key, item]) => {
            if (
                (action !== "view" && (item.internalState || 1) > (internalState || 1)) ||
                item.dontSendToApi
            ) {
                return null;
            }

            if (item.errorText && item.errorText !== [] && item.errorText.length > 0) {
                newAllErrorFields.push(key);
            }
            return null;
        });

        setAllErrorFields(newAllErrorFields);
    }, [data, internalState, action]);

    /**
     * EVENT LISTENER FOR CTRL + S - AND ARROW KEYS
     * @description ctrl S is triggering save as draft -> saveAndContinue in UserActions
     */
    useEventListener("keydown", (e) => {
        if (action === "view") {
            return;
        }

        const preventDef = (e) => {
            if (e.preventDefault) {
                e.preventDefault();
            }
        };

        let isCtrlKeyDown = navigator.platform.indexOf("Mac") > -1 ? e.metaKey : e.ctrlKey;
        let isSDown = (e.key && e.key === "s") || (e.keyCode || e.which) === 83;

        if (isCtrlKeyDown && isSDown) {
            preventDef(e);
            saveAndContinue();
            return false;
        }
    });

    /**
     * DISABLE or ENABLE SCROLLING DEPENDING on APPROVAL STATEs
     */
    useLayoutEffect(() => {
        if (propsForApprovalState || localIsLoading) {
            // scroll disabled
            document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:hidden;");
        } else {
            // scroll enabled
            document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:auto;");
        }
    }, [propsForApprovalState, localIsLoading]);

    /**
     * UNMOUNT EFFECT FOR ENABLE SCROLLING.
     * @description need this, because on page switch, scrolling left disabled without this correction.
     */
    useLayoutEffect(() => {
        setPromptWhen(true);
        return () => {
            setLocalIsLoading(false);
            setPropsForApprovalState();
            document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y:auto;");
        };
    }, []);

    /**
     * REMOVE FIRST RENDER
     */
    useLayoutEffect(() => {
        setFirstRender(false);
    }, []);

    /**
     * HANDLE GO TO TOP
     * @description Goes to top of the page smoothly.
     */
    const handleGoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    /**
     * LISTEN JUMP TO SECTION
     */
    const jumpToSection = (preOrNext) => {
        if (
            (submissionType === "UpdateProduct" && internalState < 3) ||
            (submissionType === "UpdatePack" && internalState < 4)
        ) {
            return;
        }

        let scrollY = Math.round(
            window.scrollY ||
                window.scrollTop ||
                document.getElementsByTagName("html")[0].scrollTop,
        );

        let allSectionYValues = [];
        sections.forEach((item) => {
            allSectionYValues.push(document.getElementById(item).offsetTop);
        });
        allSectionYValues.sort(function (a, b) {
            return a - b;
        });

        const arraySiraliEkle = (array, elem) => {
            let i = 0;
            while (i < array.length && array[i] < elem) {
                i++;
            }
            return i;
        };

        let i = arraySiraliEkle(allSectionYValues, scrollY);

        let prePoint = allSectionYValues[i - 1];
        if (prePoint === scrollY || (prePoint < scrollY + 10 && prePoint > scrollY - 10)) {
            prePoint = allSectionYValues[i - 2];
        }
        if (!prePoint) {
            prePoint = 0;
        }

        let nextPoint = allSectionYValues[i];
        if (nextPoint === scrollY || (nextPoint < scrollY + 10 && nextPoint > scrollY - 10)) {
            nextPoint = allSectionYValues[i + 1];
        }
        if (!nextPoint) {
            nextPoint = document.body.scrollHeight;
        }

        if (preOrNext === "pre") {
            window.scrollTo({ top: prePoint, left: 0, behavior: "smooth" });
        } else if (preOrNext === "next") {
            window.scrollTo({ top: nextPoint, left: 0, behavior: "smooth" });
        }
    };

    /**
     * CHANGE STATUS
     * @description change submission status from Draft to "ReadyForAuthorisation" or "PendingSubmission" or "Cancelled"
     * This works, if the user editing the page.
     * Otherwise, if user creating a new page, (action==="new") mark button directs user to SaveAndGoHome function.
     */
    const changeStatus = async (newStatus) => {
        if (!newStatus) {
            return;
        }
        let serverReply;

        if (newStatus === "cancel") {
            serverReply = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                callbackWithErrorStatusOrConfirmation: true,
                history: history,
                apiShortName: "markAsCancelled",
                token: userState.accessToken,
                apiNeeds: {
                    ID: ID,
                    submissionID: submissionID,
                    companyId: userState.selectedCompany?.id,
                },
                setIsLoading: (boo) => {
                    setLocalIsLoading(boo);
                },
            });
        } else if (newStatus === "rfa") {
            serverReply = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                callbackWithErrorStatusOrConfirmation: true,
                history: history,
                apiShortName: "updateASubmission",
                token: userState.accessToken,
                apiNeeds: {
                    companyId: userState.selectedCompany?.id,
                    ID: ID,
                    submissionID: submissionID,
                    name: settings.name,
                    status: "ReadyForAuthorisation",
                },
                setIsLoading: (boo) => {
                    setLocalIsLoading(boo);
                },
            });
        } else if (newStatus === "nhs") {
            serverReply = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                callbackWithErrorStatusOrConfirmation: true,
                history: history,
                apiShortName: "updateASubmission",
                token: userState.accessToken,
                apiNeeds: {
                    companyId: userState.selectedCompany?.id,
                    ID: ID,
                    submissionID: submissionID,
                    name: settings.name,
                    status: "PendingSubmission",
                },
                setIsLoading: (boo) => {
                    setLocalIsLoading(boo);
                },
            });
        } else {
            history.push({ pathname: "/error/missingStatusName" });
        }

        if (serverReply.ok === false || serverReply.id === undefined) {
            // !permissions.ok  gives an error because undefined is not a false in BE side.
            setLocalIsLoading(false);
            setPropsForApprovalState({
                pathName: "/",
                headerText: "A server error has occurred (" + serverReply.status + ")",
                redText: "Your submission has not been successful. ",
                areYouSureText: "Would you like to try again? ",
                onConfirmText: "Yes",
                onConfirm: () => {
                    setPropsForApprovalState();
                },
                onDeclineText: "No",
                onDecline: () => {
                    history.push({ pathname: "/" });
                },
            });
        } else {
            setPromptWhen(false);
            history.push({ pathname: "/" });
        }
    };

    /**
     * CREATE THE API PACKAGE for CURRENT VALUES and POST IT
     * @description updates store depending on changes via fields.
     */
    const createApiPackage = async () => {
        if (!data) {
            return false;
        }
        let newData = _.cloneDeep(data);

        /**
         * CHECK IF FILE IS UPLOADED or NOT
         * Then, upload if it's not uploaded yet.
         */
        if (
            (submissionType === "NewProduct" ||
                submissionType === "NewPack" ||
                submissionType === "UpdateProduct" ||
                submissionType === "UpdatePack") &&
            data.smpcFile?.currentValue?.fileProps &&
            !data.smpcFile?.currentValue?.fileUrl
        ) {
            let serverReply = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                callbackWithErrorStatusOrConfirmation: true,
                history: history,
                apiShortName: "fileUpload",
                token: userState.accessToken,
                apiNeeds: {
                    fileProps: data.smpcFile.currentValue.fileProps[0],
                },
            });

            let URL = serverReply.uri;

            newData.smpcFile.currentValue.fileUrl = URL;
            silentUpdateData("smpcFile", {
                ...data.smpcFile.currentValue,
                fileUrl: URL,
            });
        }

        /**
         * CREATE API PACKAGE
         */
        let apiPackage = {};

        Object.keys(newData).forEach((item) => {
            if (
                !newData[item].dontSendToApi &&
                isNotEmpty(newData[item].currentValue) &&
                (newData[item].internalState || 1) <= (internalState || 1)
            ) {
                apiPackage[item] = convertCurrentValueToSavedValue(
                    newData[item],
                    newData[item].currentValue,
                );
            }
        });

        if (Object.keys(apiPackage).length === 0) {
            return false;
        }

        /**
         * @description NewPack doesn't have name field. But Api needs it.
         * In here, I'm creating a name field for newPack and for other forms as well, if their name field is empty.
         * Because validations are not blocking for Draft Saving.
         */
        if (!apiPackage.name) {
            apiPackage.name = headerTitles.productName || "No Name";
        }
        /**
         * UpdateProduct and UpdatePack needs some extra fields depending on discontinue product or pack values.
         */
        if (submissionType === "UpdateProduct" || submissionType === "UpdatePack") {
            if (
                (data.discontinueProduct || data.discontinuePack) &&
                (data.discontinueProduct?.currentValue?.label === "Yes" ||
                    data.discontinuePack?.currentValue?.label === "Yes")
            ) {
                apiPackage.discontinued = true;
            }
        }
        /**
         * Add Single Price update here --->
         */
        if (submissionType === "UpdatePack") {
            apiPackage.singlePriceUpdate = false;

            if (settings.inComingPack && data.price) {
                const relatedFields_isItSinglePriceSub = [
                    "quantity",
                    "legalCategory",
                    "subpackInfo",
                    "gtinCodes",
                    "calendarPack",
                    "hospitalPack",
                    "limitedStability",
                ];

                if (
                    !areTheyEqual(settings.inComingPack.price.currentValue, data.price.currentValue)
                ) {
                    apiPackage.singlePriceUpdate = true;

                    for (let i = 0; i < relatedFields_isItSinglePriceSub.length; i++) {
                        let item = relatedFields_isItSinglePriceSub[i];
                        if (
                            !areTheyEqual(
                                settings.inComingPack[item].currentValue,
                                data[item].currentValue,
                            )
                        ) {
                            apiPackage.singlePriceUpdate = false;
                            break;
                        }
                    }
                }
            }
        }

        /**
         * remove 0 items from the list
         */
        if (submissionType === "MassPriceUpdate") {
            apiPackage["prices"] = apiPackage["prices"].filter(item => item.newPrice > 0);
            const priceLength = apiPackage["prices"].length;

            if(priceLength === 0)
                return null;

            apiPackage["name"] = apiPackage["name"].replace(/(\d+)/g, priceLength);
        }

        return apiPackage;
    };

    /**
     * POST SUBMISSION is a assistant function.
     * Is called by SaveAndContinue or SaveAndGoHome functions.
     */
    const postSubmission = async (apiPackage, newStatus = "draft", disableIsLoading = false) => {
        let newStatusName;
        if (newStatus === "draft") {
            newStatusName = "Draft";
        } else if (newStatus === "rfa") {
            newStatusName = "ReadyForAuthorisation";
        } else if (newStatus === "nhs") {
            newStatusName = "PendingSubmission";
        } else if (newStatus === "cancel") {
            newStatusName = "Cancelled";
        }

        /**
         * CREATE SUBMISSION DEPENDING ON REQUEST
         */
        let serverReply = await FetchAPI({
            showErrorLogsOnConsole: showErrorLogsOnConsole,
            callbackWithErrorStatusOrConfirmation: true,
            history: history,
            apiShortName: action === "new" ? "postNewSubmission" : "updateASubmission",
            token: userState.accessToken,
            apiNeeds: {
                title: apiPackage.name,
                status: newStatusName, // --> Draft RFA or pending submission -> CHANGE HERE.
                type: submissionType,
                companyId: userState.selectedCompany?.id,
                ...(action === "edit" && { ID: ID, submissionID: submissionID }),
                data: {
                    ...apiPackage,
                },
            },
        });

        return serverReply;
    };
    const saveAndGoHome = async (newStatus) => {
        let apiPackage = await createApiPackage();
        if (!apiPackage) {
            return;
        }

        setLocalIsLoading(true);

        let serverReply = await postSubmission(apiPackage, newStatus);

        // GO HOME AFTER SAVING

        if (serverReply.ok === false || serverReply.id === undefined) {
            let headerText = "A server error has occurred (" + serverReply.status + ")";
            let redText = "Your data has not been saved.";

            if(serverReply.status === 400) {
                headerText = "Invalid Data";
                redText = await serverReply.text();
            }

            setLocalIsLoading(false);
            setPropsForApprovalState({
                pathName: "/",
                headerText: headerText,
                redText: redText,
                areYouSureText: "Would you like to try again? ",
                onConfirmText: "Yes",
                onConfirm: () => {
                    setPropsForApprovalState();
                }, 
                onDeclineText: "No",
                onDecline: () => {
                    history.push({ pathname: "/" });
                },
            });
        } else {
            setLocalIsLoading(false);
            setPromptWhen(false);
            history.push({ pathname: "/" });
        }
    };
    const saveAndContinue = async () => {
        if (!isThereAnyChange) {
            return;
        }
        let apiPackage = await createApiPackage();
        if (!apiPackage) {
            return;
        }

        if (showErrorLogsOnConsole) {
            console.log(apiPackage);
        }

        let serverReply = await postSubmission(apiPackage, "draft", false);

        if (serverReply.ok === false || serverReply.id === undefined) {
            triggerGlobalAlert({
                text: "Your data has not been saved. (" + serverReply.status + ")",
                status: "warn",
            });
        } else if (action === "new") {
            setPromptWhen(false);
            history.push("/edit/" + serverReply.id);
        } else {
            triggerGlobalAlert({
                text: "Saved successfully.",
            });
            setSettings({ ...settings, isThereAnyChange: false });
        }
    };

    useLayoutEffect(() => {
        if (firstRender) {
            return;
        }
        saveAndContinue();
    }, [callSaveAndContinue]);
    useLayoutEffect(() => {
        if (firstRender) {
            return;
        }
        jumpToSection("pre");
    }, [callPreSection]);
    useLayoutEffect(() => {
        if (firstRender) {
            return;
        }
        jumpToSection("next");
    }, [callNextSection]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    return (
        <React.Fragment>
            {/* REF POINT */}
            <div id="userActionsZone"></div>

            {/* PROMPT */}
            <Prompt
                when={promptWhen}
                message={(location) => {
                    if (
                        action === "view" ||
                        !isThereAnyChange ||
                        (propsForApprovalState &&
                            location.pathname === propsForApprovalState.pathName)
                    ) {
                        setPropsForApprovalState();
                        return true;
                    } else {
                        setPropsForApprovalState({
                            pathName: location.pathname,
                            headerText: "Do You Want To Leave?",
                            redText: "You may lose changes on form.",
                            onConfirm: () => {
                                history.push(location.pathname);
                            },
                            onDecline: () => {
                                setPropsForApprovalState();
                            },
                        });
                        return false;
                    }
                }}
            />

            {/* INTERNAL USAGE FOR ISLOADING */}
            <IsLoading internalBoo={localIsLoading} />

            {/* APPROVAL STAGE  */}
            {!propsForApprovalState ? null : (
                <ApprovalZone
                    headerText={propsForApprovalState.headerText}
                    redText={propsForApprovalState.redText}
                    onConfirmText={propsForApprovalState.onConfirmText}
                    onConfirm={propsForApprovalState.onConfirm}
                    onDeclineText={propsForApprovalState.onDeclineText}
                    onDecline={propsForApprovalState.onDecline}
                    areYouSureText={propsForApprovalState.areYouSureText}
                />
            )}

            {/* ACTIONS SEPERATOR */}
            {status === "Cancelled" ? null : <Seperator placeHolder="Actions" />}

            {/* SAVE AS DRAFT - DISCARD CHANGES  */}
            {action === "view" || status !== "Draft" ? null : (
                <React.Fragment>
                    <Button
                        buttonType={
                            !isThereAnyChange
                                ? "deactive half"
                                : allErrorFields && allErrorFields.length === 0
                                ? "green half"
                                : "half"
                        }
                        // throttle
                        text="SAVE AS DRAFT"
                        onClick={() => {
                            saveAndGoHome("draft");
                        }}
                    />
                    <Button
                        buttonType={!isThereAnyChange ? "deactive half" : "red half"}
                        text="DISCARD CHANGES"
                        onClick={() => {
                            setPropsForApprovalState({
                                pathName: "/",
                                headerText: "Discard Changes Confirmation",
                                redText: "Any changes made may be lost.",
                                onConfirm: () => {
                                    history.push("/");
                                },
                                onDecline: () => {
                                    setPropsForApprovalState();
                                },
                            });
                        }}
                        additionalStyle={{ marginRight: 0 }}
                    />
                </React.Fragment>
            )}

            {/* VALIDATION ERRORS BOX for VIEW+DRAFT */}
            {action === "view" &&
            status === "Draft" &&
            allErrorFields &&
            allErrorFields.length > 0 ? (
                <div className="errorfields_listing" style={{ marginTop: 40 }}>
                    You have missing fields in the form. For marking the submission as "Ready to
                    Authorisation" or for submitting submission to NHS, please edit and fill all
                    mandatory fields.
                </div>
            ) : null}

            {/* VALIDATION ERRORS DETAILS BOX for NEW-EDIT */}
            {action !== "view" &&
            status === "Draft" &&
            allErrorFields &&
            allErrorFields.length > 0 ? (
                <div className="errorfields_listing" style={{ marginTop: 15, marginBottom: 15 }}>
                    You have missing fields in the form. For marking the submission as "Ready to
                    Authorisation" or for submitting submission to NHS, please correct these fields:
                    &nbsp;
                    <br />
                    {allErrorFields.map((error) => {
                        let errorFieldName = data[error].fieldName;

                        return (
                            <span
                                key={error}
                                onClick={() => {
                                    errorFieldNameClicked(error);
                                }}
                            >
                                {errorFieldName}
                            </span>
                        );
                    })}
                </div>
            ) : null}

            {/* EDIT SUBMISSION BUTTON  */}

            {action === "view" && status === "Draft" ? (
                <Button
                    buttonType="max"
                    text="EDIT SUBMISSION"
                    onClick={() => {
                        history.push("/edit/" + ID);
                    }}
                />
            ) : null}

            {/* MARK AS READY FOR AUTH. BUTTON */}
            {(!allErrorFields || allErrorFields.length === 0) &&
            userState.currentPermLevel === 1 &&
            status === "Draft" ? (
                <Button
                    buttonType="green max"
                    text={action === "view" ? "MARK AS READY" : "SAVE and MARK AS READY"}
                    onClick={() => {
                        setPropsForApprovalState({
                            pathName: null,
                            headerText: "Mark As Ready Confirmation",
                            redText: "You are about to change your submission's status.",
                            onConfirm: () => {
                                if (action === "view") {
                                    changeStatus("rfa");
                                } else {
                                    saveAndGoHome("rfa");
                                }
                                setPropsForApprovalState();
                            },
                            onDecline: () => {
                                setPropsForApprovalState();
                            },
                        });
                    }}
                />
            ) : null}

            {/* SUBMIT TO NHS BUTTON */}
            {(!allErrorFields || allErrorFields.length === 0) &&
            userState.currentPermLevel === 2 &&
            (status === "Draft" || status === "ReadyForAuthorisation") ? (
                <Button
                    buttonType="green max"
                    text={action === "view" ? "SUBMIT TO NHS BSA" : "SAVE and SUBMIT TO NHS BSA"}
                    onClick={() => {
                        setPropsForApprovalState({
                            pathName: null,
                            headerText: "Submit Submission to NHS BSA",
                            redText: "You are about to change your submission's status.",
                            onConfirm: () => {
                                if (action === "view") {
                                    changeStatus("nhs");
                                } else {
                                    saveAndGoHome("nhs");
                                }
                                setPropsForApprovalState();
                            },
                            onDecline: () => {
                                setPropsForApprovalState();
                            },
                        });
                    }}
                />
            ) : null}

            {/* MARK AS CANCELLED BUTTON */}
            {(status !== "Draft" &&
                status !== "ReadyForAuthorisation" &&
                status !== "PendingSubmission" &&
                status !== "WithNhsBsa") ||
            action === "new" ? null : (
                <Button
                    buttonType="red max"
                    text="MARK AS CANCELLED"
                    onClick={() => {
                        setPropsForApprovalState({
                            pathName: null,
                            headerText: "Cancellation Confirmation",
                            redText: "Once confirmed, this cannot be undone.",
                            onConfirm: () => {
                                changeStatus("cancel");
                                setPropsForApprovalState();
                            },
                            onDecline: () => {
                                setPropsForApprovalState();
                            },
                        });
                    }}
                />
            )}

            {/* PRINT - EXPORT - TOP  */}
            <div className="printArea">
                <div
                    className="font_subLink"
                    onClick={() => {
                        window.print();
                    }}
                >
                    PRINT
                </div>
                <div> - </div>
                <div className="font_subLink">EXPORT</div>
                <div> - </div>
                <div
                    className="font_subLink"
                    onClick={() => {
                        handleGoToTop();
                    }}
                >
                    TOP
                </div>
            </div>
        </React.Fragment>
    );
};
