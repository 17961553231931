import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { Card } from "../Card/Card.js";
import "./CardsForListing.scss";

export const CardsForListing = (props) => {
    const { filteredSubmissions, clientTop, currentPage } = props;
    const { convertDateForScreen } = useContext(GlobalContext);

    /**
     * HANDLE GO TO TOP
     * @description Goes to top of the page smoothly.
     */
    const handleGoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };
    /**
     * HANDLE GO TO BOTTOM
     * @description Goes to bottom of the page smoothly.
     */
    const handleGoToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: "smooth",
        });
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    return (
        <div className="listing_cards">
            {!filteredSubmissions
                ? null
                : filteredSubmissions.map((item, key) => {
                      if (key > clientTop * currentPage - 1) {
                          return null;
                      }
                      return (
                          <React.Fragment key={key}>
                              {clientTop && key % clientTop === 0 ? (
                                  <div className="box_seperator">
                                      {filteredSubmissions.length <= currentPage * clientTop ? (
                                          <div>
                                              {" "}
                                              {filteredSubmissions.length}{" "}
                                              {filteredSubmissions.length > 1 ? "items" : "item"}
                                          </div>
                                      ) : (
                                          <div>
                                              Items {key === 0 ? 1 : key} -{" "}
                                              {key + clientTop > filteredSubmissions.length
                                                  ? filteredSubmissions.length
                                                  : key + clientTop}{" "}
                                              of {filteredSubmissions.length}
                                          </div>
                                      )}
                                      <div>
                                          {key === 0 ? null : (
                                              <React.Fragment>
                                                  <div
                                                      className="font_subLink"
                                                      style={{
                                                          display: "inline-block",
                                                      }}
                                                      onClick={() => {
                                                          handleGoToTop();
                                                      }}
                                                  >
                                                      Go Top
                                                  </div>{" "}
                                                  -{" "}
                                              </React.Fragment>
                                          )}
                                          {filteredSubmissions.length <=
                                          currentPage * clientTop ? null : (
                                              <div
                                                  className="font_subLink"
                                                  style={{
                                                      display: "inline-block",
                                                  }}
                                                  onClick={() => {
                                                      handleGoToBottom();
                                                  }}
                                              >
                                                  Go Bottom
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              ) : null}

                              <Card
                                  additionalStyle={{ marginRight: 0 }}
                                  id={item.id}                                  
                                  status={item.status}
                                  type={item.type}
                                  medName={item.title}
                                  dateOn={convertDateForScreen(item.modified)}
                                  dateBy={item.modifiedBy}
                                  nhsMsg={item.nhsMsg}
                                  submissionId={item.submissionId}
                              />
                          </React.Fragment>
                      );
                  })}
        </div>
    );
};
