import React from "react";

export const PackBox = (props) => {
    const { currentAll } = props;

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="form_ingredient">
            <div>
                <b>
                    {currentAll?.quantity?.currentValue?.input +
                        " " +
                        currentAll?.quantity?.currentValue?.unit?.label}
                </b>
            </div>
            <div>
                <b>Legal Category: </b>
                {currentAll?.legalCategory?.currentValue?.label}
            </div>
            <div>
                <b>Subpack Information: </b>
                {currentAll?.subpackInfo?.currentValue}
            </div>
            <div>
                <b>GTIN Codes: </b>
                {currentAll?.gtinCodes?.currentValue}
            </div>
            <div>
                <b>Calender Pack: </b>
                {currentAll?.calendarPack?.currentValue?.label}
            </div>
            <div>
                <b>Hospital Pack: </b>
                {currentAll?.hospitalPack?.currentValue?.label}
            </div>
            <div>
                <b>Limited Stability: </b>
                {currentAll?.limitedStability?.currentValue?.label}
            </div>
            <div>
                <b>Price (in pence): </b>
                {currentAll?.price?.currentValue}
            </div>
        </div>
    );
};
