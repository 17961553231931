import React, { useState, useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";

export const RadioButton = (props) => {
    const { data, keyName, updateData, additionalStyle, viewMode } = props;
    const {
        fieldName,
        currentValue,
        optionValue,
        informationText,
        errorText,
        validationRules,
        alwaysNonEditable,
    } = data;
    const { turnArrayToLines, isNotEmpty } = useContext(GlobalContext);
    const [firstFocus, setFirstFocus] = useState(false);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (viewMode === "view" || alwaysNonEditable) {
        return (
            <div className="view_single">
                <div>{fieldName}</div>
                <div>
                    <span>{currentValue ? currentValue.label : ""}</span>
                </div>
            </div>
        );
    }

    return (
        <div
            id={keyName}
            className={"form_radio " + (errorText?.length > 0 ? "error" : "")}
            style={additionalStyle}
            onMouseOver={() => {
                if (!firstFocus) {
                    setFirstFocus(true);
                }
            }}
        >
            {fieldName ? (
                <label
                    className={
                        "form_label" +
                        ((validationRules?.length > 0 && !isNotEmpty(currentValue)) ||
                        errorText?.length > 0
                            ? " onLoadControlError"
                            : "")
                    }
                >
                    {fieldName}
                </label>
            ) : null}

            {optionValue.map((item, key) => {
                return (
                    <label className="form_radio_container" key={key}>
                        <input
                            type="radio"
                            name={keyName}
                            checked={currentValue?.value === item.value ? true : false}
                            onChange={() => {
                                updateData(keyName, {
                                    label: item.label,
                                    value: item.value,
                                });
                            }}
                        />
                        <div>{item.label}</div>
                        <span className="form_radio_checkmark"></span>
                    </label>
                );
            })}

            {informationText ? <div className="form_infoText">{informationText}</div> : null}
            {firstFocus && errorText?.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
        </div>
    );
};
