import * as React from "react";
import { useEffect } from "react";

export const FeatureFlagsStore = React.createContext({});

const FeatureFlagsProvider = ({ children }) => {
    const initDefaults = {
        enableNhsBsaEmailAddress: undefined
    }

    const errorDefaults = {
        enableNhsBsaEmailAddress: false
    }

    const [flags, setFlags] = React.useState(initDefaults);

    useEffect(() => {
        async function getFlags() {
            try {
                const url = `${process.env.REACT_APP_API_PATH}/features/management`;
                const headers = {
                    "Content-Type": "application/json",
                };

                const response = await fetch(url, { method: "GET", headers: headers });
                if (!response.ok) {
                    throw new Error();
                }

                setFlags(await response.json());
            } catch {
                setFlags(errorDefaults)
            }
        }

        getFlags();
    }, []);

    return <FeatureFlagsStore.Provider value={{ ...flags }}>{children}</FeatureFlagsStore.Provider>;
};
export default FeatureFlagsProvider;
