import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "./UserStore.js";

export const PrivateRoute = ({ component, ...rest }) => {
    const { isAuthenticated, login } = useContext(UserContext);

    if (isAuthenticated() && !!component) {
        return <Route {...rest} component={component} />;
    } else {
        login();
    }

    return null;
};
