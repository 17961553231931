import React, { useState, useContext, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "contexts/UserStore.js";
import { RadioButton } from "./RadioButton.js";
import { Button } from "../FormElements/Button/Button.js";
import { Link } from "react-router-dom";

export const UserSettings = (props) => {
    const { userState, changeSelectedCompany } = useContext(UserContext);
    const [allRadios, setAllRadios] = useState(
        userState.companyPermissions.map((item) => {
            return {
                ...item,
                checked: userState.selectedCompany?.id === item.id ? true : false,
            };
        }),
    );
    const [selectedOption, setSelectedOption] = useState(userState.selectedCompany);
    let history = useHistory();

    /**
     * CHANGE COMPANY SELECTION
     */
    const changeSelection = (selectedCompany) => {
        setAllRadios((prev) => ({
            ...prev,
            [selectedOption]: {
                name: allRadios[selectedOption].name,
                checked: false,
            },
            [selectedCompany]: {
                name: allRadios[selectedCompany].name,
                checked: true,
            },
        }));
    };
    useLayoutEffect(() => {
        let newSelectedOption = 0;
        if (allRadios) {
            Object.entries(allRadios).map(([key, item]) => {
                if (item.checked) {
                    newSelectedOption = key;
                }

                return null;
            });

            setSelectedOption(newSelectedOption);
        }
    }, [allRadios]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="sidebarContent userSettingsContent" style={{ whiteSpace: "normal" }}>
            {/* HEADER */}
            <div className="sidebarContent_header">User Settings</div>
            <div className="sidebarContent_line"></div>

            {/* CURRENT ACCOUNT */}
            <div className="sidebarContent_links" style={{ minWidth: 275 }}>
                <div className="sidebarContent_title">CURRENT ACCOUNT:</div>
                {userState.userName}
                <img className="sidebarContent_icons" src={require("img/user.svg")} alt="" />
                {userState.selectedCompany?.name}
                <img className="sidebarContent_icons" src={require("img/company.svg")} alt="" />
                <Link className="font_subLink" style={{ color: "white" }} to="/logout">
                    Log Out
                </Link>
            </div>
            {/* CURRENT ROLE */}
            {userState.currentPermLevel === -1 ? null : (
                <React.Fragment>
                    <div className="sidebarContent_line"></div>
                    <div
                        className="sidebarContent_links"
                        style={{ minWidth: 275, marginBottom: 15 }}
                    >
                        <div className="sidebarContent_title">PERMISSIONS:</div>
                        {userState.currentPermLevel === 0
                            ? "Indemand User"
                            : userState.currentPermLevel === 1
                            ? "Collate and track submissions (L1)"
                            : userState.currentPermLevel === 2
                            ? "Collate, track and authorise submissions (L2)"
                            : null}
                    </div>
                </React.Fragment>
            )}
            {/* SWITCH COMPANY */}

            {!allRadios || allRadios.length < 2 ? null : (
                <React.Fragment>
                    <div className="sidebarContent_line"></div>
                    <div className="sidebarContent_links" style={{ minWidth: 275 }}>
                        <div className="sidebarContent_title">SWITCH COMPANY:</div>

                        {!allRadios
                            ? null
                            : Object.entries(allRadios).map(([key, item]) => {
                                  return (
                                      <RadioButton
                                          inputType="radio"
                                          key={key}
                                          text={item.name}
                                          checked={item.checked}
                                          onChange={() => {
                                              changeSelection(key);
                                          }}
                                      />
                                  );
                              })}

                        <Button
                            buttonType="white"
                            text="CHANGE"
                            onClick={() => {
                                changeSelectedCompany(selectedOption);
                                history.push("/");
                            }}
                            additionalStyle={{ marginRight: 0 }}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
