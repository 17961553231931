import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { BackEmblem } from "../BackEmblem/BackEmblem.js";
import "./IsLoading.scss";

export const IsLoading = (props) => {
    //-------------------------------------------------------------
    // 		VARIABLES
    //-------------------------------------------------------------
    const { isLoading } = useContext(GlobalContext);
    const { showMeAlways, internalBoo } = props;

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (internalBoo === true || internalBoo === false) {
        return (
            <div className={"loading_zone" + (!internalBoo ? " hide" : "")}>
                <BackEmblem svgClass="onLoading" />
                <img src={require("img/loading.svg")} alt="" />
                emc med data is working on your request.
            </div>
        );
    } else {
        return (
            <div className={"loading_zone" + (!isLoading || showMeAlways ? " hide" : "")}>
                <BackEmblem svgClass="onLoading" />
                <img src={require("img/loading.svg")} alt="" />
                emc med data is working on your request.
            </div>
        );
    }
};
