import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore";

export const NavButtonContact = (props) => {
    const { id, icon, onClick } = props;
    const { sidebarBehavior } = useContext(GlobalContext);

    return (
        <div
            id={id}
            className={
                "sidebar_navButton " + sidebarBehavior.position + " " + sidebarBehavior.content
            }
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            onKeyPress={(e) => {
                if ((e.key = "Enter")) {
                    onClick();
                }
            }}
        >
            <div className="sidebar_navButton_wideRect">
                <span>Contact</span>
                <img src={require(`img/dataPharmLogo.png`)} alt="" />
            </div>
            <div className="sidebar_navButton_narrowRect">
                <img src={require(`img/${icon}`)} alt="" />
            </div>
        </div>
    );
};
