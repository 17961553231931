import React from "react";
import { Link } from "react-router-dom";

export const CreateNew = (props) => {
    return (
        <div className="sidebarContent createNewContent">
            <div className="sidebarContent_header">
                {" "}
                Create New
                <br />
                Submission{" "}
            </div>
            <div className="sidebarContent_line"></div>
            <div className="sidebarContent_links">
                <Link to="/new/NewProduct">New Product</Link>
                <Link to="/new/NewPack">New Pack</Link>
                <Link to="/new/UpdateProduct">Update Product</Link>
                <Link to="/new/UpdatePack">Update Pack</Link>
                <Link to="/new/MassPriceUpdate">Mass Price Update</Link>
            </div>
        </div>
    );
};
