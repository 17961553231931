import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";

import "./SummarySection.scss";

export const SummarySection = (props) => {
    const { savedData } = props;
    const { convertDateForScreen } = useContext(GlobalContext);

    const findClassName = (status) => {
        if (status === "Draft") {
            return "draft";
        }
        if (status === "ReadyForAuthorisation") {
            return "ready";
        }
        if (status === "PendingSubmission" || status === "PendingCancellation") {
            return "pending";
        }
        if (status === "WithNhsBsa") {
            return "withNHS";
        }
        if (status === "Confirmed") {
            return "confirmed";
        }
        if (status === "Rejected") {
            return "rejected";
        }
        if (status === "Cancelled") {
            return "canceled";
        }
    };

    const newClass = findClassName(savedData.status);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    return (
        <div className="view_summary">
            <div className="view_single">
                <div>Status</div>
                <div>
                    <span className={"view_summaryColorful " + newClass}>
                        <svg
                            className="view_arrow"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 6 10"
                            space="preserve"
                        >
                            <path d="M4.1,5L0.5,8.6c-0.3,0.3-0.3,0.7,0,1s0.7,0.3,1,0c0,0,0,0,0,0l4.1-4.1 c0.3-0.3,0.3-0.7,0-0.9L1.4,0.4c-0.3-0.3-0.7-0.3-1,0s-0.3,0.7,0,1c0,0,0,0,0,0L4.1,5z" />
                        </svg>{" "}
                        {savedData.status}
                    </span>
                </div>
            </div>

            <div className="view_status">
                <div className="view_single">
                    <div>ID</div>
                    <div>
                        <span>{savedData.submissionId > 0 ? savedData.submissionId : 'Not yet assigned'}</span>
                    </div>
                </div>
            </div>
            <div className="view_status">
                <div className="view_single">
                    <div>Type</div>
                    <div>
                        <span>{savedData.type}</span>
                    </div>
                </div>
            </div>
            <div className="view_name">
                <div className="view_single">
                    <div>Name</div>
                    <div>
                        <span>{savedData.title}</span>
                    </div>
                </div>
            </div>
            <div className="view_created">
                <div className="view_single">
                    <div>Last Updated</div>
                    <div>
                        <span>
                            by {savedData.modifiedBy} on {convertDateForScreen(savedData.modified)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
