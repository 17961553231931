import React, { useLayoutEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { FetchAPI } from "components/APIConnections/APIConnections.js";
import { GlobalContext } from "contexts/GlobalStore.js";
import { UserContext } from "contexts/UserStore.js";
import { ClientContext } from "contexts/ClientStore.js";
import { CardsForListing } from "components/CardsForListing/CardsForListing.js";
import { Button } from "components/FormElements/Button/Button.js";
import "./List.scss";

export const List = (props) => {
    const { location } = props;
    const {
        pageName,
        setPageName,
        setSidebarBehavior,
        disableIsLoadingWithDelay,
        setIsLoading,
        setHeaderTitles,
        showErrorLogsOnConsole,
    } = useContext(GlobalContext);
    const { userState } = useContext(UserContext);
    const { CS } = useContext(ClientContext);
    const thisPageName = "listingPage";
    let history = useHistory();

    const [statusArray, setStatusArray] = useState();
    const [typeArray, setTypeArray] = useState();
    const [searchValue, setSearchValue] = useState();
    const [allSubmissions, setAllSubmissions] = useState();
    const [filteredSubmissions, setFilteredSubmissions] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [clientTop, setClientTop] = useState();
    const [isLoadMore, setIsLoadMore] = useState();

    const boxLineNumber = 4;

    /**
     * SET PAGE NAME and SIDEBAR BEHAVIOUR IN MOUNT AND REMOVE ISLOADING
     * @description sets pageName and sidebarBehaviour in globalStore
     */
    useLayoutEffect(() => {
        setPageName(thisPageName);
        setSidebarBehavior({ position: "narrow", content: "none" });
    }, [pageName]);

    /**
     * GET SEARCH PARAMATERS
     */
    useLayoutEffect(() => {
        let innerSearchParams = new URLSearchParams(location.search);
        setStatusArray(
            innerSearchParams.get("status") ? innerSearchParams.get("status").split(",") : [],
        );
        setTypeArray(innerSearchParams.get("type") ? innerSearchParams.get("type").split(",") : []);
        setSearchValue(innerSearchParams.get("search") || null);
    }, [location]);

    /**
     * UPDATE HEADER TITLE
     */
    useLayoutEffect(() => {
        if ((!allSubmissions, !filteredSubmissions)) {
            return;
        }

        let headerTitle = "";
        let greyTitle = "";

        if (allSubmissions.length === filteredSubmissions.length) {
            headerTitle = "All Submissions";
            greyTitle = "";
        } else if (statusArray && statusArray.length > 0) {
            headerTitle = "Status";
            if (statusArray.length < 3) {
                greyTitle = statusArray.join(", ");
            } else {
                greyTitle =
                    statusArray[0] +
                    ", " +
                    statusArray[1] +
                    " + " +
                    (statusArray.length - 2) +
                    " more status";
            }
        } else if (typeArray && typeArray.length > 0) {
            headerTitle = "Type";
            if (typeArray.length < 3) {
                greyTitle = typeArray.join(", ");
            } else {
                greyTitle =
                    typeArray[0] +
                    ", " +
                    typeArray[1] +
                    " + " +
                    (typeArray.length - 2) +
                    " more type";
            }
        } else if (searchValue && searchValue.length > 0) {
            headerTitle = "Search";
            greyTitle = searchValue;
        } else {
            headerTitle = "Submissions";
        }
        setHeaderTitles({
            submissionType: headerTitle,
            productName: greyTitle,
        });
    }, [statusArray, typeArray, searchValue, allSubmissions, filteredSubmissions]);

    /**
     * FETCH ALL SUBMISSIONS if allSubmissions are null
     */
    useLayoutEffect(() => {
        async function fetchData() {
            let newData = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: "callDataForHomePage",
                token: userState.accessToken,
                apiNeeds: {
                    companyId: userState.selectedCompany?.id,
                },
                setIsLoading: (boo) => {
                    setIsLoading(boo);
                },
            });

            setAllSubmissions(newData.data);
        }

        if (!allSubmissions) {
            fetchData();
        }
    }, [userState]);

    /**
     * FILTER <allSubmissions> DEPANDING ON status, type, search PARAMS.
     */
    useLayoutEffect(() => {
        setIsLoading(true);
        if (!allSubmissions) {
            return;
        }

        // modifiedBy
        // name
        // status
        // submissionId
        // type

        if (statusArray.length === 0 && typeArray.length === 0 && !searchValue) {
            setFilteredSubmissions(allSubmissions);
        } else {
            const newList = (allSubmissions || []).filter((option) => {
                return (
                    String(option.modifiedBy)
                        .toLowerCase()
                        .includes(String(searchValue).toLowerCase()) ||
                    String(option.name).toLowerCase().includes(String(searchValue).toLowerCase()) ||
                    String(option.submissionId).includes(String(searchValue).toLowerCase()) ||
                    statusArray.indexOf(option.status) > -1 ||
                    typeArray.indexOf(option.type) > -1
                );
            });
            setFilteredSubmissions(newList);
        }

        disableIsLoadingWithDelay();
    }, [allSubmissions, statusArray, typeArray, searchValue]);

    /**
     * DEFINE PAGE HOW MANY CARDS WILL BE SHOWN IN A PAGE
     * @description responsive value for clientTop
     */
    useLayoutEffect(() => {
        const calculateClientTop = () => {
            return CS.WINW > 1300
                ? 4 * boxLineNumber
                : CS.WINW > 1000
                ? 3 * boxLineNumber
                : CS.WINW > 700
                ? 2 * boxLineNumber
                : 1 * boxLineNumber;
        };
        setClientTop(calculateClientTop());
    }, [CS.WINW]);

    /**
     * HANDLE GO TO TOP
     * @description Goes to top of the page smoothly.
     */
    const handleGoToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    /**
     * HANDLE LOAD MORE BUTTON & SCROLL DOWN PAGE AUTOMATICLY
     * @description if user press Load More Button, this function will be triggered.
     */
    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };
    useLayoutEffect(() => {
        setIsLoadMore(false);
        window.scrollTo({
            top: window.pageYOffset + CS.WINH / 2,
            left: 0,
            behavior: "smooth",
        });
    }, [currentPage]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (!allSubmissions) {
        return null;
    } else if (!filteredSubmissions || (filteredSubmissions && filteredSubmissions.length === 0)) {
        return (
            <div style={{ padding: 20 }}>
                {/*  filteredContentBox will be here  */}
                Sorry, we couldn't find anything...
                <br />
                Please try a new search or change filter settings.
            </div>
        );
    }

    return (
        <React.Fragment>
            {/** RENDER CARDS */}
            <CardsForListing
                filteredSubmissions={filteredSubmissions}
                clientTop={clientTop}
                currentPage={currentPage}
            />

            {/** LOAD MORE */}
            {filteredSubmissions && filteredSubmissions.length > currentPage * clientTop ? (
                isLoadMore ? (
                    <div className="loadMoreButtonArea">Loading...</div>
                ) : (
                    <div className="buttomButtonsArea">
                        <div>{filteredSubmissions.length - currentPage * clientTop} more items</div>
                        <div className="loadMoreButtonArea">
                            <Button
                                additionalStyle={{ marginTop: 0 }}
                                buttonType=""
                                text="LOAD MORE"
                                onClick={() => {
                                    setIsLoadMore(true);
                                    handleLoadMore();
                                }}
                            />
                        </div>
                        <div
                            className="font_subLink"
                            onClick={() => {
                                handleGoToTop();
                            }}
                        >
                            Go Top
                        </div>
                    </div>
                )
            ) : null}
        </React.Fragment>
    );
};
