import React, { useState, useLayoutEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";

import { CheckBox } from "./CheckBox.js";
import { Button } from "../FormElements/Button/Button.js";

export const SelectStatus = (props) => {
    const { statusLib, setSidebarBehavior } = useContext(GlobalContext);
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState({
        Draft: { name: statusLib.DRA.scr, checked: true },
        ReadyForAuthorisation: { name: statusLib.RFA.scr, checked: false },
        PendingSubmission: { name: statusLib.PENSUB.scr, checked: false },
        WithNhsBsa: { name: statusLib.NHS.scr, checked: false },
        Confirmed: { name: statusLib.CON.scr, checked: false },
        Rejected: { name: statusLib.REJ.scr, checked: false },
        PendingCancellation: { name: statusLib.PENCAN.scr, checked: false },
        Cancelled: { name: statusLib.CAN.scr, checked: false },
    });
    const [selectedCount, setSelectedCount] = useState(0);
    let history = useHistory();

    /**
     * @function handleSelect -> converts all selection checked or unchecked depending on param
     * @description if param is true, all options will be checked, if false, all will be unchecked
     * @param {boolean}
     *
     * @function changeSelection -> convert selected option checked or unchecked.
     * @param {string} selectedOption
     */
    const handleSelect = (trueOrFalse) => {
        let checked = false;
        if (trueOrFalse) {
            checked = true;
        }

        let newArray = {};

        if (selectedCheckBoxes) {
            Object.entries(selectedCheckBoxes).map(([key, item]) => {
                newArray[key] = { name: item.name, checked: checked };
                return null;
            });

            setSelectedCheckBoxes(newArray);
        }
    };

    const changeSelection = (selectedOption) => {
        setSelectedCheckBoxes((prev) => ({
            ...prev,
            [selectedOption]: {
                name: selectedCheckBoxes[selectedOption].name,
                checked: !selectedCheckBoxes[selectedOption].checked,
            },
        }));
    };

    useLayoutEffect(() => {
        let newSelectedCount = 0;
        if (selectedCheckBoxes) {
            Object.entries(selectedCheckBoxes).map(([key, item]) => {
                if (item.checked) {
                    newSelectedCount++;
                }

                return null;
            });

            setSelectedCount(newSelectedCount);
        }
    }, [selectedCheckBoxes]);

    /**
     * @function filterStatus
     * @description collect all selected options and send to the page
     * @description NOT FINISHED YET.
     */
    const filterStatus = () => {
        let newArray = [];

        if (selectedCheckBoxes) {
            Object.entries(selectedCheckBoxes).map(([key, item]) => {
                if (item.checked) {
                    newArray.push(item.name);
                }
                return null;
            });

            history.push({
                pathname: "/list",
                search:
                    "?" + new URLSearchParams({ status: newArray }).toString(),
            });
            setSidebarBehavior({ position: "narrow", content: "none" });
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    return (
        <div className="sidebarContent selectStatusContent">
            <div className="sidebarContent_header">
                Select Status
                <div>
                    <span
                        onClick={() => {
                            handleSelect(true);
                        }}
                    >
                        Select All
                    </span>{" "}
                    -{" "}
                    <span
                        onClick={() => {
                            handleSelect(false);
                        }}
                    >
                        Deselect All
                    </span>
                </div>
            </div>
            <div className="sidebarContent_line"></div>
            <div className="sidebarContent_links">
                {!selectedCheckBoxes
                    ? null
                    : Object.entries(selectedCheckBoxes).map(([key, item]) => {
                          return (
                              <CheckBox
                                  key={key}
                                  text={item.name}
                                  checked={item.checked}
                                  onChange={() => {
                                      changeSelection(key);
                                  }}
                              />
                          );
                      })}

                <Button
                    buttonType={
                        selectedCount === 0 ? "white deactive" : "white"
                    }
                    text="VIEW"
                    onClick={() => {
                        filterStatus();
                    }}
                    additionalStyle={{ marginRight: 0 }}
                />
            </div>
        </div>
    );
};
