import React, { createContext, useState, useLayoutEffect } from "react";
import _ from "lodash";

export const ClientContext = createContext();
const ClientStore = (props) => {
    //-------------------------------------------------------------
    // 		FUNCTIONS
    //-------------------------------------------------------------

    /**
     * @function
     * @description defines Client Window width, Window Height and Device and Browser type.
     */

    const defineWINW = () => {
        return window.innerWidth || document.documentElement.clientWidth;
    };
    const defineWINH = () => {
        return window.innerHeight || document.documentElement.clientHeight;
    };
    const defineDEVICE = () => {
        let DEVICE =
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i) ||
            navigator.userAgent.match(/Opera Mini/i) ||
            navigator.userAgent.match(/IEMobile/i)
                ? true
                : false;
        return DEVICE;
    };
    let BROWSER = "UNKNOWN";
    let isBrowserGreen = true;
    const defineBROWSER = () => {
        if (
            (!!window.opr && !!window.opr.addons) ||
            !!window.opera ||
            navigator.userAgent.indexOf(" OPR/") >= 0
        ) {
            BROWSER = "OPERA";
        } else if (typeof InstallTrigger !== "undefined") {
            BROWSER = "FIREFOX";
        } else if (
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(
                !window["safari"] ||
                    (typeof safari !== "undefined" &&
                        window.safari.pushNotification),
            )
        ) {
            BROWSER = "SAFARI";
        } else if (/*@cc_on!@*/ false || !!document.documentMode) {
            if (!!window.StyleMedia) {
                BROWSER = "IE";
                isBrowserGreen = false;
            } else {
                BROWSER = "OLDEDGE";
                isBrowserGreen = false;
            }
        } else if (
            !!window.chrome &&
            (!!window.chrome.webstore || !!window.chrome.runtime)
        ) {
            if (navigator.userAgent.indexOf("Edg") !== -1) {
                BROWSER = "EDGE";
            } else {
                BROWSER = "CHROME";
            }
        }
    };
    defineBROWSER();

    /**
     * @var
     * @description defines CS
     */
    const [CS, setCS] = useState({
        WINW: defineWINW(),
        WINH: defineWINH(),
        isMOBILE: defineDEVICE(),
        BROWSER: BROWSER,
        isBROWSERGREEN: isBrowserGreen,
    });

    /**
     * @function
     * @description calls WINW and WINH again onResize
     */
    useLayoutEffect(() => {
        const resizeBlocker = _.debounce(() => {
            defineWindowProps();
        }, 250);

        const defineWindowProps = () => {
            setCS((prevCS) => ({
                ...prevCS,
                WINW: defineWINW(),
                WINH: defineWINH(),
            }));
        };

        window.addEventListener("resize", resizeBlocker);
    }, []);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    return (
        <ClientContext.Provider value={{ CS }}>
            {props.children}
        </ClientContext.Provider>
    );
};

export default ClientStore;
