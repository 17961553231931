import React, { useContext, useState, useCallback, useLayoutEffect } from "react";
import useEventListener from "@use-it/event-listener";
// import { useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";
import _ from "lodash";
import { HeaderActions } from "./HeaderActions.js";
import "./Header.scss";

export const Header = (props) => {
    const { pageName, headerTitles, globalAlert } = useContext(GlobalContext);
    const [onMouseOverFix, setOnMouseOverFix] = useState(false);
    // const [negativeActions, setNegativeActions] = useState(false);
    const [newClass, setNewClass] = useState();
    const [headerActionsScrollYPosition, setHeaderActionsScrollYPosition] = useState(0);
    // const inputZone = useRef();
    // let history = useHistory();

    /**
     * FIND CLASS NAME FOR HEADER TITLE
     */
    const findClassName = (status) => {
        if (pageName === "listingPage") {
            return "draft";
        } else {
            switch (status) {
                case "Draft":
                    return "draft";
                case "ReadyForAuthorisation":
                    return "ready";
                case "PendingSubmission":
                case "PendingCancellation":
                    return "pending";
                case "WithNhsBsa":
                    return "withNHS";
                case "Confirmed":
                    return "confirmed";
                case "Rejected":
                    return "rejected";
                case "Cancelled":
                    return "canceled";
                default:
                    return undefined;
            }
        }
    };
    useLayoutEffect(() => {
        setNewClass(findClassName(headerTitles.status));
    }, [headerTitles.status]);

    /**
     * ON MOUSE OVER FIX
     * @description after searching something, mouse is still over the header area. Because of that, header title becomes invisible.
     * @description with this delay fix, for the first loading of the page, I'm blocking onMouseOver effect.
     */
    useLayoutEffect(() => {
        setOnMouseOverFix(false);
    }, [pageName]);
    useLayoutEffect(() => {
        turnMouseOverTrue();
    }, [onMouseOverFix]);
    const turnMouseOverTrue = useCallback(
        _.debounce(() => {
            setOnMouseOverFix(true);
        }, 1000),
        [],
    );

    /**
     * HANDLE ON MOUSE OVER & OUT for RELATIVE DIV
     */
    const handleOnMouseOver = () => {
        // NOT IMPLEMENTED IN THIS VERSION
        // if (!onMouseOverFix) {
        //     return;
        // }
        // inputZone.current.value = "";
        // inputZone.current.focus();
        // setNegativeActions(true);
    };
    const handleOnMouseOut = () => {
        // NOT IMPLEMENTED IN THIS VERSION
        //     setNegativeActions(false);
        //     inputZone.current.blur();
    };
    // const handleSubmit = (e) => {
    //     handleOnMouseOut();
    //     setOnMouseOverFix(false);
    //     history.push({
    //         pathname: "/list",
    //         search:
    //             "?" +
    //             new URLSearchParams({
    //                 search: e.target.value.trim(),
    //             }).toString(),
    //     });
    // };

    /**
     * CALCULATE HEADER ACTIONS
     */
    useEventListener("scroll", () => {
        let scrollY =
            window.scrollY ||
            window.scrollTop ||
            document.getElementsByTagName("html")[0].scrollTop;
        if (scrollY < 50) {
            setHeaderActionsScrollYPosition(-1 * scrollY);
        } else {
            setHeaderActionsScrollYPosition(-50);
        }
    });

    //-------------------------------------------------------------
    // 		RETURN NULL FOR homePage and errorPage
    //-------------------------------------------------------------
    if (!pageName || pageName === "homePage" || pageName === "errorPage") {
        return null;
    }

    //-------------------------------------------------------------
    // 		RETURN FOR ABOUT PAGE
    //-------------------------------------------------------------
    else if (pageName === "aboutPage") {
        return (
            <React.Fragment>
                <div className="listing_header">
                    <div className="subpages_title">
                        <div>About & Legal</div>
                    </div>
                </div>
                <HeaderActions headerActionsScrollYPosition={headerActionsScrollYPosition} />
            </React.Fragment>
        );
    }

    //-------------------------------------------------------------
    // 		RETURN FOR HANDLE PAGE
    //-------------------------------------------------------------
    else if (pageName.split("/")[0] === "handlePage") {
        return (
            <React.Fragment>
                <div className={"globalAlert " + (globalAlert ? globalAlert.status : "off")}>
                    {globalAlert ? globalAlert.text : ""}
                </div>
                <div className="listing_header">
                    {!headerTitles.productName ? (
                        <div className="subpages_title">
                            <div>{headerTitles.submissionType}</div>
                        </div>
                    ) : (
                        <div
                            className={"subpages_title withLine" + (newClass ? " " + newClass : "")}
                        >
                            <div>{headerTitles.submissionType}</div>
                            <div>{headerTitles.productName}</div>
                        </div>
                    )}
                </div>
                <HeaderActions headerActionsScrollYPosition={headerActionsScrollYPosition} />
            </React.Fragment>
        );
    }

    //-------------------------------------------------------------
    // 		RETURN FOR LISTING PAGE
    //-------------------------------------------------------------
    else if (pageName === "listingPage") {
        return (
            <React.Fragment>
                <div className="listing_header">
                    {
                        <div
                            className={
                                "subpages_title" +
                                (!headerTitles.productName
                                    ? ""
                                    : " withLine" + (newClass ? " " + newClass : ""))
                            }
                            onMouseOver={() => {
                                handleOnMouseOver();
                            }}
                            onMouseOut={() => {
                                handleOnMouseOut();
                            }}
                        >
                            <div>{headerTitles.submissionType}</div>
                            <div>{headerTitles.productName}</div>
                            {/* <input
                                ref={inputZone}
                                className={
                                    "listingHeaderInput" + (negativeActions ? " negative" : "")
                                }
                                spellCheck="false"
                                autoCorrect="off"
                                autoCapitalize="off"
                                placeholder="Please type a value to search"
                                defaultValue=""
                                onKeyUp={(e) => {
                                    if (e.keyCode === 27) {
                                        handleOnMouseOut();
                                    }
                                    if (e.keyCode === 13) {
                                        handleSubmit(e);
                                    }
                                }}
                             
                            /> */}
                        </div>
                    }
                </div>
                <HeaderActions headerActionsScrollYPosition={headerActionsScrollYPosition} />
            </React.Fragment>
        );
    }
    //-------------------------------------------------------------
    // 		DEFAULT RETURN
    //-------------------------------------------------------------
    else {
        return null;
    }
};
