import React from "react";
import { useHistory } from "react-router-dom";

export const CardSeeAll = (props) => {
    const { howMuchMore, status } = props;
    let history = useHistory();

    return (
        <div
            className="card_seeAll"
            onClick={() => {
                history.push({
                    pathname: "/list",
                    search:
                        "?" +
                        new URLSearchParams({ status: status }).toString(),
                });
            }}
        >
            <div className="card_seeAll_header">
                <div className="card_seeAll_count">+{howMuchMore}</div>
                <div className="card_seeAll_text">more submissions</div>
            </div>
            <div className="font_subLink">See All</div>
        </div>
    );
};
