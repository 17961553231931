import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { useHistory } from "react-router-dom";
import "./MobileMenu.scss";

export const MobileMenu = (props) => {
    const { setSidebarBehavior, pageName } = useContext(GlobalContext);
    let history = useHistory();

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (pageName === "errorPage") {
        return null;
    }

    return (
        <div className="mobile_header">
            <img
                className="mobile_header_logo"
                src={require("img/logo.svg")}
                alt=""
                onClick={() => {
                    history.push("/");
                }}
            />
            <img
                className="mobile_header_hamburger"
                src={require("img/hamburgerIcon.svg")}
                alt=""
                onClick={(e) => {
                    setSidebarBehavior({ position: "mobile", content: "none" });
                }}
            />
        </div>
    );
};
