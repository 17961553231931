import React from "react";

import "./FreeForm.scss";

export const FreeForm = (props) => {
    const { savedData } = props;


    const getResponse = (e) => {
        const thisResponses = e.responses.map((name, index) =>
            // name.additionalInfo
            <li className="" key={index}>{name.reason} : {name.additionalInfo}</li>
        )
        return thisResponses
    };

    const newResponse = getResponse(savedData.data)
    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="view_summary">
           <ul>
           {newResponse}
           </ul>
       </div>
    );
};
