import React, { useLayoutEffect, useContext, useState } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { UserContext } from "contexts/UserStore.js";
import { Button } from "components/FormElements/Button/Button.js";
import "./LoggedOut.scss";

export const LoggedOut = (props) => {
    const {
        pageName,
        setPageName,
        setSidebarBehavior,
        setIsLoading,
        disableIsLoadingWithDelay,
    } = useContext(GlobalContext);
    const { login } = useContext(UserContext);
    const thisPageName = "logoutPage";

    /**
     * SET PAGE NAME and SIDEBAR BEHAVIOUR
     * @description sets pageName and sidebarBehaviour in globalStore
     */
    useLayoutEffect(() => {
        setIsLoading(true);
        setPageName(thisPageName);
        setSidebarBehavior({ position: "noSidebar", content: "hide" });
        disableIsLoadingWithDelay();
    }, [pageName]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="error_zone">
            <img
                className="error_logo"
                src={require("img/logo.svg") }
                alt=""
                onClick={() => {
                    login();
                }}
            />

            <div className="error_title">You have been logged out.</div>
            <div> Thank you for using emc med data dm+d. See you soon. </div>
            <div style={{ marginBottom: 30 }}></div>
            <Button
                buttonType=""
                text="LOGIN"
                onClick={() => {
                    login();
                }}
                additionalStyle={{}}
            />
        </div>
    );
};
