// https://www.newline.co/@andreeamaco/how-to-build-a-react-drag-and-drop-component-for-file-upload--bf8e5017
import React, { useRef, useState } from "react";
import { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";

export const FileInput = (props) => {
    const { data, keyName, updateData, additionalStyle, viewMode } = props;
    const { fieldName, currentValue, informationText, errorText } = data;
    const { turnArrayToLines, isNotEmpty } = useContext(GlobalContext);

    const [firstFocus, setFirstFocus] = useState(false);

    const draggableZone = useRef();
    const input = useRef();

    /**
     * FILE INPUT CHANGED
     */
    const fileInputChanged = (e) => {
        let files = [];
        if (e.target.files) {
            files = e.target.files;
            updateData(keyName, {
                label: files[0].name,
                value: files[0].name,
                fileProps: files,
            });
        }
    };

    /**
     * REMOVE FILE
     */
    const fileRemove = () => {
        input.current.value = null;
        updateData(keyName, null);
    };

    /**
     * DROP EVENT
     */
    const dropEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let files = [];
        if (e.dataTransfer.files) {
            files = e.dataTransfer.files;
            updateData(keyName, {
                label: files[0].name,
                value: files[0].name,
                fileProps: files,
            });
        }
    };

    /**
     * ALLOW DROP
     */
    const allowDrop = (e) => {
        e.preventDefault();
    };
    /**
     * SHORTEN FILE NAME
     */
    const shortenFileName = (name) => {
        if (name.length > 24) {
            let file = name.split(".");
            let fileName = file[0];
            let fileType = file[file.length - 1];
            let newFileName =
                fileName.substring(0, 10) +
                " (...) " +
                fileName.substring(fileName.length - 10, fileName.length) +
                "." +
                fileType;

            return newFileName;
        } else {
            return name;
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (viewMode === "view") {
        return (
            <div className="view_single">
                <div>{fieldName}</div>
                <div>
                    {currentValue?.fileUrl ? (
                        <a
                            href={"https://" + currentValue.fileUrl}
                            className="font_subLink"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textTransform: "none" }}
                        >
                            {currentValue.label}
                        </a>
                    ) : null}
                </div>
            </div>
        );
    }
    return (
        <div
            id={keyName}
            className={"form_fileSelect " + (errorText?.length > 0 ? "error" : "")}
            style={additionalStyle}
            onMouseOver={() => {
                if (!firstFocus) {
                    setFirstFocus(true);
                }
            }}
        >
            {fieldName ? (
                <label
                    className={"form_label" + (errorText?.length > 0 ? " onLoadControlError" : "")}
                >
                    {fieldName}
                </label>
            ) : null}

            <input
                type="file"
                multiple="multiple"
                ref={input}
                onChange={(e) => {
                    fileInputChanged(e);
                }}
            />
            <label
                ref={draggableZone}
                htmlFor={input}
                className="form_fileSelect_overText"
                onDragEnter={() => {
                    draggableZone.current.classList.toggle("dragenter");
                }}
                onDragLeave={() => {
                    draggableZone.current.classList.toggle("dragenter");
                }}
                onMouseOut={() => {
                    draggableZone.current.classList.remove("dragenter");
                }}
                onDrop={(e) => {
                    dropEvent(e);
                }}
                onDragOver={(e) => {
                    allowDrop(e);
                }}
            >
                {!isNotEmpty(currentValue) ? (
                    <div>
                        Drop your file here or&nbsp;
                        <span
                            className="font_subLink"
                            onClick={() => {
                                input.current.click();
                            }}
                        >
                            Browse
                        </span>
                    </div>
                ) : (
                    <div>
                        {shortenFileName(currentValue?.label || "")}
                        &nbsp;
                        <span
                            className="font_subLink"
                            onClick={() => {
                                fileRemove();
                            }}
                        >
                            Remove
                        </span>
                    </div>
                )}
            </label>

            {firstFocus && informationText ? (
                <div className="form_infoText">{informationText}</div>
            ) : null}
            {firstFocus && errorText?.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
        </div>
    );
};
