import "isomorphic-fetch";

export const FetchAPI = async (props) => {
    const {
        apiShortName,
        setIsLoading,
        apiNeeds,
        history,
        token,
        callbackWithErrorStatusOrConfirmation,
        showErrorLogsOnConsole = false,
    } = props;

    let apiURL = null;
    let apiData = null;
    let apiTask = null;
    let apiMethod = "GET";
    let apiHeaders = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
    };
    let removeStringfy = false;

    if (setIsLoading) {
        setIsLoading(true);
    }

    //-------------------------------------------------------------
    // 		API URLs
    //-------------------------------------------------------------
    switch (apiShortName) {
        case "callADummySelectBox":
            apiURL = "/bulkJSON/dynamicSelectBoxDummy.json";
            break;

        case "callIngredients":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/ingredients?search=${apiNeeds.value.toLowerCase()}`;
            break;

        case "callIngredients_idSearch":
            apiURL = `${process.env.REACT_APP_API_PATH}/ingredient/${apiNeeds.value}`;
            break;

        case "callVMP":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/products/virtual?search=${apiNeeds.value.toLowerCase()}`;
            break;

        case "callVMP_idSearch":
            apiURL = `${process.env.REACT_APP_API_PATH}/product/virtual/${apiNeeds.value}`;
            break;

        case "callAMP":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/products?search=${apiNeeds.value.toLowerCase()}&companyId=${apiNeeds.companyId}`;
            break;

        case "callAMP_idSearch":
            apiURL = `${process.env.REACT_APP_API_PATH}/product/${apiNeeds.value}`;
            break;

        case "callAMPP":
            apiURL = `${
                process.env.REACT_APP_API_PATH
                // }/packs?search=${apiNeeds.value.toLowerCase()}`;
            }/packs?companyId=${apiNeeds.companyId}&actualProductId=${apiNeeds.ampId}`;
            break;

        case "callAMPP_idSearch":
            apiURL = `${
                process.env.REACT_APP_API_PATH
                // }/packs?search=${apiNeeds.value.toLowerCase()}`;
            }/pack/${apiNeeds.value}`;
            break;

        case "callDataForHomePage":
            apiURL = `${process.env.REACT_APP_API_PATH}/submissions?companyId=${apiNeeds.companyId}&PageSize=800`;
            // apiURL = "/bulkJSON/homePageDataDummy.json";
            break;

        case "postNewSubmission":
            apiURL = `${process.env.REACT_APP_API_PATH}/submission`;
            apiMethod = "POST";
            apiData = {
                ...apiNeeds,
            };
            break;

        case "updateASubmission":
            apiURL = `${process.env.REACT_APP_API_PATH}/submission`;
            apiMethod = "PUT";
            apiHeaders = {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json-patch+json",
            };
            apiData = {
                ...apiNeeds,
            };
            break;

        case "callUserPermissions":
            apiURL = `${process.env.REACT_APP_API_PATH}/user`;
            break;

        case "callASubmission":
            console.warn('GET apiNeeds = ', apiNeeds);
            if (apiNeeds.submissionID)
                apiURL = `${process.env.REACT_APP_API_PATH}/submission?sid=${apiNeeds.submissionID}&cid=${apiNeeds.companyId}`;
            else 
                apiURL = `${process.env.REACT_APP_API_PATH}/submission/${apiNeeds.ID}?cid=${apiNeeds.companyId}`;    
            break;

        case "markAsCancelled":
            console.warn('CANCEL apiNeeds = ', apiNeeds);
            if (apiNeeds.submissionID)            
                apiURL = `${process.env.REACT_APP_API_PATH}/submission/cancel?sid=${apiNeeds.submissionID}&cid=${apiNeeds.companyId}`;
            else 
                apiURL = `${process.env.REACT_APP_API_PATH}/submission/${apiNeeds.ID}/cancel?cid=${apiNeeds.companyId}`;    
            break;

        case "callMassPriceUpdate":
            apiURL = `${process.env.REACT_APP_API_PATH}/prices?companyId=${apiNeeds.companyId}&pageSize=99999`;
            break;

        case "fileUpload":
            apiURL = `${process.env.REACT_APP_API_PATH}/smpc`;
            apiMethod = "POST";
            apiHeaders = {
                Authorization: "Bearer " + token,
            };
            removeStringfy = true;
            const formData = new FormData();
            formData.append("files", apiNeeds.fileProps);
            apiData = formData;
            break;
        
        case "callFormulation":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/frm`;
            break;

        case "callLicensedRoutes":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/rt`;
            break;

        case "callFlavours":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/f`;
            break;

        case "callControlledDrugCategories":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/cdc`;
            break;

        case "callCurrentLicensing":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/la`;
            break;

        case "callRestrictions":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/ar`;
            break;

        case "callBops":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/bos`;
            break;

        case "callUnits":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/uom`;
            break;

        case "callLegalCategory":
            apiURL = `${
                process.env.REACT_APP_API_PATH
            }/form-lookups/c`;
            break;

        default:
            apiURL = "";
    }

    //-------------------------------------------------------------
    // 	FETCH
    //-------------------------------------------------------------
    let newBody;
    if (removeStringfy) {
        newBody = apiData;
    } else {
        newBody = JSON.stringify(apiData ?? {});
    }

    if (!process.env.NODE_ENV && process.env.NODE_ENV === "development" && showErrorLogsOnConsole) {
        console.warn(
            "apiCall> ",
            apiURL,
            apiMethod === "POST" || apiMethod === "PUT" ? newBody : "",
        );
    }

    apiTask = fetch(apiURL, {
        // mode: "no-cors",
        method: apiMethod,
        headers: apiHeaders,
        ...((apiMethod === "POST" || apiMethod === "PUT") && {
            body: newBody,
        }),
    });

    let dataObject;
    let errorMessage;
    let errorObject;

    await apiTask
        .then((response) => {
            if (!response.ok) {
                throw response;
            } else {
                return response.json();
            }
        })

        //-------------------------------------------------------------
        // 		HANDLE DATA
        //-------------------------------------------------------------

        .then((data) => {
            if (!data.id && data.status?.length > 0) {
                errorMessage = "JSONIsEmpty";
                errorObject = data;
            }
            dataObject = data;
        })

        //-------------------------------------------------------------
        // 		CATCH ERROR
        //-------------------------------------------------------------

        .catch((response) => {
            dataObject = undefined;
            errorMessage = response.status;
            errorObject = response;
        });

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (!process.env.NODE_ENV && process.env.NODE_ENV === "development" && showErrorLogsOnConsole) {
        console.info(
            "apiResponse> ",
            dataObject,
            errorMessage || "",
            errorObject !== dataObject ? errorObject || "" : "",
        );
    }

    if (callbackWithErrorStatusOrConfirmation && errorObject) {
        return errorObject;
    } else if (callbackWithErrorStatusOrConfirmation && !errorObject) {
        return dataObject;
    } else if (errorMessage) {
        if (setIsLoading) {
            setIsLoading(false);
        }
        history.push({ pathname: "/error/" + errorMessage });
        return undefined;
        // setTimeout(() => {
        //     if (setIsLoading) {
        //         setIsLoading(false);
        //     }
        //     history.replace({ pathname: "/error/" + errorMessage });
        // }, 250);
    } else {
        return dataObject;
    }
};
