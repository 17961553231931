import React, { useState, useCallback, useRef, useLayoutEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";
import { UserContext } from "contexts/UserStore.js";
import { FetchAPI } from "../../APIConnections/APIConnections.js";
import _ from "lodash";

export const SelectBox = (props) => {
    const {
        data,
        keyName,
        updateData,
        silentUpdateData,
        additionalStyle,
        setIsFocused2,
        viewMode,
        onChange
    } = props;
    const {
        placeHolder,
        fieldName,
        currentValue,
        optionValue,
        apiNameForOptionValue,
        informationText,
        handleData,
        errorText,
        validationRules,
        alwaysNonEditable,
    } = data;
    const { turnArrayToLines, isNotEmpty, showErrorLogsOnConsole } = useContext(GlobalContext);
    const { userState } = useContext(UserContext);
    const [isFocused, setIsFocused] = useState(false);
    const [firstFocus, setFirstFocus] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [localInputValue, setLocalInputValue] = useState("");
    const [localOptionBuffer, setLocalOptionBuffer] = useState();
    const inputZone = useRef();
    let history = useHistory();

    /**
     * CALL NEW OPTINS VIA API
     * @description call new options for dynamic selectBox
     * @description works with lodash
     */
    const callNewOptionsViaAPI = useCallback(
        _.debounce(async (value) => {
            let newData = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: apiNameForOptionValue,
                token: userState.accessToken,
                apiNeeds: {
                    value: value,
                    companyId: userState.selectedCompany?.id,
                },
            });

            if (handleData) {
                newData = handleData({ DATA: newData });
            }

            setLocalOptionBuffer(newData);
            setLocalLoading(false);
        }, 500),
        [],
    );
    useLayoutEffect(() => {
        if (!localOptionBuffer) {
            return;
        }

        silentUpdateData(keyName, localOptionBuffer, "optionValue");
        setLocalOptionBuffer();
    }, [localOptionBuffer]);

    /**
     * PREPARE FILTERED OPTIONS
     * @description filtered "options" before mapping it.
     */
    const filteredOptions = (optionValue || [])
        .filter(
            (option) =>
                option.label
                    .toLowerCase()
                    .includes(localInputValue ? String(localInputValue).toLowerCase() : "") ||
                !localInputValue,
        )
        .map((item, key) => {
            return (
                <div
                    key={key}
                    onMouseDown={() => {
                        if(onChange) onChange({ label: item.label, value: item.value });
                        updateData(keyName, {
                            label: item.label,
                            value: item.value,
                        });
                    }}
                >
                    {item.label}
                </div>
            );
        });
        
    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (data.dontSendToApi && viewMode === "view") {
        return null;
    } else if (viewMode === "view" || alwaysNonEditable) {
        return (
            <div className="view_single">
                <div>{fieldName}</div>
                <div>
                <span>{fieldName === "Restrictions on Availability" && currentValue?.value === "0008"
                    ? currentValue.label ="Hospital Only"
                    : currentValue
                    ? currentValue.label 
                    : ""
                    }
                </span>
                </div>
            </div>
        );
    }
    return (
        <div
            id={keyName}
            className={"form_selectBox " + (errorText?.length > 0 ? "error" : "")}
            style={additionalStyle}
        >
            {fieldName ? (
                <label
                    className={
                        "form_label" +
                        ((validationRules?.length > 0 && !isNotEmpty(currentValue)) ||
                        errorText?.length > 0
                            ? " onLoadControlError"
                            : "")
                    }
                >
                    {fieldName}
                </label>
            ) : null}
            {
                <React.Fragment>
                    <input
                        ref={inputZone}
                        spellCheck="false"
                        autoCorrect="off"
                        autoCapitalize="off"
                        placeholder={placeHolder}
                        value={
                            isFocused
                                ? localInputValue
                                : currentValue?.label
                                ? currentValue.label
                                : ""
                        }
                        onBlur={() => {
                            setIsFocused(false);
                            if (setIsFocused2) {
                                setIsFocused2(false);
                            }
                        }}
                        onFocus={() => {
                            setIsFocused(true);
                            if (!firstFocus) {
                                setFirstFocus(true);
                            }
                            if (setIsFocused2) {
                                setIsFocused2(true);
                            }
                            setLocalInputValue("");
                            if (apiNameForOptionValue) {
                                setLocalLoading(true);
                                callNewOptionsViaAPI("");
                            }
                        }}
                        onChange={(e) => {
                            let val = e.target.value;
                            setLocalInputValue(val);
                            if (apiNameForOptionValue) {
                                setLocalLoading(true);
                                callNewOptionsViaAPI(val);
                            }
                        }}
                    />
                    <img className="form_downArrow" src={require("img/downArrow.svg")} alt="" />
                    <div
                        className="form_selectBoxContent"
                    >
                        {localLoading ? (
                            <span>Loading...</span>
                        ) :
                        filteredOptions.length > 0 ? (
                            filteredOptions
                        ) : (
                            <span>No results found.</span>
                        )}
                    </div>
                    {informationText ? (
                        <div className="form_infoText">{informationText}</div>
                    ) : null}
                    {firstFocus && errorText?.length > 0 ? (
                        <div className="form_errorText">{turnArrayToLines(errorText)}</div>
                    ) : null}
                </React.Fragment>
            }
        </div>
    );
};
