import React, { useContext} from "react";

import { DataGridEdit } from "./parts/DataGridEdit";

import { GlobalContext } from "contexts/GlobalStore.js";
import "./DataGrid.scss";

export const DataGrid = (props) => {
    const {
        data,
        keyName,
        // dispatch,
        // additionalStyle,
        // setIsFocused2,         // date input - sub fields
        // viewMode
        updateData,
    } = props;
    const {
        // apiValue, // data to go back to server
        // placeHolder,
        // fieldName,              // label name
        currentValue, // {label, value, UOM}
        // savedValue,             // data from api
        // optionValue,            // [{value,label}] for selections
        // apiNameForOptionValue,  // call to retrieve
        // informationText,        // tooltip
        // handleData,             // when not value/label eg ingredients converts to this structure
        errorText,
        // validationRules,
        // alwaysNonEditable,      // view mode not edit mode
        // uniqueDispatch,
        isReadOnly = false,
    } = data;
    const prices = currentValue || [];

    const { turnArrayToLines } = useContext(GlobalContext);

    if (!prices) {
        return <div>Loading...</div>;
    }

    const handleUpdatePrice = (id, value) => {
        const newPrices = [...prices].map((item) => {
            if (item.actualPackId === id) {
                // if it is an empty string, ensure that the value would not be converted to zero
                return { ...item, newPrice: value === "" ? undefined : +value };
            }
            return item;
        });

        updateData(keyName, newPrices);
    };

    const tableRows = prices.map((price) => {
        const { actualPackId: id, newPrice: value } = price;

        return (
            <tr key={price.actualPackId}>
                <td>{price.actualPackId}</td>
                <td>{price.packName}</td>
                <td>{price.price}</td>
                <td>
                    {isReadOnly === false ? (
                        <DataGridEdit id={id} value={value} onChange={handleUpdatePrice} />
                    ) : (
                        <div>{value}</div>
                    )}
                </td>
            </tr>
        );
    });
    return (
        <div className={"DataGrid "+ (errorText && errorText.length > 0 ? "error" : "")}>
                <table>
                    <thead>
                        <tr>
                            <th>AMPP ID</th>
                            <th>Pack Name</th>
                            <th>Current Price (Pence)</th>
                            <th>New Price (Pence)</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
                { errorText ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
                ) : null}
            </div> 
    );
};
