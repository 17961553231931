import React, { createContext, useState, useLayoutEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Oidc from "oidc-client";
import { FetchAPI } from "components/APIConnections/APIConnections.js";
import { IsLoading } from "components/IsLoading/IsLoading.js";
import { GlobalContext } from "./GlobalStore.js";
import Cookies from "universal-cookie";

export const UserContext = createContext({});

export const UserStore = ({ children }) => {
    const { setSidebarBehavior, showErrorLogsOnConsole } = useContext(GlobalContext);
    const oidcConfig = {
        authority: process.env.REACT_APP_AUTH_URL,
        client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_REDIRECT_URL,
        response_type: "code",
        scope: "openid profile datapharm.indemand.api",

        automaticSilentRenew: false,
        silentRequestTimeout: 20 * 1000,
        silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
        // silent_redirect_uri: "http://localhost:4200/silentRenew", // << if this is different from the original REACT_APP_REDIRECT_URL ident throws an error

        // monitorSession: true,
        // accessTokenExpiringNotificationTime: 20, //default 60
        // checkSessionInterval: 5000, //default 2000
        // silentRequestTimeout: 20000, //default: 10000

        revokeAccessTokenOnSignout: true,

        metadata: {
            issuer: process.env.REACT_APP_AUTH_URL,
            jwks_uri: process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
            authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/authorize",
            token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
            userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo",
            end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/endsession",
            check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
            revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/revocation",
            introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect",
        },
    };
    const alreadyVisitedHubCookie = "alreadyVisitedHub";
    let history = useHistory();
    const cookies = new Cookies();
    const [oidcMgr, setOidcMgr] = useState();

    const [processing, setProcessing] = useState(true);
    const [user, setUser] = useState();
    const [userState, setUserState] = useState();
    const location = useLocation();

    /**
     * CREATE INDEMAND OR PUBLISHER HUB OIDCMGR
     */
    useLayoutEffect(() => {
        setupAuthentication();
    }, []);

    const setupAuthentication = () => {
        setupMeddataAuthentication();
        const newOidcMgr = new Oidc.UserManager(oidcConfig);
        setOidcMgr(newOidcMgr);
    }

    const setupMeddataAuthentication = () => {
        if (!location.search) {
            const cookie = getHubVisitCookie();
            if (!cookie) {
                setHubOIDCSettings();
            }
        }
        else if (location.search?.includes(process.env.REACT_APP_MEDDATA_REDIRECT_QUERY_PARAM)) {
            setHubVisitCookie();
        }
        else if (!location.search?.includes("code")) {
            setHubOIDCSettings();
        }
    }

    const getHubVisitCookie = () => cookies.get(alreadyVisitedHubCookie);
    const setHubVisitCookie = () => cookies.set(alreadyVisitedHubCookie, null, { expires: 0 });

    const setHubOIDCSettings = () => {
        oidcConfig.client_id = process.env.REACT_APP_MEDDATA_IDENTITY_CLIENT_ID;
        oidcConfig.redirect_uri = process.env.REACT_APP_MEDDATA_REDIRECT_URL;
        oidcConfig.silent_redirect_uri = process.env.REACT_APP_MEDDATA_SILENT_REDIRECT_URL;
    }

    /**
     * DEFINE USER MANAGER and GET TOKENS
     */
    useLayoutEffect(() => {
        if (!oidcMgr) {
            return;
        }

        if (history.location.pathname === "/callback") {
            signinRedirectCallback();
        } else {
            oidcMgr.getUser().then((user) => {
                handleUser(user);
            });
        }

        oidcMgr.events.addSilentRenewError((e) => {
            alert("silent renew error", e.message);
            logout();
        });

        oidcMgr.events.addAccessTokenExpired(() => {
            tokenExpired();
        });
    }, [oidcMgr]);

    /**
     * USER MANAGER FUNCTIONS
     */
    const handleUser = async (user) => {
        if (!!!user) {
            setProcessing(false);
            return;
        }
        if (showErrorLogsOnConsole) {
            console.info(user);
        }
        setUser(user);

        let accessToken = user.access_token;
        let userId = user.profile.user_id;
        let userName = user.profile.user_name;
        let userEmail = user.profile.email;

        if (!!!accessToken || !!!userId || !!!userName) {
            history.push("/error/noAccessToken");
            setProcessing(false);
            return;
        }

        // LOAD PERMISSIONS
        let permissions = await FetchAPI({
            showErrorLogsOnConsole: showErrorLogsOnConsole,
            callbackWithErrorStatusOrConfirmation: true,
            history: history,
            apiShortName: "callUserPermissions",
            token: accessToken,
            apiNeeds: {
                userId: userId,
            },
        });

        if (
            permissions.ok === false
            || permissions.message === "Failed to fetch"
            || permissions.companyPermissions.length === 0
        ) {
            history.push("/error/noPermissions");
            setProcessing(false);
            return;
        }

        // FIND SELECTED COMPANY

        let Cookie = getUserCookie(userId);
        let selectedCompany;
        if (!Cookie) {
            selectedCompany = permissions ? permissions.companyPermissions[0] : "";
            setUserCookie(userId, selectedCompany);
        } else {
            selectedCompany = Cookie.selectedCompany;
        }

        // UPDATE STATE
        setUserState({
            userId: userId,
            userName: userName,
            userEmail: userEmail,
            companyPermissions: permissions ? permissions.companyPermissions : "",
            selectedCompany: selectedCompany,
            companyPackCount: 66,
            companyProductCount: 99,
            currentPermLevel: definePermissionLevel(selectedCompany?.permissions || null),
            accessToken: accessToken,
        });
        setProcessing(false);
    };

    /**
     * COOKIES
     */
    const setUserCookie = (userId, selectedCompany) => {
        let newCookie = {
            userId: userId,
            selectedCompany: selectedCompany,
        };
        cookies.set("userState", newCookie, { path: "/", expires: 0 });
    };
    const getUserCookie = (userId) => {
        let oldCookie = cookies.get("userState");
        if (!oldCookie || oldCookie.userId !== userId) {
            cookies.remove("userState");
            return false;
        } else {
            return oldCookie;
        }
    };
    const removeUserCookie = () => {
        cookies.remove("userState");
    };

    /**
     * CHANGE SELECTED COMPANY
     */
    const changeSelectedCompany = (selectedCompanyId) => {
        let newCompany = userState.companyPermissions[selectedCompanyId];
        setUserCookie(userState.userId, newCompany);
        setUserState((prev) => ({
            ...prev,
            selectedCompany: newCompany,
            currentPermLevel: definePermissionLevel(newCompany.permissions || null),
        }));
        setSidebarBehavior({ position: "landing", content: "none" });
    };

    /**
     * DEFINE PERMISION LEVEL FOR SELECTED COMPANY
     */
    const definePermissionLevel = (selectedCompanyPermissionsArray) => {
        const L0Text = "Indemand User";
        const L1Text = "in-demand V2 L1";
        const L2Text = "in-demand V2 L2";
        let currentLevel = -1;

        if (!selectedCompanyPermissionsArray || selectedCompanyPermissionsArray.length === 0) {
            return currentLevel;
        }

        selectedCompanyPermissionsArray.forEach((item) => {
            if (item.role === L0Text && currentLevel < 0) {
                currentLevel = 0;
            }
            if (item.role === L1Text && currentLevel < 1) {
                currentLevel = 1;
            }
            if (item.role === L2Text && currentLevel < 2) {
                currentLevel = 2;
            }
        });

        return currentLevel;
    };

    /**
     * MANAGER ACTIONS
     */
    const isAuthenticated = () => {
        if (!user || !userState) {
            return false;
        } else {
            return true;
        }
    };

    const login = () => {
        oidcMgr.signinRedirect();
    };

    const signinRedirectCallback = () => {
        oidcMgr
            .signinRedirectCallback()
            .then((user) => {
                if (user) {
                    handleUser(user);
                    history.replace("/");
                }
            })
            .catch((err) => {
                history.push("/error/signinRedirectCallbackErr" + err);
                setProcessing(false);
            });
    };

    const logout = () => {
        setProcessing(false);
        oidcMgr.signoutRedirect();
        oidcMgr.clearStaleState();
        oidcMgr.signoutRedirectCallback().then(() => {
            localStorage.clear();
            sessionStorage.clear();
            removeUserCookie();
            history.push("/loggedOut");
        });
    };

    const signoutRedirectCallback = () => {
        setProcessing(false);
        oidcMgr.signoutRedirectCallback().then(() => {
            localStorage.clear();
            sessionStorage.clear();
            oidcMgr.clearStaleState();
            removeUserCookie();
            history.push("/loggedOut");
        });
    };

    const tokenExpired = () => {
        if (showErrorLogsOnConsole) {
            console.info("tokenExpired!!!");
        }
        signoutRedirectCallback();
    };

    const signinSilent = () => {
        if (showErrorLogsOnConsole) {
            console.info("signinSilent>");
        }
        oidcMgr
            .signinSilent()
            .then((user) => {
                if (showErrorLogsOnConsole) {
                    console.info("silent signed in", user);
                }
            })
            .catch((err) => {
                if (showErrorLogsOnConsole) {
                    console.info("silent signed error", err);
                }
            });
    };

    const signinSilentCallback = () => {
        if (showErrorLogsOnConsole) {
            console.info("signinSilentCallBack>");
        }

        oidcMgr.signinSilentCallback((...props) => {
            console.info(props);
        });
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    return (
        <UserContext.Provider
            value={{
                isAuthenticated,
                user,
                userState,
                login,
                logout,
                signinSilent,
                signinSilentCallback,
                signinRedirectCallback,
                signoutRedirectCallback,
                changeSelectedCompany,
            }}
        >
            {processing ? <IsLoading showMeAlways /> : children}
        </UserContext.Provider>
    );
};

export default UserStore;
