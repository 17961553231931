import React, { useLayoutEffect, useState, useRef, useContext } from "react";
import _ from "lodash";
import useEventListener from "@use-it/event-listener";
import { ClientContext } from "contexts/ClientStore.js";
import { GlobalContext } from "contexts/GlobalStore.js";

import { Card } from "../Card/Card.js";
import { CardSeeAll } from "../CardSeeAll/CardSeeAll.js";
import "./CardSliderForHome.scss";

export const CardSliderForHome = (props) => {
    const { title, status, order } = props;
    const data = props.data ? props.data : [];
    const { CS } = useContext(ClientContext);
    const { convertDateForScreen } = useContext(GlobalContext);
    const [howMuchMore, setHowMuchMore] = useState();

    const sArea = useRef();
    const sAreaLong = useRef();
    const sLine = useRef();
    const sBar = useRef();
    const sBarZone = useRef();
    const mouseX = useRef();
    const delayFunc = useRef();
    const delayFunc2 = useRef();

    const [currentWINW, setCurrentWINW] = useState(CS.WINW);

    const [sBarMaxLeft, setSBarMaxLeft] = useState();
    const [sAreaMaxLeft, setSAreaMaxLeft] = useState();
    const [isSBarNeeded, setIsSBarNeeded] = useState();
    const [sBarLeftToWall, setSBarLeftToWall] = useState();
    const [halfOfSBar, setHalfOfSBar] = useState();

    /**
     * CALCULATE HOW MUCH MORE
     */
    useLayoutEffect(() => {
        setHowMuchMore(data.length - order);
    }, [data, order]);

    /**
     * INDIVIDUAL SCROLL BAR MOVEMENT
     * @description lots of Math
     */
    const blockScrollingIfDOMIsntReady = () => {
        if (
            (sBarMaxLeft && sBarMaxLeft) ||
            !sArea ||
            !sAreaLong ||
            !sLine ||
            !sBar ||
            !sBarZone ||
            !mouseX ||
            !delayFunc
        ) {
            return false;
        }
        return true;
    };
    const defineRects = () => {
        if (!blockScrollingIfDOMIsntReady) {
            return;
        }

        delayFunc2.current = _.delay(function () {
            let sAreaWidth = sArea.current.getBoundingClientRect().width; // -> görünür alanın genişliği
            let sAreaLongWidth = sAreaLong.current.getBoundingClientRect().width; //-> hareket eden alanın genişliği
            setSAreaMaxLeft(sAreaLongWidth - sAreaWidth); // maksimum scroll seviyesi

            if (sAreaLongWidth > sAreaWidth) {
                setIsSBarNeeded(true);
                let sBarRect = sBar.current.getBoundingClientRect();
                setSBarLeftToWall(sBarRect.left); // -> scroll barın sol duvara uzaklığı
                let sBarWidth = sBarRect.width; //-> scroll barın genişliği
                let sBarZoneWidth = sBarZone.current.getBoundingClientRect().width; //-> scroll barın hareket edebileceği genişlik
                setHalfOfSBar(sBarWidth / 2);
                setSBarMaxLeft(sBarZoneWidth - sBarWidth); // scrollBarın gelebileceği max left seviyesi
            }
        }, 500);
    };
    useLayoutEffect(() => {
        if ((sBarMaxLeft && currentWINW) === CS.WINW) {
            return;
        }
        defineRects();
        setCurrentWINW(CS.WINW);
    }, [CS.WINW]);
    const onScrollLeft = () => {
        if (!blockScrollingIfDOMIsntReady) {
            return;
        }
        let sAreaSLeft = sArea.current.scrollLeft;
        sBar.current.style.left = sAreaSLeft * (sBarMaxLeft / sAreaMaxLeft) + "px";
    };
    const onBarDrag = (mX, e) => {
        if (!blockScrollingIfDOMIsntReady) {
            return;
        }
        if (delayFunc) {
            clearTimeout(delayFunc.current);
        }
        let newVal;
        if (sBarLeftToWall + halfOfSBar > mX) {
            newVal = 0;
        } else if (mX > sBarLeftToWall + halfOfSBar + sBarMaxLeft) {
            newVal = sBarMaxLeft;
        } else {
            newVal = mX - sBarLeftToWall - halfOfSBar;
        }
        sBar.current.style.left = newVal + "px";
        // sArea.current.scrollLeft = newVal * (sAreaMaxLeft / sBarMaxLeft);
        sArea.current.scrollable = false;
        delayFunc.current = _.delay(function () {
            sArea.current.scrollable = true;
        }, 750);

        sArea.current.scrollTo({
            left: newVal * (sAreaMaxLeft / sBarMaxLeft),
            behavior: "smooth",
        });

        e.preventDefault();
    };
    const getMX = (e) => {
        if (!blockScrollingIfDOMIsntReady) {
            return;
        }
        if (sLine && sLine.current && sLine.current.mouseDown) {
            mouseX.current = Number((e.clientX || 0).toFixed(0));
            onBarDrag(mouseX.current, e);
        }
    };
    useEventListener("mousemove", (e) => {
        getMX(e);
    });
    useEventListener("mouseup", () => {
        if (sLine && sLine.current && sLine.current.mouseDown) {
            sLine.current.mouseDown = false;
        }
    });

    /**
     * UNMOUNT & CLEAR FOR DELAY FUNCTIONS
     */
    useLayoutEffect(() => {
        return () => {
            clearTimeout(delayFunc.current);
            clearTimeout(delayFunc2.current);
        };
    }, []);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (!data) {
        return null;
    }
    return (
        <React.Fragment>
            {/* SEPERATOR */}
            <div className="home_seperator">
                {title}
                <span className="home_seperatorCount"> ({data.length})</span>
            </div>

            {/* SEARCH BAR */}
            <div
                className="horizontal_scroll_zone"
                ref={sArea}
                onScroll={() => {
                    if (sArea.current.scrollable !== false) {
                        onScrollLeft();
                    }
                }}
            >
                <div ref={sAreaLong} className="horizontal_scroll_innerZone">
                    {/* CALL CARDS */}
                    {!data || data.length === 0 ? (
                        <div
                            style={{
                                minHeight: 50,
                                marginLeft: 20,
                                marginTop: 20,
                            }}
                        >
                            You don't have any "{title}" submission.
                        </div>
                    ) : (
                        data.map((item, key) => {
                            if (key > order - 1) {
                                return null;
                            }
                            return (
                                <Card
                                    key={key}
                                    hideStatus
                                    id={item.id}
                                    status={status}
                                    type={item.type}
                                    medName={item.title}
                                    dateOn={convertDateForScreen(item.modified)}
                                    dateBy={item.modifiedBy}
                                    nhsMsg={item.nhsMsg}
                                    submissionId={item.submissionId}
                                />
                            );
                        })
                    )}

                    {/* 'SEE ALL' CARD */}
                    {howMuchMore > 0 ? (
                        <CardSeeAll howMuchMore={howMuchMore} status={status} />
                    ) : null}
                </div>
            </div>

            {/* UNIQUE SCROLL BARS */}
            {!isSBarNeeded ? null : (
                <div ref={sBarZone}>
                    <div
                        ref={sLine}
                        className="horizontal_scroll_line"
                        onMouseDown={(e) => {
                            sLine.current.mouseDown = true;
                            getMX(e);
                        }}
                    >
                        <div ref={sBar} className="horizontal_scroll_moving_bar" />
                        <div className="lineplacement"></div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
