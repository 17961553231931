import React from "react";
import { useLayoutEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";
import { Button } from "components/FormElements/Button/Button.js";
import "./ErrorPages.scss";

export const ErrorPages = (props) => {
    const type = String(
        props?.type ? props.type : props?.match?.params?.type ? props.match.params.type : "unknown",
    );
    const {
        pageName,
        setPageName,
        setSidebarBehavior,
        goToHome,
        setIsLoading,
        disableIsLoadingWithDelay,
    } = useContext(GlobalContext);
    const history = useHistory();
    const thisPageName = "errorPage";

    /**
     * SET PAGE NAME and SIDEBAR BEHAVIOUR IN MOUNT AND REMOVE ISLOADING
     * @description sets pageName and sidebarBehaviour in globalStore
     */
    useLayoutEffect(() => {
        setIsLoading(true);
        setPageName(thisPageName);
        setSidebarBehavior({ position: "noSidebar", content: "hide" });
        disableIsLoadingWithDelay();
        history.replace("/error/" + type);
    }, [pageName]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="error_zone">
            <img
                className="error_logo"
                src={require("img/logo.svg")}
                alt=""
                onClick={() => {
                    goToHome();
                }}
            />

            <div className="error_title">Sorry to say, but...</div>
            <div>
                {type === "403" ? (
                    <div>
                        You do not have access to the page you have requested.
                        <br />
                        Please <a href="http://indemandsupport.medicines.org.uk/support/home">contact Datapharm</a>.
                    </div>
                ) : type === "404" ? (
                    <div>We could not locate the requested page</div>
                ) : type === "500" ? (
                    <div>
                        There is something wrong.
                        <br />
                        Please <a href="http://indemandsupport.medicines.org.uk/support/home">contact Datapharm</a>.
                    </div>
                ) : type === "noPermissions" ? (
                    <div>
                        <b>You have insufficient permissions to access emc med data dm+d.</b>
                        <br />
                        <br />
                        Please contact the service desk at <b>servicedesk@datapharm.com</b>
                        <br />
                        or call us on <b>01372 371 44</b>
                    </div>
                ) : (
                    <div>
                        There is something wrong.
                        <br />
                        Please try again or contact your admin.
                    </div>
                )}
                <br />
                {type !== "noPermissions" && `Error: ${type}`}
            </div>
            <div style={{ marginBottom: 30 }}></div>

            {type === "403" || type === "noPermissions" ? (
                <Button
                    buttonType=""
                    text="LOGIN"
                    onClick={() => history.push("/logout")}
                    additionalStyle={{}}
                />
            ) : (
                <Button
                    buttonType=""
                    text="HOME"
                    onClick={() => {
                        goToHome();
                    }}
                    additionalStyle={{}}
                />
            )}
        </div>
    );
};
