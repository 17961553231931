import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

export const reactPlugin = new ReactPlugin();

const browserHistory = createBrowserHistory({ basename: '' });
const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY ?? '';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();

const Telemetry = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('search');
    reactPlugin.trackPageView({
      uri: location.pathname,
      name: 'page change',
      properties: { query },
    });
  }, [location]);

  return children;
};

export default Telemetry;
