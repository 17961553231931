import React, { useContext, useState, useCallback, useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";
import { UserContext } from "contexts/UserStore.js";
import { FetchAPI } from "../../APIConnections/APIConnections.js";
import _ from "lodash";

export const MultipleSelectBox = (props) => {
    const { data, keyName, updateData, viewMode, silentUpdateData } = props;
    const {
        placeHolder,
        fieldName,
        currentValue,
        optionValue,
        apiNameForOptionValue,
        informationText,
        errorText,
        validationRules,
        handleData,
    } = data;
    const { turnArrayToLines, isNotEmpty, showErrorLogsOnConsole } = useContext(GlobalContext);
    const { userState } = useContext(UserContext);
    const [localLoading, setLocalLoading] = useState(false);
    const [localInputValue, setLocalInputValue] = useState();
    const [firstFocus, setFirstFocus] = useState(false);
    const [localOptionBuffer, setLocalOptionBuffer] = useState();
    const inputZone = useRef();
    let history = useHistory();

    /**
     * ADD AN ITEM
     * @description adds an item to Current values
     */
    const addAnItem = (arr) => {
        if (!arr) {
            return;
        }

        let label = arr.label;
        let value = arr.value;
        let newArray = [];
        let checkIfItsAlreadyExist = 0;

        (currentValue || []).forEach((each) => {
            newArray.push(each);
        });

        newArray.forEach((item) => {
            if (item.label === label || item.value === value) {
                checkIfItsAlreadyExist++;
            }
        });

        if (checkIfItsAlreadyExist === 0) {
            newArray.push({ label: label, value: value });
            updateData(keyName, newArray);
        }
        setLocalInputValue("");
    };

    /**
     * REMOVE AN ITEM
     * @description removes an item from Current values
     */
    const removeAnItem = (item) => {
        const newArray = currentValue.filter((VAL) => {
            return VAL.value !== item.value && VAL.label !== item.label;
        });
        updateData(keyName, newArray);
    };

    /**
     * CALL NEW OPTINS VIA API
     * @description call new options for dynamic selectBox
     * @description works with lodash
     */
    const callNewOptionsViaAPI = useCallback(
        _.debounce(async (value) => {
            let newData = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: apiNameForOptionValue,
                token: userState.accessToken,
                apiNeeds: {
                    value: value,
                    companyId: userState.selectedCompany?.id,
                },
            });

            if (handleData) {
                newData = handleData({ DATA: newData });
            }

            setLocalOptionBuffer(newData);
            setLocalLoading(false);
        }, 500),
        [],
    );
    useLayoutEffect(() => {
        if (!localOptionBuffer) {
            return;
        }

        silentUpdateData(keyName, localOptionBuffer, "optionValue");
        setLocalOptionBuffer();
    }, [localOptionBuffer]);

    /**
     * PREPARE FILTERED OPTIONS
     * @description filtered "options" before mapping it.
     */
    const filteredOptions = (optionValue || [])
        .filter(
            (option) =>
                option.label
                    .toLowerCase()
                    .includes(localInputValue ? String(localInputValue).toLowerCase() : "") ||
                !localInputValue,
        )
        .map((item, key) => {
            return (
                <div
                    key={key}
                    onMouseDown={() => {
                        addAnItem({ label: item.label, value: item.value });
                    }}
                >
                    {item.label}
                </div>
            );
        });

    /**
     * PREPARE SELECTED VALUES
     * @description prepares typedValues for currentValue
     */
    const selectedValues = (currentValue || []).map((item, key) => {
        return (
            <React.Fragment key={key}>
                <div className="form_selected">{item.label}</div>
                <img
                    className="form_remove"
                    src={require("img/formClose.svg")}
                    onMouseDown={() => {
                        removeAnItem(item);
                    }}
                    alt=""
                />
            </React.Fragment>
        );
    });

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (viewMode === "view") {
        return (
            <div className="view_multiple">
                <div>{fieldName}</div>
                <div>
                    {!currentValue
                        ? null
                        : currentValue.map((item, i) => {
                              return <span key={i}>{item.label}</span>;
                          })}
                </div>
            </div>
        );
    }
    return (
        <div id={keyName} className={"form_selectBox " + (errorText?.length > 0 ? "error" : "")}>
            {fieldName ? (
                <label
                    className={
                        "form_label" +
                        ((validationRules?.length > 0 && !isNotEmpty(currentValue)) ||
                        errorText?.length > 0
                            ? " onLoadControlError"
                            : "")
                    }
                >
                    {fieldName}
                </label>
            ) : null}

            {selectedValues.length > 0 ? selectedValues : null}

            <input
                ref={inputZone}
                spellCheck="false"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder={placeHolder}
                value={localInputValue ? localInputValue : ""}
                onFocus={() => {
                    setLocalInputValue("");
                    if (!firstFocus) {
                        setFirstFocus(true);
                    }
                    if (apiNameForOptionValue) {
                        setLocalLoading(true);
                        callNewOptionsViaAPI(keyName, "");
                    }
                }}
                onChange={(e) => {
                    let val = e.target.value;
                    setLocalInputValue(val);
                    if (apiNameForOptionValue) {
                        setLocalLoading(true);
                        callNewOptionsViaAPI(keyName, val);
                    }
                }}
            />

            <img className="form_downArrow" src={require("img/downArrow.svg")} alt="" />
            <div className="form_selectBoxContent">
                {localLoading ? (
                    <span>Loading...</span>
                ) :
                filteredOptions.length > 0 ? (
                    filteredOptions
                ) : (
                    <span>No results found.</span>
                )}
            </div>

            {informationText ? <div className="form_infoText">{informationText}</div> : null}
            {firstFocus && errorText?.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
        </div>
    );
};
