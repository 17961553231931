import React, { useState, useCallback } from "react";
import _ from "lodash";
import "./Button.scss";

export const Button = (props) => {
    const {
        buttonType,
        text,
        onClick,
        additionalStyle,
        throttle,
        viewMode,
    } = props;
    const [isDisabled, setIsDisabled] = useState(false);

    /**
     * ONCLICK ACTION
     * @description buttons click onClick action which is coming from parent.
     * @description and if throttle is true, button is disabled for 5 sec.
     */
    const onClickAction = () => {
        onClick();
        if (throttle) {
            setIsDisabled(true);
            throttledCall();
        }
    };
    const throttledCall = useCallback(
        _.debounce(async () => {
            setIsDisabled(false);
        }, 5000),
        [],
    );

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (viewMode === "view") {
        return null;
    }

    return (
        <button
            className={
                "button" +
                (buttonType ? " " + buttonType : "") +
                (isDisabled ? " deactive" : "")
            }
            onMouseDown={() => {
                onClickAction();
            }}
            style={additionalStyle}
            data-testid="buttonText"
        >
            {text}
        </button>
    );
};
