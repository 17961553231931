import React from "react";

export const Seperator = (props) => {
    const { data, viewMode, keyName } = props;
    const { placeHolder } = data || props || "";

    return (
        <div id={keyName} className={"form_seperator" + (viewMode === "view" ? " viewMode" : "")}>
            {placeHolder}
        </div>
    );
};
