import { useContext, useLayoutEffect } from "react";
import { UserContext } from "./UserStore.js";
import { GlobalContext } from "./GlobalStore.js";

import { useHistory } from "react-router-dom";

export const AuthPages = (props) => {
    let location = useHistory().location.pathname;
    const {
        signinRedirectCallback,
        login,
        logout,
        signoutRedirectCallback,
        signinSilentCallback,
    } = useContext(UserContext);
    const { setIsLoading } = useContext(GlobalContext);

    useLayoutEffect(() => {
        setIsLoading(true);
    }, []);

    useLayoutEffect(() => {
        switch (location) {
            case "/callback":
                signinRedirectCallback();
                break;
            case "/login":
                login();
                break;
            case "/logout":
                logout();
                break;
            case "/logout/callback/":
                signoutRedirectCallback();
                break;
            case "/silentRenew":
                signinSilentCallback();
                break;
            default:
                break;
        }
    }, []);

    return null;
};
