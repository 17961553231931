import React, { useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import Telemetry from './Telemetry';
import { PrivateRoute } from "./contexts/PrivateRoute.js";
import { AuthPages } from "./contexts/AuthPages.js";

import { IsLoading } from "./components/IsLoading/IsLoading.js";
import { BackEmblem } from "./components/BackEmblem/BackEmblem.js";
import { Sidebar } from "./components/Sidebar/Sidebar.js";
import { Header } from "./components/Header/Header.js";
import { Home } from "./pages/Home/Home.js";
import { HandleSub } from "./pages/HandleSub/HandleSub.js";
import { About } from "./pages/About/About.js";
import { ErrorPages } from "./pages/ErrorPages/ErrorPages.js";
import { LoggedOut } from "./pages/LoggedOut/LoggedOut.js";
import { MobileMenu } from "./components/MobileMenu/MobileMenu.js";
import { List } from "./pages/List/List.js";

import { UserContext } from "contexts/UserStore.js";

import { version } from "../package.json";

import "./css_app.scss";

export const App = () => {

    const { userState } = useContext(UserContext);
    const history = useHistory();

    if (userState && userState.currentPermLevel < 1) {
        history.push("/logout");
    }

    return (

            <div className="app">
                <div
                    style={{ position: "fixed", zIndex: 99999, bottom: 4, right: 8, opacity: 0.5 }}
                >
                    <span>{version}</span>
                </div>
                {/* ISLOADING */}
                <IsLoading />

                {/* BACK EMBLEM */}
                <BackEmblem svgClass="onWhite" />

                {/* SIDEBAR */}
                <Sidebar />

                {/* CONTENT ZONE is STARTING */}
                <div className={"layout_contentZone"}>
                    {/* MOBILE HEADER WILL PLACE HERE ?!?!? */}
                    <MobileMenu />

                    {/* HEADER */}
                    <Header />

                    <Telemetry>
                        {/* SWITCH to PAGE CONTENT */}
                        <Switch>
                            <PrivateRoute exact path={["/home", "/"]} component={Home} />
                            <PrivateRoute exact path="/about&legal" component={About} />

                            <PrivateRoute exact path="/new/:submissionType" component={HandleSub} />
                            <PrivateRoute exact path="/edit/:ID" component={HandleSub} />
                            <PrivateRoute exact path="/view/:ID" component={HandleSub} />
                            <PrivateRoute exact path="/list" component={List} />

                            <Route exact path={["/error", "/error/:type"]} component={ErrorPages} />
                            <Route exact path="/loggedOut" component={LoggedOut} />

                            <Route
                                exact
                                path={[
                                    "/callback",
                                    "/login",
                                    "/logout",
                                    "/logout/callback/",
                                    "/silentRenew",
                                ]}
                                component={AuthPages}
                            />

                            <Route render={(routeProps) => <ErrorPages type={404} {...routeProps} />} />
                        </Switch>
                    </Telemetry>
                </div>
            </div>
    );
};
