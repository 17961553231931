import React from "react";

export const IngredientBox = (props) => {
    const { currentAll, keyName, remove, viewMode, alwaysNonEditable } = props;

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="form_ingredient">
            {viewMode === "view" || alwaysNonEditable ? null : (
                <img
                    className="form_remove"
                    src={require("img/formClose.svg")}
                    alt=""
                    onClick={() => {
                        remove({ keyName: keyName });
                    }}
                />
            )}

            <div>
                <b>{currentAll?.ingredientName?.currentValue?.label || ""} </b>
            </div>
            <div>
                <b>BOPS: </b> {currentAll?.bops?.currentValue?.label || ""}
            </div>
            <div>
                <b>BoSS: </b> {currentAll?.boss?.currentValue?.label || ""}
            </div>
            <div>
                <b>Numerator Strength: </b>
                {(currentAll?.numerator?.currentValue?.input || "") +
                    " " +
                    (currentAll?.numerator?.currentValue?.unit?.label || "")}
            </div>
            <div>
                <b>Denominator Strength: </b>
                {(currentAll?.denominator?.currentValue?.input || "") +
                    " " +
                    (currentAll?.denominator?.currentValue?.unit?.label || "")}
            </div>
        </div>
    );
};
