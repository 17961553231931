import React, { useLayoutEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { GlobalContext } from "contexts/GlobalStore.js";
import { UserContext } from "contexts/UserStore.js";
import { FetchAPI } from "components/APIConnections/APIConnections.js";
import { Seperator } from "components/FormElements/Seperator/Seperator.js";
import { SummarySection } from "components/FormElements/SummarySection/SummarySection.js";
import { FreeForm } from "components/FormElements/FreeForm/FreeForm.js";

import { UserActions } from "./UserActions.js";
import {
    NewProduct,
    NewPack,
    UpdateProduct,
    UpdatePack,
    MassPriceUpdate,
} from "./setup/formFields.js";

import "./HandleSub.scss";
import "components/FormElements/forms.scss";

export let textFunc;

export const HandleSub = (props) => {
    const { match } = props;
    const { params } = match || {};
    let { ID } = params || undefined;
    let { submissionType } = params || undefined;
    let { submissionID } = params || undefined;
    const {
        isLoading,
        setPageName,
        setSidebarBehavior,
        setHeaderTitles,
        addSpaces,
        setIsLoading,
        disableIsLoadingWithDelay,
        typeIdentifier,
        showErrorLogsOnConsole,
    } = useContext(GlobalContext);
    const { userState } = useContext(UserContext);
    const history = useHistory();
    const action = history.location.pathname.split("/")[1];
    const [data, setData] = useState();
    const [settings, setSettings] = useState();
    const [sections, setSections] = useState();

    /**
     * CONVERTERS AND SUPPORTER FUNCTIONS
     * @description convert data to currentValue or savedValue or nhsForm
     */
    const convertSavedValueToCurrentValue = (fieldOrfieldName, savedValue, newData = data) => {
        if (!fieldOrfieldName || !savedValue) {
            return null;
        }
        let field;
        if (typeof fieldOrfieldName === "object") {
            field = fieldOrfieldName;
        } else {
            field = newData[fieldOrfieldName];
        }
        let componentType = field.componentTypeName;
        let currentValue;
        let newData2 = newData;

        switch (componentType) {
            case "DataGrid":
                currentValue = savedValue;
                break;
            case "TextInput":
                currentValue = savedValue;
                break;
            case "SelectBox":
                if (field.apiNameForOptionValue) {
                    currentValue = savedValue;
                } else {
                    let newObj;
                    for (let i in field.optionValue) {
                        if (field.optionValue[i].value === savedValue) {
                            newObj = field.optionValue[i];
                            break;
                        }
                    }

                    currentValue = {
                        value: savedValue,
                        label: newObj?.label,
                    };
                }
                break;
            case "MultipleSelectBox":
                if (field.apiNameForOptionValue) {
                    if (showErrorLogsOnConsole) {
                        console.log(
                            "we don't have a dynamic multipleSelectBox. Fill here if needed.",
                        );
                    }
                } else {
                    let newObj = [];
                    for (let i in field.optionValue) {
                        for (let i2 in savedValue) {
                            if (field.optionValue[i].value === savedValue[i2]) {
                                newObj.push({
                                    value: savedValue[i2],
                                    label: field.optionValue[i].label,
                                });
                            }
                        }
                    }
                    currentValue = newObj;
                }
                break;
            case "RadioButton":
                let newRadio = [];
                for (let i in field.optionValue) {
                    if (field.optionValue[i].value === savedValue) {
                        newRadio = {
                            value: savedValue,
                            label: field.optionValue[i].label,
                        };
                    }
                }
                currentValue = newRadio;
                break;
            case "DateInput":
                let saved2 = savedValue.split("-");
                let dayValue = saved2[2];
                let monthValue = saved2[1];
                let yearValue = saved2[0];
                currentValue = {
                    day: { label: dayValue, value: dayValue },
                    month: {
                        label: field.optionValue.month[monthValue - 1].label,
                        value: monthValue,
                    },
                    year: { label: yearValue, value: yearValue },
                };
                break;
            case "UnitSelect":
                let newObj;
                for (let i in field.optionValue) {
                    if (field.optionValue[i].value === savedValue.uom) {
                        newObj = field.optionValue[i];
                        break;
                    }
                }

                if (!newObj) {
                    break;
                }

                currentValue = {
                    input: savedValue.value,
                    unit: {
                        value: savedValue.uom,
                        label: newObj.label,
                    },
                };

                break;
            case "MultipleTextInput":
                currentValue = savedValue;
                break;
            case "FileInput":
                currentValue = {
                    fileUrl: savedValue.url,
                    label: savedValue.value,
                    value: savedValue.value,
                };
                break;
            case "Packs":
            case "Ingredients":
                let currentPack = {};
                savedValue.forEach((item) => {
                    let newPack = {};
                    Object.keys(item).forEach((key) => {
                        let fieldName2 = key;
                        let oldSaved = item[key];
                        let currentElem = convertSavedValueToCurrentValue(
                            newData2[fieldName2],
                            oldSaved,
                            newData2,
                        );
                        newPack[key] = { currentValue: currentElem };
                    });

                    if (componentType === "Packs") {
                        currentPack[
                            newPack?.quantity?.currentValue?.input +
                                "" +
                                newPack?.quantity?.currentValue?.unit?.label
                        ] = newPack;
                    } else {
                        currentPack[newPack?.ingredientName?.currentValue?.value] = newPack;
                    }
                });
                currentValue = currentPack;

                break;
            default:
                break;
        }

        return currentValue;
    };
    const convertCurrentValueToSavedValue = (fieldOrfieldName, currentValue) => {
        if (!fieldOrfieldName || !currentValue) {
            return null;
        }
        let field;
        if (typeof fieldOrfieldName === "object") {
            field = fieldOrfieldName;
        } else {
            field = data[fieldOrfieldName];
        }

        let componentType = field.componentTypeName;
        let savedValue;

        switch (componentType) {
            case "TextInput":
                savedValue = currentValue;
                break;
            case "SelectBox":
                if (field.apiNameForOptionValue) {
                    savedValue = currentValue;
                } else {
                    savedValue = currentValue.value;
                }
                break;
            case "RadioButton":
                savedValue = currentValue.value;
                break;
            case "MultipleSelectBox":
                let newSaved = [];
                currentValue.forEach((item) => {
                    newSaved.push(item.value);
                });
                savedValue = newSaved;
                break;
            case "DateInput":
                if (!currentValue.day || !currentValue.month || !currentValue.year) {
                    savedValue = null;
                } else {
                    // use slice() method instead of string.prototype.padStart, as we need to still support IE11...
                    savedValue =
                        currentValue.year.value +
                        "-" +
                        ("0" + currentValue.month.value).slice(-2) +
                        "-" +
                        ("0" + currentValue.day.value).slice(-2);
                }

                break;
            case "UnitSelect":
                if (!currentValue.input || !currentValue.unit) {
                    savedValue = null;
                } else {
                    savedValue = {
                        value: currentValue.input,
                        uom: currentValue.unit.value,
                    };
                }
                break;
            case "Packs":
            case "Ingredients":
                let savedPack = [];
                Object.keys(currentValue).forEach((key) => {
                    let objInner = currentValue[key];
                    let innerPack = {};
                    Object.keys(objInner).forEach((key2) => {
                        let fieldName2 = key2;
                        let elem2 = objInner[key2].currentValue;
                        let savedElem = convertCurrentValueToSavedValue(fieldName2, elem2);
                        innerPack[key2] = savedElem;
                    });
                    savedPack.push(innerPack);
                });
                savedValue = savedPack;
                break;
            case "MultipleTextInput":
                savedValue = currentValue;
                break;
            case "FileInput":
                savedValue = {
                    value: currentValue.label,
                    url: currentValue.fileUrl,
                };

                break;
            case "DataGrid":
                savedValue = currentValue.filter((item) => item.newPrice !== undefined);

                break;
            default:
                break;
        }
        return savedValue;
    };
    const convertNhsFormToCurrentValue = (dataOrg, incomingForm) => {
        if (!dataOrg || !incomingForm) {
            return;
        }

        let data = _.cloneDeep(dataOrg);

        const yesNoNAConverter = (val) => {
            if (val === true) {
                return "Yes";
            } else if (val === false) {
                return "No";
            } else if (val === null) {
                return "NA";
            }
            return undefined;
        };

        /**
         * IsThere function
         * @description we need this function because some NHS values are coming null but it doesn't mean that it is null.
         * "null" is a variable in nhs form. so, I have to handle them as well.
         */
        const formKeys = Object.keys(incomingForm) || [];
        const isThere = (val) => {
            if (formKeys.indexOf(val) > -1) {
                return true;
            }
            return false;
        };
        let onlyChanged = {
            calendarPack: { currentValue: null },
            hospitalPack: { currentValue: null },
            limitedStability: { currentValue: null },
            price: { currentValue: null },
            subpackInfo: { currentValue: null },
            gtinCodes: { currentValue: null },
            legalCategory: { currentValue: null },
            quantity: { currentValue: null },
        };

        if (isThere("name") && data.name) {
            data.name.currentValue = incomingForm.name;
        }
        // need relatedVMP label !!!! ---- waiting for BE.
        if (isThere("virtualProductId") && data.relatedVMP) {
            data.relatedVMP.currentValue = {
                value: incomingForm.virtualProductId,
                label: incomingForm.virtualProductId,
            };
        }
        if (isThere("formulation") && data.formulation) {
            data.formulation.currentValue = convertSavedValueToCurrentValue(
                "formulation",
                incomingForm.formulation || "",
                data,
            );
        }
        if (isThere("licensedRoutes") && data.licensedRoutes) {
            data.licensedRoutes.currentValue = convertSavedValueToCurrentValue(
                "licensedRoutes",
                incomingForm.licensedRoutes,
                data,
            );
        }
        if (isThere("flavourCode") && data.productFlavour) {
            data.productFlavour.currentValue = convertSavedValueToCurrentValue(
                "productFlavour",
                incomingForm.flavourCode,
                data,
            );
        }
        if (isThere("glutenFree") && data.glutenFreeProduct) {
            data.glutenFreeProduct.currentValue = convertSavedValueToCurrentValue(
                "glutenFreeProduct",
                yesNoNAConverter(incomingForm.glutenFree),
                data,
            );
        }
        if (isThere("preservativeFree") && data.preservativeFreeProduct) {
            data.preservativeFreeProduct.currentValue = convertSavedValueToCurrentValue(
                "preservativeFreeProduct",
                yesNoNAConverter(incomingForm.preservativeFree),
                data,
            );
        }
        if (isThere("sugarFree") && data.sugarFreeProduct) {
            data.sugarFreeProduct.currentValue = convertSavedValueToCurrentValue(
                "sugarFreeProduct",
                yesNoNAConverter(incomingForm.sugarFree),
                data,
            );
        }
        if (isThere("cfcFree") && data.cfcFreeProduct) {
            data.cfcFreeProduct.currentValue = convertSavedValueToCurrentValue(
                "cfcFreeProduct",
                yesNoNAConverter(incomingForm.cfcFree),
                data,
            );
        }
        if (isThere("controlledDrugCategoryCode") && data.controlledDrugCategory) {
            data.controlledDrugCategory.currentValue = convertSavedValueToCurrentValue(
                "controlledDrugCategory",
                incomingForm.controlledDrugCategoryCode,
                data,
            );
        }
        if (isThere("licensingAuthorityCode") && data.currentLicensingAuthority) {
            data.currentLicensingAuthority.currentValue = convertSavedValueToCurrentValue(
                "currentLicensingAuthority",
                incomingForm.licensingAuthorityCode,
                data,
            );
        }
        if (isThere("restrictionOnAvailability") && data.restrictionsOnAvailability) {
            data.restrictionsOnAvailability.currentValue = convertSavedValueToCurrentValue(
                "restrictionsOnAvailability",
                incomingForm.restrictionOnAvailability,
                data,
            );
        }
        if (isThere("emaAdditionalMonitoring") && data.emaAdditionalMonitoring) {
            data.emaAdditionalMonitoring.currentValue = convertSavedValueToCurrentValue(
                "emaAdditionalMonitoring",
                incomingForm.emaAdditionalMonitoring,
                data,
            );
        }
        if (isThere("ingredients") && data.ingredients) {
            let newIngs = {};
            incomingForm.ingredients.forEach((item) => {
                newIngs[item.id] = {
                    ingredientName: {
                        currentValue: {
                            label: item.name,
                            value: item.id,
                        },
                    },
                    bops: {
                        currentValue: convertSavedValueToCurrentValue(
                            "bops",
                            item.basisOfPharmStrengthCode,
                            data,
                        ),
                    },
                    boss: {
                        currentValue: {
                            label: item.basisOfStrengthSubstanceId,
                            value: item.basisOfStrengthSubstanceId,
                        },
                    },
                    numerator: {
                        currentValue: convertSavedValueToCurrentValue(
                            "numerator",
                            {
                                value: item.numeratorValue,
                                uom: item.numeratorUomCode,
                            },
                            data,
                        ),
                    },
                    denominator: {
                        currentValue: convertSavedValueToCurrentValue(
                            "denominator",
                            {
                                value: item.denominatorStrengthValue,
                                uom: item.denominatorStrengthUomCode,
                            },
                            data,
                        ),
                    },
                };
            });

            data.ingredients.currentValue = newIngs;
        }
        if (isThere("packs") && data.packs) {
            let newPacks = {};
            incomingForm.packs.forEach((item) => {
                let findQuantity = {
                    currentValue: convertSavedValueToCurrentValue(
                        "quantity",
                        {
                            value: item.quantity,
                            uom: item.unitOfMeasureCode,
                        },
                        data,
                    ),
                };

                newPacks[
                    findQuantity.currentValue.input + "" + findQuantity.currentValue.unit.label
                ] = {
                    quantity: findQuantity,
                    legalCategory: {
                        currentValue: convertSavedValueToCurrentValue(
                            "legalCategory",
                            item.legalCategoryCode,
                            data,
                        ),
                    },
                    subpackInfo: { currentValue: item.subPackInformation },
                    gtinCodes: { currentValue: item.gtinCodes },
                    calendarPack: {
                        currentValue: convertSavedValueToCurrentValue(
                            "calendarPack",
                            yesNoNAConverter(item.calendarPack),
                            data,
                        ),
                    },
                    hospitalPack: {
                        currentValue: convertSavedValueToCurrentValue(
                            "hospitalPack",
                            yesNoNAConverter(item.hospitalPack),
                            data,
                        ),
                    },
                    limitedStability: {
                        currentValue: convertSavedValueToCurrentValue(
                            "limitedStability",
                            yesNoNAConverter(item.limitedStability),
                            data,
                        ),
                    },
                    price: { currentValue: item.price },
                };
            });

            data.packs.currentValue = newPacks;
        }

        /**
         * UPDATE PACK VALS.
         */

        if (isThere("calendarPack") && data.calendarPack) {
            data.calendarPack.currentValue = onlyChanged.calendarPack.currentValue = convertSavedValueToCurrentValue(
                "calendarPack",
                yesNoNAConverter(incomingForm.calendarPack),
                data,
            );
        }
        if (isThere("hospitalPack") && data.hospitalPack) {
            data.hospitalPack.currentValue = onlyChanged.hospitalPack.currentValue = convertSavedValueToCurrentValue(
                "hospitalPack",
                yesNoNAConverter(incomingForm.hospitalPack),
                data,
            );
        }
        if (isThere("limitedStability") && data.limitedStability) {
            data.limitedStability.currentValue = onlyChanged.limitedStability.currentValue = convertSavedValueToCurrentValue(
                "limitedStability",
                yesNoNAConverter(incomingForm.limitedStability),
                data,
            );
        }
        if (isThere("price") && data.price) {
            data.price.currentValue = onlyChanged.price.currentValue = incomingForm.price + "";
        }
        if (isThere("subPackInformation") && data.subpackInfo) {
            data.subpackInfo.currentValue = onlyChanged.subpackInfo.currentValue =
                incomingForm.subPackInformation;
        }
        if (isThere("gtinCodes") && data.gtinCodes) {
            data.gtinCodes.currentValue = onlyChanged.gtinCodes.currentValue =
                incomingForm.gtinCodes;
        }
        if (isThere("legalCategoryCode") && data.legalCategory) {
            data.legalCategory.currentValue = onlyChanged.legalCategory.currentValue = convertSavedValueToCurrentValue(
                "legalCategory",
                incomingForm.legalCategoryCode,
                data,
            );
        }
        if (isThere("quantity") && data.quantity) {
            data.quantity.currentValue = onlyChanged.quantity.currentValue = convertSavedValueToCurrentValue(
                "quantity",
                {
                    value: incomingForm.quantity,
                    uom: incomingForm.unitOfMeasureCode,
                },
                data,
            );
        }

        /**
         * LOG THEM.
         */

        if (
            !process.env.NODE_ENV &&
            process.env.NODE_ENV === "development" &&
            showErrorLogsOnConsole
        ) {
            console.info("nhsFormConverter worked.");
            console.info("incomingForm>", incomingForm);
            console.info("resultData>", data);
        }

        return {
            data: data,
            onlyChanged: onlyChanged,
        };
    };

    const findError = (fieldOrfieldName, currentValue) => {
        let errorText = [];

        if (!fieldOrfieldName) {
            return errorText;
        }

        let field;
        if (typeof fieldOrfieldName === "object") {
            field = fieldOrfieldName;
        } else {
            if (!data) {
                return errorText;
            }
            field = data[fieldOrfieldName];
        }

        if (!field?.validationRules || field.validationRules.length === 0) {
            return errorText;
        }

        field.validationRules.forEach((rule) => {
            let validationResult = rule({
                typeIdentifier: typeIdentifier,
                DATA: currentValue,
                hideFromUI: field.hideFromUI,
            });
            if (validationResult !== true) {
                errorText.push(validationResult);
            }
        });

        return errorText;
    };
    const findAllErrors = (data) => {
        Object.entries(data).map(([key, item]) => {
            let validationRules = item.validationRules;
            if (!validationRules) {
                return null;
            }

            let errorText = [];

            validationRules.forEach((rule) => {
                let validationResult = rule({
                    keyName: key,
                    DATA: item.currentValue,
                    setHeaderTitles: setHeaderTitles,
                    typeIdentifier: typeIdentifier,
                    token: userState.accessToken,
                    hideFromUI: item.hideFromUI,
                });
                if (validationResult !== true) {
                    errorText.push(validationResult);
                }
            });

                data[key].errorText = errorText;

            return null;
        });

        return data;
    };
    const areTheyEqual = (item1, item2) => {
        const typeOfItem1 = typeIdentifier(item1);
        const typeOfItem2 = typeIdentifier(item2);
        if (typeOfItem1 !== typeOfItem2) {
            return false;
        }

        if (typeOfItem1 === "string" || typeOfItem1 === "number" || typeOfItem1 === "boolean") {
            if (item1 !== item2) {
                return false;
            }
        } else if (typeOfItem1 === "array") {
            if (!_.isEqual(item1.sort(), item2.sort())) {
                return false;
            }
        } else if (typeOfItem1 === "object") {
            if (!_.isEqual(item1, item2)) {
                return false;
            }
        }

        return true;
    };

    /**
     * GET and ORGANISE ALL DATA
     */
    useLayoutEffect(() => {
        // go Error page if page doesn't have enough data.

        if (
            (action !== "new" && action !== "edit" && action !== "view") ||
            (action === "new" &&
                submissionType !== "NewProduct" &&
                submissionType !== "NewPack" &&
                submissionType !== "UpdateProduct" &&
                submissionType !== "UpdatePack" &&
                submissionType !== "MassPriceUpdate") ||
            ((action === "edit" || action === "view") && !ID)
        ) {
            history.push("/error/404");
        }

        let newData;
        let savedData;

        let currentID = ID;
        let currentAction = action;
        let currentSubmissionType = submissionType;
        let currentSubmissionID = submissionID;
        let currentStatus = "Draft";
        let currentPageName;
        let newDataKeys;
        let newInternalState = 1;
        let inComingPack;

        // STEP 1 - AWAIT
        // GET SAVED DATA if action === edit or view
        const step1 = async () => {
            let retrievedData;
            if (action === "new" && submissionType === "MassPriceUpdate") {
                const newValue = await FetchAPI({
                    showErrorLogsOnConsole: showErrorLogsOnConsole,
                    history: history,
                    apiShortName: "callMassPriceUpdate",
                    token: userState.accessToken,
                    apiNeeds: {
                        companyId: userState.selectedCompany?.id,
                    },
                    setIsLoading: (boo) => {
                        setIsLoading(boo);
                    },
                });

                retrievedData = {
                    type: submissionType,
                    data: {
                        prices: newValue.data,
                    },
                };
            } else if (
                (action === "view" || action === "edit") &&
                submissionType === "MassPriceUpdate"
            ) {
                setIsLoading(false);
                return;
            } else if (action === "view" || action === "edit") {
                retrievedData = await FetchAPI({
                    showErrorLogsOnConsole: showErrorLogsOnConsole,
                    history: history,
                    apiShortName: "callASubmission",
                    token: userState.accessToken,
                    apiNeeds: {
                        ID: ID,
                        submissionID: submissionID,
                        companyId: userState.selectedCompany?.id,
                    },
                    setIsLoading: (boo) => {
                        setIsLoading(boo);
                    },
                });

                if (!retrievedData || retrievedData.data.length === 0) {
                    history.push("/error/404");
                }

                if (retrievedData.type === "UpdatePack" && retrievedData.data.relatedPack) {
                    inComingPack = await FetchAPI({
                        showErrorLogsOnConsole: showErrorLogsOnConsole,
                        history: history,
                        apiShortName: "callAMPP_idSearch",
                        token: userState.accessToken,
                        apiNeeds: {
                            value: retrievedData.data.relatedPack,
                        },
                    });
                }
            }

            if (retrievedData) {
                savedData = retrievedData;
                currentID = retrievedData.id;
                currentSubmissionType = retrievedData.type;
                currentSubmissionID = retrievedData.submissionId;
                currentStatus = retrievedData.status || "Draft";
            }
        };

        // STEP 2
        // GET FORM FIELDS DEPENDING ON SUBMISSION TYPE (NewProduct, UpdatePack etc.)
        const step2 = async () => {
            switch (currentSubmissionType) {
                case "NewProduct":
                    newData = _.cloneDeep(NewProduct(await getProductLookups()));
                    break;
                case "NewPack":
                    newData = _.cloneDeep(NewPack(await getPackLookups()));
                    break;
                case "UpdateProduct":
                    newData = _.cloneDeep(UpdateProduct(await getProductLookups()));
                    break;
                case "UpdatePack":
                    newData = _.cloneDeep(UpdatePack(await getPackLookups()));
                    break;
                case "MassPriceUpdate":
                    newData = _.cloneDeep(MassPriceUpdate);
                    break;
                default:
                    history.push("/error/404");
                    return;
            }
        };

        const getProductLookups = async () => {
            const formulations = await getLookup("callFormulation");
            const licensedRoutes = await getLookup("callLicensedRoutes");
            const flavours = await getLookup("callFlavours")
            const controlledDrugCategories = await getLookup("callControlledDrugCategories")
            const currentLicensing = await getLookup("callCurrentLicensing")
            const restrictions = await getLookup("callRestrictions")
            const bops = await getLookup("callBops")
            const units = await getLookup("callUnits");
            const legalCategories = await getLookup("callLegalCategory")
            return {formulations, licensedRoutes, flavours, controlledDrugCategories, currentLicensing, restrictions, bops, units, legalCategories};
        }

        const getPackLookups = async () => {
            const units = await getLookup("callUnits");
            const legalCategories = await getLookup("callLegalCategory")
            return {units, legalCategories};
        }

        const getLookup = async (apiShortName) => await FetchAPI({
            showErrorLogsOnConsole: showErrorLogsOnConsole,
            history: history,
            apiShortName: apiShortName,
            token: userState.accessToken,
            setIsLoading: (boo) => {
                setIsLoading(boo);
            },
        });

        // STEP 3
        // UPDATE newData via savedData if action is VIEW or EDIT
        const step3 = () => {
            const processData = () => {
                if (!savedData) {
                    return;
                }

                newDataKeys = Object.keys(newData);
                if (newDataKeys.indexOf("name") > -1) {
                    newData.name.currentValue = savedData.title;
                }
                let list = Object.keys(savedData.data);
                list.forEach((key) => {
                    if (newDataKeys.indexOf(key) > -1) {
                        newData[key].currentValue = convertSavedValueToCurrentValue(
                            newData[key],
                            savedData.data[key],
                            newData,
                        );
                    }
                });
            };

            if (action === "new") {
                processData();
            } else if (action === "view" || action === "edit") {
                processData();

                if (currentSubmissionType === "MassPriceUpdate") {
                    if (action === "view") {
                        newData.prices.isReadOnly = true;
                    } else {
                        newData.prices.isReadOnly = false;
                    }
                }

                if (currentSubmissionType === "UpdateProduct") {
                    if (_.isEmpty(newData.relatedProduct.currentValue)) {
                        newInternalState = 1;

                        if (newData.discontinueReason) {
                            newData.discontinueReason.hideFromUI = true;
                        }
                    } else {
                        if (newData.discontinueProduct?.currentValue?.label === "Yes") {
                            newInternalState = 2;

                            if (newData.discontinueReason) {
                                newData.discontinueReason.hideFromUI = false;
                            }
                        } else if (newData.discontinueProduct?.currentValue?.label === "No") {
                            newInternalState = 3;

                            if (newData.discontinueReason) {
                                newData.discontinueReason.hideFromUI = true;
                            }
                        } else {
                            newInternalState = 2;

                            if (newData.discontinueReason) {
                                newData.discontinueReason.hideFromUI = true;
                            }
                        }
                    }
                }

                if (currentSubmissionType === "UpdatePack") {
                    let nhsConverter = convertNhsFormToCurrentValue(newData, inComingPack);
                    inComingPack = _.cloneDeep(nhsConverter.onlyChanged);

                    if (
                        inComingPack?.quantity?.currentValue?.input &&
                        inComingPack?.quantity?.currentValue?.unit?.label
                    ) {
                        newData.relatedPack.currentValue.label =
                            inComingPack.quantity.currentValue.input +
                            " " +
                            inComingPack.quantity.currentValue.unit.label;
                    }

                    if (_.isEmpty(newData.relatedProduct.currentValue)) {
                        newInternalState = 1;

                        if (newData.discontinueReason) {
                            newData.discontinueReason.hideFromUI = true;
                            newData.discontinueQuantity.hideFromUI = true;
                        }
                    } else if (_.isEmpty(newData.relatedPack.currentValue)) {
                        newInternalState = 2;

                        if (newData.discontinueReason) {
                            newData.discontinueReason.hideFromUI = true;
                            newData.discontinueQuantity.hideFromUI = true;
                        }
                    } else {
                        if (newData.discontinuePack?.currentValue?.label === "Yes") {
                            newInternalState = 3;
                            if (newData.discontinueReason) {
                                newData.discontinueReason.hideFromUI = false;
                                newData.discontinueQuantity.hideFromUI = false;
                            }
                        } else if (newData.discontinuePack?.currentValue?.label === "No") {
                            newInternalState = 4;
                            if (newData.discontinueReason) {
                                newData.discontinueReason.hideFromUI = true;
                                newData.discontinueQuantity.hideFromUI = true;
                            }
                        } else {
                            newInternalState = 3;
                            if (newData.discontinueReason) {
                                newData.discontinueReason.hideFromUI = true;
                                newData.discontinueQuantity.hideFromUI = true;
                            }
                        }
                    }
                }
            }
        };

        // STEP 4
        // FIND ERRORS
        const step4 = () => {
            if (savedData) {
                if (
                    currentSubmissionType === "UpdateProduct" ||
                    currentSubmissionType === "UpdatePack"
                ) {
                    const savedIngredients = savedData["ingredients"] || [];
                    const hasNoSavedIngredients = savedIngredients.length === 0;

                    const target = newData.ingredients || {};
                    const noValidation = [];

                    if (hasNoSavedIngredients) {
                        target.validationRules = noValidation;
                    }
                }
            }

            newData = findAllErrors(newData);
        };

        // LAST STEP, STEP 5
        // UPDATE STATES

        const step5 = () => {
            disableIsLoadingWithDelay();
            if (action === "new") {
                setHeaderTitles({
                    id: null,
                    submissionType: addSpaces(currentSubmissionType),
                    productName: null,
                    status: "Draft",
                    submissionId: null,
                    currentAction: currentAction,
                });
            } else {
                setHeaderTitles({
                    id: ID,
                    submissionType: addSpaces(savedData.type),
                    productName: savedData.title,
                    status: currentStatus,
                    submissionId: submissionID,
                    currentAction: currentAction,
                });
            }
            currentPageName =
                "handlePage/" +
                currentAction +
                "/" +
                currentSubmissionType +
                "/" +
                currentID;
                //currentSubmissionID;
            setPageName(currentPageName);
            setSidebarBehavior({ position: "narrow", content: "none" });

            setSettings({
                isThereAnyChange: false,
                action: currentAction,
                submissionType: currentSubmissionType,
                ID: currentID,
                submissionID: currentSubmissionID,
                name: savedData ? savedData.title : "",
                status: currentStatus,
                savedData: savedData,
                internalState: newInternalState,
                inComingPack: inComingPack,
            });
            setData(newData);
        };

        // RUN FUNCS
        const runFuncs = async () => {
            await step1();
            await step2();
            step3();
            step4();
            step5();
        };
        runFuncs();
    }, [ID, submissionType, submissionID, action]);
    
    /**
     * UPDATE DATA
     */
    const updateData = async (fieldName, newDataObj, propName = "currentValue") => {
        if (!data || !settings) {
            return;
        }
        let currentSubmissionType = settings.submissionType || "";

        /**
         * CLONE MAIN DATA and CHANGE INCOMING VALUES
         */
        let errorText = findError(fieldName, newDataObj);
        let newData = _.cloneDeep(data);
        let newSettings = _.cloneDeep(settings);

        newData[fieldName] = {
            ...newData[fieldName],
            [propName]: newDataObj,
            ...(propName === "currentValue" && {
                errorText: errorText,
            }),
        };

        /**
         * CHECK IF THERE IS ANY CHANGE FOR ONE TIME
         */
        if (
            !settings.isThereAnyChange &&
            data[fieldName].dontSendToApi !== true &&
            propName === "currentValue"
        ) {
            newSettings.isThereAnyChange = true;
        }

        /**
         * UNIQUE FUNCTIONS DEPENDING ON SUBMISSION TYPE
         */
        const toggleSaveIngredientButton = () => {
            const relatedFieldNames = [
                "ingredientName",
                "bops",
                "boss",
                "numerator",
                "denominator",
            ];
            if (!data.saveIngredientButton || relatedFieldNames.indexOf(fieldName) === -1) {
                return;
            } else if (errorText.length > 0) {
                newData.saveIngredientButton.buttonType = "deactive";
            } else {
                let newArr = relatedFieldNames.filter((item) => {
                    if (item !== fieldName) {
                        return data[item]?.errorText?.length !== 0;
                    } else {
                        return null;
                    }
                });

                if (newArr.length > 0) {
                    newData.saveIngredientButton.buttonType = "deactive";
                } else {
                    newData.saveIngredientButton.buttonType = "";
                }
            }
        };
        const toggleSavePackButton = () => {
            const relatedFieldNames = [
                "quantity",
                "legalCategory",
                "subpackInfo",
                "gtinCodes",
                "calendarPack",
                "hospitalPack",
                "limitedStability",
                "price",
            ];
            if (!data.savePackButton || relatedFieldNames.indexOf(fieldName) === -1) {
                return;
            } else if (errorText.length > 0) {
                newData.savePackButton.buttonType = "deactive";
            } else {
                let newArr = relatedFieldNames.filter((item) => {
                    if (item !== fieldName) {
                        const errorCount = data[item]?.errorText?.length || 0;
                        return errorCount !== 0;
                    } else {
                        return null;
                    }
                });

                if (newArr.length > 0) {
                    newData.savePackButton.buttonType = "deactive";
                } else {
                    newData.savePackButton.buttonType = "";
                }
            }
        };
        const relatedProductChanged = async () => {
            if (
                !data.relatedProduct ||
                fieldName !== "relatedProduct" ||
                propName !== "currentValue" ||
                areTheyEqual(newDataObj, data.relatedProduct.currentValue)
            ) {
                return;
            }

            let inComingValues = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: "callAMP_idSearch",
                token: userState.accessToken,
                apiNeeds: {
                    value: newDataObj.value,
                },
                setIsLoading: (boo) => {
                    setIsLoading(boo);
                },
            });
            newData = _.cloneDeep(convertNhsFormToCurrentValue(newData, inComingValues).data);
            newData = findAllErrors(newData);
            newSettings.internalState = 2;

            setIsLoading(false);
        };
        const discontinueProductChanged = () => {
            if (_.isEmpty(newData.relatedProduct?.currentValue)) {
                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = true;
                }
                return;
            }

            if (newData.discontinueProduct?.currentValue?.label === "Yes") {
                newSettings.internalState = 2;

                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = false;
                }
            } else if (newData.discontinueProduct?.currentValue?.label === "No") {
                newSettings.internalState = 3;

                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = true;
                }
            } else {
                newSettings.internalState = 2;

                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = true;
                }
            }

            newData = findAllErrors(newData);
        };
        const discontinuePackChanged = () => {
            if (
                _.isEmpty(newData.relatedProduct?.currentValue) ||
                _.isEmpty(newData.relatedPack?.currentValue)
            ) {
                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = true;
                    newData.discontinueQuantity.hideFromUI = true;
                }
                return;
            }

            if (newData.discontinuePack?.currentValue?.label === "Yes") {
                newSettings.internalState = 3;

                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = false;
                    newData.discontinueQuantity.hideFromUI = false;
                }
            } else if (newData.discontinuePack?.currentValue?.label === "No") {
                newSettings.internalState = 4;

                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = true;
                    newData.discontinueQuantity.hideFromUI = true;
                }
            } else {
                newSettings.internalState = 3;

                if (newData.discontinueReason) {
                    newData.discontinueReason.hideFromUI = true;
                    newData.discontinueQuantity.hideFromUI = true;
                }
            }

            newData = findAllErrors(newData);
        };

        const relatedProductChangedForUpdatePack = async () => {
            if (
                !data.relatedProduct ||
                fieldName !== "relatedProduct" ||
                propName !== "currentValue" ||
                areTheyEqual(newDataObj, data.relatedProduct.currentValue)
            ) {
                return;
            }

            let inComingValues = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: "callAMPP",
                token: userState.accessToken,
                apiNeeds: {
                    companyId: userState.selectedCompany?.id,
                    ampId: newDataObj.value,
                },
                setIsLoading: (boo) => {
                    setIsLoading(boo);
                },
            });

            let newPackObj = [];

            inComingValues.data.forEach((item) => {
                newPackObj.push({
                    value: item.id,
                    label: item.name,
                });
            });

            newData.relatedPack.optionValue = newPackObj;
            newData.relatedPack.currentValue = undefined;

            newSettings.internalState = 2;
            setIsLoading(false);
        };
        const relatedPackChanged = async () => {
            if (
                !data.relatedPack ||
                fieldName !== "relatedPack" ||
                propName !== "currentValue" ||
                areTheyEqual(newDataObj, data.relatedPack.currentValue)
            ) {
                return;
            }

            let inComingValues = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: "callAMPP_idSearch",
                token: userState.accessToken,
                apiNeeds: {
                    value: newDataObj.value,
                },
                setIsLoading: (boo) => {
                    setIsLoading(boo);
                },
            });

            let nhsConverter = convertNhsFormToCurrentValue(newData, inComingValues);

            newData = _.cloneDeep(nhsConverter.data);
            newSettings.inComingPack = _.cloneDeep(nhsConverter.onlyChanged);

            newData = findAllErrors(newData);
            newSettings.internalState = 3;
            setIsLoading(false);
        };
        const massPriceUpdateChanged = () => {
            const noOfUpdatedPrice = newData.prices.currentValue.filter(item => item.newPrice > 0).length;
            newSettings.isThereAnyChange = noOfUpdatedPrice > 0;
        };

        switch (currentSubmissionType) {
            case "NewProduct":
                toggleSaveIngredientButton();
                toggleSavePackButton();
                break;
            case "UpdateProduct":
                await relatedProductChanged();
                discontinueProductChanged();
                toggleSavePackButton();
                break;
            case "UpdatePack":
                await relatedProductChangedForUpdatePack();
                await relatedPackChanged();
                discontinuePackChanged();
                break;
            case "MassPriceUpdate":
                massPriceUpdateChanged();
                break;
            default:
                break;
        }

        setData(newData);
        setSettings(newSettings);
    };
    const silentUpdateData = (fieldName, newDataObj, propName = "currentValue") => {
        if (!data) {
            return;
        }

        let currentValue = data[fieldName][propName];
        if (!areTheyEqual(currentValue, newDataObj)) {
            setData({
                ...data,
                [fieldName]: {
                    ...data[fieldName],
                    [propName]: newDataObj,
                },
            });
        }
    };

    /**
     * UPDATE HEADER TITLE
     */
    useLayoutEffect(() => {
        if (!data || !settings) {
            return;
        }

        let newHeaderTitle = "";

        if (settings.submissionType === "NewProduct" && data.name?.currentValue) {
            newHeaderTitle = data.name.currentValue;
        } else if (
            (settings.submissionType === "NewPack" || settings.submissionType === "UpdatePack") &&
            data.relatedProduct?.currentValue?.label &&
            data.quantity?.currentValue?.unit?.label
        ) {
            newHeaderTitle =
                data.relatedProduct.currentValue.label +
                " " +
                data.quantity.currentValue.input +
                " " +
                data.quantity.currentValue.unit.label;
        } else if (
            settings.submissionType === "UpdateProduct" &&
            data.relatedProduct?.currentValue?.label
        ) {
            newHeaderTitle = data.relatedProduct.currentValue.label;
        } else if (
            settings.submissionType === "MassPriceUpdate" &&
            data.prices?.currentValue?.length
        ) {
            const prices = data.prices?.currentValue || [];
            const count = prices.filter((item) => item.newPrice !== undefined && item.newPrice > 0).length;

            newHeaderTitle = `${count} Prices Updated`;
        } else {
            newHeaderTitle = settings.name;
        }

        if (setHeaderTitles.productName !== newHeaderTitle) {
            setHeaderTitles((prev) => ({
                ...prev,
                productName: newHeaderTitle,
            }));
        }
    }, [data, settings?.submissionType]);

    /**
     * DEFINE SECTIONS for ANCHOR POINTS
     */
    useLayoutEffect(() => {
        if (!data) {
            return;
        }
        let newSections = [];

        Object.keys(data).forEach((item) => {
            if (data[item].isASection) {
                newSections.push(item);
            }
        });
        setSections(newSections);
    }, [data]);

    /**
     * SCROLL TO ERROR FIELD
     */
    const errorFieldNameClicked = (fieldName) => {
        let element = document.getElementById(fieldName);
        window.scrollTo(0, element.offsetTop + 75);
    };

    /**
     *  ON EXTERNAL CLICKS CALLBACK
     */
    const onExternalClick = (callBack, additionalData) => {
        if (callBack) {
            callBack(
                {
                    data: data,
                    setData: setData,
                    updateData: updateData,
                    silentUpdateData: silentUpdateData,
                    settings: settings,
                    setSettings: setSettings,
                    findError: findError,
                },
                additionalData,
            );
        }
    };

    /**
     * USE CONSOL to SEE DATA or SETTINGS for DEVELOPMENT
     */
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || showErrorLogsOnConsole) {
        window.data = data;
        window.settings = settings;
    }

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (!data || !settings || isLoading) {
        return null;
    }

    return (
        <div className="formArea">
            {/* SUMMARY SECTION FOR VIEW and EDIT ACTIONS */}
            {settings.action === "view" ? (
                <React.Fragment>
                    <Seperator
                        viewMode={settings.action}
                        data={{
                            placeHolder: "Summary",
                        }}
                    />                    
                    <SummarySection savedData={settings.savedData} />     
              
                </React.Fragment>
            ) : null}
             {/* Displaying the Freeform Message */}
             {(settings.status === "Confirmed" || settings.status === "Rejected") ? ( 
                <React.Fragment>
                    <Seperator
                        viewMode={settings.action}
                        data={{
                            placeHolder: "Messages",
                        }}
                    />     
                    <FreeForm savedData={settings.savedData} />
                </React.Fragment>
            ) : null}

            {/* NHSs MESSAGE FOR FURTHER STEPS, WILL BE PLACED HERE */}

            {/* PRINT ALL FORM FIELDS  */}
            {!data
                ? null
                : Object.entries(data).map(([key, item]) => {
                      if (
                          item.hideFromUI ||
                          (settings.action !== "view" &&
                              (item.internalState || 1) > (settings?.internalState || 1))
                      ) {
                          return null;
                      }
                      return (
                          <item.componentType
                              key={key}
                              keyName={key}
                              data={data[key]}
                              viewMode={settings.action}
                              accessToken={userState.accessToken}
                              updateData={updateData}
                              silentUpdateData={silentUpdateData}
                              text={data[key].text}
                              buttonType={data[key].buttonType}
                              onClick={() => {
                                  onExternalClick(data[key].onClick);
                              }}
                              removeButtonForIngsAndPacks={(additionalData) => {
                                  onExternalClick(
                                      data[key].removeButtonForIngsAndPacks,
                                      additionalData,
                                  );
                              }}
                              onChange={(additionalData) => { 
                                  onExternalClick(data[key].onChange, additionalData); 
                              }}
                          />
                      );
                  })}

            {/* USER ACTIONS  */}
            <UserActions
                areTheyEqual={areTheyEqual}
                sections={sections}
                settings={settings}
                data={data}
                setData={setData}
                setSettings={setSettings}
                updateData={updateData}
                silentUpdateData={silentUpdateData}
                errorFieldNameClicked={(error) => {
                    errorFieldNameClicked(error);
                }}
                convertCurrentValueToSavedValue={convertCurrentValueToSavedValue}
            />

            <div style={{ marginBottom: 100 }}></div>
        </div>
    );
};
