import React from "react";
import useEventListener from "@use-it/event-listener";

import { Button } from "../Button/Button.js";
import "./ApprovalZone.scss";

export const ApprovalZone = (props) => {
    const {
        onConfirm,
        onDecline,
        headerText,
        redText,
        onConfirmText,
        onDeclineText,
        areYouSureText,
    } = props;

    /**
     * EVENT LISTENER FOR ENTER or ESC
     * @description onConfirm when Enter pressed - onDecline when Esc pressed
     */
    useEventListener("keydown", (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            onConfirm();
        } else if (e.keyCode === 27) {
            e.preventDefault();
            onDecline();
        }

        return;
    });

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (!onConfirm || !onDecline || !headerText || !redText) {
        return null;
    }
    return (
        <div className="approvalZone">
            <div className="approvalBox">
                <div className="headerText">{headerText}</div>
                <div style={{ color: "red" }}>{redText}</div>
                <div>{areYouSureText ? areYouSureText : "Are you sure?"}</div>
                <div>
                    <Button
                        buttonType="green half"
                        text={onConfirmText ? onConfirmText : "CONFIRM"}
                        onClick={() => {
                            onConfirm();
                        }}
                    />
                    <Button
                        buttonType="red half"
                        text={onDeclineText ? onDeclineText : "DECLINE"}
                        onClick={() => {
                            onDecline();
                        }}
                        additionalStyle={{ marginRight: 0 }}
                    />
                </div>
            </div>
        </div>
    );
};
