import React from "react";
import { useLayoutEffect, useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";

export const About = (props) => {
    const thisPageName = "aboutPage";
    const {
        pageName,
        setPageName,
        setSidebarBehavior,
        disableIsLoadingWithDelay,
        setIsLoading,
    } = useContext(GlobalContext);

    /**
     * SET PAGE NAME and SIDEBAR BEHAVIOUR IN MOUNT AND REMOVE ISLOADING
     * @description sets pageName and sidebarBehaviour in globalStore
     */
    useLayoutEffect(() => {
        setIsLoading(true);
        setPageName(thisPageName);
        setSidebarBehavior({ position: "narrow", content: "about" });
        disableIsLoadingWithDelay();
    }, [pageName]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <div className="formArea">
            <div className="form_seperator">About emc in demand</div>

            <div className="form_descText">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. In dictum non consectetur a erat nam at lectus urna.
                    Molestie a iaculis at erat pellentesque adipiscing commodo
                    elit at. Rhoncus mattis rhoncus urna neque viverra justo nec
                    ultrices. Pellentesque eu tincidunt tortor aliquam nulla.
                    Morbi leo urna molestie at elementum. Quis hendrerit dolor
                    magna eget est lorem.
                </p>

                <p>
                    Hac habitasse platea dictumst quisque sagittis purus sit
                    amet. Lectus magna fringilla urna porttitor rhoncus dolor
                    purus. Orci a scelerisque purus semper eget. Velit egestas
                    dui id ornare arcu. Venenatis urna cursus eget nunc
                    scelerisque viverra mauris in. Sodales neque sodales ut
                    etiam sit amet nisl. Vitae semper quis lectus nulla at
                    volutpat diam ut. Facilisi etiam dignissim diam quis enim
                    lobortis scelerisque fermentum dui. In hendrerit gravida
                    rutrum quisque non. Est ullamcorper eget nulla facilisi
                    etiam. Massa tincidunt dui ut ornare lectus sit amet est
                    placerat.
                </p>
            </div>

            <div className="form_seperator">Legal</div>

            <div className="form_descText">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. In dictum non consectetur a erat nam at lectus urna.
                    Molestie a iaculis at erat pellentesque adipiscing commodo
                    elit at. Rhoncus mattis rhoncus urna neque viverra justo nec
                    ultrices. Pellentesque eu tincidunt tortor aliquam nulla.
                    Morbi leo urna molestie at elementum. Quis hendrerit dolor
                    magna eget est lorem.
                </p>

                <p>
                    Hac habitasse platea dictumst quisque sagittis purus sit
                    amet. Lectus magna fringilla urna porttitor rhoncus dolor
                    purus. Orci a scelerisque purus semper eget. Velit egestas
                    dui id ornare arcu. Venenatis urna cursus eget nunc
                    scelerisque viverra mauris in. Sodales neque sodales ut
                    etiam sit amet nisl. Vitae semper quis lectus nulla at
                    volutpat diam ut. Facilisi etiam dignissim diam quis enim
                    lobortis scelerisque fermentum dui. In hendrerit gravida
                    rutrum quisque non. Est ullamcorper eget nulla facilisi
                    etiam. Massa tincidunt dui ut ornare lectus sit amet est
                    placerat.
                </p>
            </div>
        </div>
    );
};
