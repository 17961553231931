import React, { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";

import { Link } from "react-router-dom";
import "./Card.scss";

export const Card = (props) => {
    const {
        hideStatus,
        id,
        type,
        status,
        medName,
        dateOn,
        dateBy,
        nhsMsg,
        //submissionId,
        additionalStyle,
    } = props;
    const { statusLib, addSpaces } = useContext(GlobalContext);
    const statusCSS = () => {
        switch (status) {
            case statusLib.DRA.co:
            case statusLib.DRA.scr:
                return "draft";
            case statusLib.RFA.co:
            case statusLib.RFA.scr:
                return "ready";
            case statusLib.NHS.co:
            case statusLib.NHS.scr:
                return "withNHS";
            case statusLib.CON.co:
            case statusLib.CON.scr:
                return "confirmed";
            case statusLib.REJ.co:
            case statusLib.REJ.scr:
                return "rejected";
            case statusLib.CAN.co:
            case statusLib.CAN.scr:
                return "canceled";
            case statusLib.PENSUB.co:
            case statusLib.PENSUB.scr:
                return "pending";
            case statusLib.PENCAN.co:
            case statusLib.PENCAN.scr:
                return "pending";
            default:
                return "draft";
        }
    };

    return (
        <Link to={"/view/" + id} className="cardLink">
            <div className={"card " + statusCSS() + (hideStatus ? " noStatus" : "")}
                 style={additionalStyle}>
                <div className="card_header">
                    <svg
                        className="card_arrow"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 6 10"
                        space="preserve"
                    >
                        <path d="M4.1,5L0.5,8.6c-0.3,0.3-0.3,0.7,0,1s0.7,0.3,1,0c0,0,0,0,0,0l4.1-4.1 c0.3-0.3,0.3-0.7,0-0.9L1.4,0.4c-0.3-0.3-0.7-0.3-1,0s-0.3,0.7,0,1c0,0,0,0,0,0L4.1,5z" />
                    </svg>
                    <div className="card_status">{status}</div>

                    <React.Fragment>
                        <div className="card_type">{addSpaces(type)}</div>
                        <div className="card_name">{medName}</div>
                    </React.Fragment>
                </div>
                {status === statusLib.DRA.co ||
                status === statusLib.DRA.scr ||
                status === statusLib.RFA.co ||
                status === statusLib.RFA.scr ||
                status === statusLib.CAN.co ||
                status === statusLib.CAN.scr ? (
                    <div className="card_desc">
                        Last Updated on {dateOn}
                        <br />
                        by {dateBy}
                    </div>
                ) : status === statusLib.NHS.co ||
                  status === statusLib.NHS.scr ||
                  status === statusLib.PENSUB.co ||
                  status === statusLib.PENSUB.scr ||
                  status === statusLib.PENCAN.co ||
                  status === statusLib.PENCAN.scr ? (
                    <div className="card_desc">
                        Authorised on {dateOn}
                        <br />
                        by {dateBy}
                    </div>
                ) : status === statusLib.CON.co ||
                  status === statusLib.REJ.co ||
                  status === statusLib.CON.scr ||
                  status === statusLib.REJ.scr ? (
                    <div className="card_desc">{nhsMsg}</div>
                ) : null}
            </div>
        </Link>
    );
};
