import React, { useContext, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/GlobalStore.js";
import { ClientContext } from "contexts/ClientStore.js";
import { UserContext } from "contexts/UserStore.js";
import { FetchAPI } from "components/APIConnections/APIConnections.js";
// import { SearchBarForHome } from "components/SearchBarForHome/SearchBarForHome.js";
import { CardSliderForHome } from "components/CardSliderForHome/CardSliderForHome.js";

import "./Home.scss";

export const Home = () => {
    const {
        pageName,
        setPageName,
        setSidebarBehavior,
        isLoading,
        setIsLoading,
        disableIsLoadingWithDelay,
        statusLib,
        showErrorLogsOnConsole,
    } = useContext(GlobalContext);
    const { userState } = useContext(UserContext);
    const { CS } = useContext(ClientContext);

    const thisPageName = "homePage";
    const [draftData, setDraftData] = useState();
    const [rfaData, setRfaData] = useState();
    const [nhsData, setNhsData] = useState();
    const [confirmedData, setConfirmedData] = useState();
    const [rejectedData, setRejectedData] = useState();
    const [cancelledData, setCancelledData] = useState();
    const [penSubData, setPenSubData] = useState();

    const sliderNames = [
        {
            status: statusLib.DRA.co,
            title: statusLib.DRA.display,
            dataRoute: draftData,
            hideIfEmpty: true,
        },
        {
            status: statusLib.RFA.co,
            title: statusLib.RFA.scr,
            dataRoute: rfaData,
            hideIfEmpty: true,
        },
        {
            status: statusLib.PENSUB.co,
            title: statusLib.PENSUB.display,
            dataRoute: penSubData,
            hideIfEmpty: true,
        },
        {
            status: statusLib.NHS.co,
            title: statusLib.NHS.display,
            dataRoute: nhsData,
            hideIfEmpty: true,
        },
        {
            status: statusLib.CON.co,
            title: statusLib.CON.display,
            dataRoute: confirmedData,
            hideIfEmpty: true,
        },
        {
            status: statusLib.REJ.co,
            title: statusLib.REJ.display,
            dataRoute: rejectedData,
            hideIfEmpty: true,
        },
        {
            status: statusLib.CAN.co,
            title: statusLib.CAN.display,
            dataRoute: cancelledData,
            hideIfEmpty: true,
        },
    ];
    let history = useHistory();
    const [order, setOrder] = useState();

    /**
     * CALCULATE ORDER
     * @description depending on screen size, calculate how many card will shown.
     */

    useLayoutEffect(() => {
        const calculateOrder = (WINW) => {
            let returnValue = WINW > 1600 ? 10 : WINW > 1200 ? 8 : WINW > 700 ? 6 : 2;
            return returnValue;
        };

        setOrder(calculateOrder(CS.WINW));
    }, [CS.WINW]);

    /**
     * SET PAGE NAME and SIDEBAR BEHAVIOUR
     * @description sets pageName and sidebarBehaviour in globalStore
     */
    useLayoutEffect(() => {
        setIsLoading(true);
        setPageName(thisPageName);
        setSidebarBehavior({ position: "landing", content: "none" });
    }, [pageName]);

    /**
     * GET DATA VIA API and PLACE IT.
     * THEN REMOVE LOADING PAGE.
     */
    useLayoutEffect(() => {
        async function fetchData() {
            let newData = await FetchAPI({
                showErrorLogsOnConsole: showErrorLogsOnConsole,
                history: history,
                apiShortName: "callDataForHomePage",
                token: userState.accessToken,
                apiNeeds: {
                    order: order,
                    companyId: userState.selectedCompany?.id,
                },
                setIsLoading: (boo) => {
                    setIsLoading(boo);
                },
            });

            if (!newData) {
                return;
            }

            setDraftData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.DRA.scr;
                      })
                    : [],
            );
            setRfaData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.RFA.scr;
                      })
                    : [],
            );
            setPenSubData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.PENSUB.scr;
                      })
                    : [],
            );
            setNhsData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.NHS.scr;
                      })
                    : [],
            );
            setConfirmedData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.CON.scr;
                      })
                    : [],
            );
            setRejectedData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.REJ.scr;
                      })
                    : [],
            );
            setCancelledData(
                newData
                    ? newData.data.filter((item) => {
                          return item.status === statusLib.CAN.scr;
                      })
                    : [],
            );
        }
        fetchData();
    }, [userState]);

    useLayoutEffect(() => {
        if (
            isLoading &&
            draftData &&
            rfaData &&
            nhsData &&
            confirmedData &&
            rejectedData &&
            cancelledData
        ) {
            disableIsLoadingWithDelay();
        }
    }, [draftData, rfaData, nhsData, confirmedData, rejectedData, cancelledData]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (
        !draftData ||
        !rfaData ||
        !nhsData ||
        !confirmedData ||
        !rejectedData ||
        !cancelledData ||
        !penSubData
    ) {
        return null;
    }
    if (
        draftData.length +
            rfaData.length +
            nhsData.length +
            confirmedData.length +
            rejectedData.length +
            cancelledData.length +
            penSubData.length ===
        0
    ) {
        return (
            <div style={{ minHeight: "85vh", display: "flex" }}>
                <div style={{ alignSelf: "center", maxWidth: 500 }}>
                    <b>Welcome</b> to emc med data dm+d.
                    <br />
                    <p>
                        Welcome to emc med data dm+d. You haven't yet made any submissions to the NHS
                        BSA. Once started, your submissions will appear here. Please Create a New
                        Submission using the menu option
                    </p>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            {/* SEARCH BAR NOT IMPLEMENTED IN THIS VERSION - REMOVE - packer in to make ui look ok*/}
            {/* <div className="home_seperator">Search a Submission</div>
            <SearchBarForHome /> */}
            <div style={{ height: 116 }} />

            {/* CALL CARD SLIDERS */}
            {!sliderNames
                ? null
                : sliderNames.map((item, key) => {
                      if (!item.dataRoute) {
                          return null;
                      }

                      if (item.hideIfEmpty && item.dataRoute.length === 0) {
                          return null;
                      }
                      return (
                          <CardSliderForHome
                              key={key}
                              title={item.title}
                              status={item.status}
                              data={item.dataRoute}
                              order={order}
                          />
                      );
                  })}
        </React.Fragment>
    );
};
