import "babel-polyfill";
import "core-js/features/url-search-params";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import GlobalStore from "./contexts/GlobalStore.js";
import ClientStore from "./contexts/ClientStore.js";
import UserStore from "./contexts/UserStore.js";
import { App } from "./App";
import FeatureFlagsStore from "contexts/FeatureContext";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './Telemetry';

ReactDOM.render(
	<AppInsightsContext.Provider value={reactPlugin}>
		<Router basename={process.env.PUBLIC_URL}>
			<GlobalStore>
				<ClientStore>
					<FeatureFlagsStore>
						<UserStore>
							<App />
						</UserStore>
					</FeatureFlagsStore>
				</ClientStore>
			</GlobalStore>
		</Router>
	</AppInsightsContext.Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
