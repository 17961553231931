import React, { useRef, useState, useContext, useLayoutEffect } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import useDebounce from "./useDebounce.js";

export const TextInput = (props) => {
    const { data, keyName, updateData, additionalStyle, setIsFocused2, viewMode } = props;
    const {
        placeHolder,
        fieldName,
        currentValue,
        informationText,
        errorText,
        validationRules,
        alwaysNonEditable,
    } = data;
    const { turnArrayToLines, isNotEmpty } = useContext(GlobalContext);

    const [localCurrentValue, setLocalCurrentValue] = useState(currentValue);
    const [firstFocus, setFirstFocus] = useState(false);
    const inputZone = useRef();
    const debouncedSearchTerm = useDebounce(localCurrentValue, 250);

    /**
     * UPDATE DATA IN PARENT
     * @description sends local variables to parent with lodash
     */
    useLayoutEffect(() => {
        if (!firstFocus) {
            return;
        }

        if (debouncedSearchTerm) {
            updateData(keyName, localCurrentValue);
        } else {
            updateData(keyName, "");
        }
    }, [debouncedSearchTerm]);

    /**
     * UPDATE LOCAL CURRENT VALUE, IF AN EXTERNAL TOUCH ARRIVES
     */
    useLayoutEffect(() => {
        if (!data) {
            return;
        }
        if (currentValue !== localCurrentValue) {
            setLocalCurrentValue(currentValue);
        }
    }, [currentValue]);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (data.dontSendToApi && viewMode === "view") {
        return null;
    } else if (viewMode === "view" || alwaysNonEditable) {
        return (
            <div className="view_single">
                <div>{fieldName}</div>
                <div>
                    <span>{currentValue}</span>
                </div>
            </div>
        );
    }

    return (
        <div
            id={keyName}
            className={"form_textInput " + (errorText?.length > 0 ? "error" : "")}
            style={additionalStyle}
        >
            {fieldName ? (
                <label
                    className={
                        "form_label" +
                        ((validationRules?.length > 0 && !isNotEmpty(currentValue)) ||
                        errorText?.length > 0
                            ? " onLoadControlError"
                            : "")
                    }
                >
                    {fieldName}
                </label>
            ) : null}
            <input
                ref={inputZone}
                spellCheck="false"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder={placeHolder}
                value={localCurrentValue || ""}
                onBlur={() => {
                    if (setIsFocused2) {
                        setIsFocused2(false);
                    }
                }}
                onFocus={(e) => {
                    if (setIsFocused2) {
                        setIsFocused2(true);
                    }
                    if (!firstFocus) {
                        setFirstFocus(true);
                    }
                }}
                onChange={(e) => {
                    setLocalCurrentValue(e.target.value);
                    // updateData(keyName, );
                }}
                onKeyUp={(event) => {
                    if (event.keyCode === 27) {
                        inputZone.current.blur();
                    }
                }}
            />
            {informationText ? <div className="form_infoText">{informationText}</div> : null}
            {firstFocus && errorText?.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
        </div>
    );
};
