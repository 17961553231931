import React from "react";
import "./BackEmblem.scss";

export const BackEmblem = (props) => {
    const { svgClass } = props;

    return (
        <svg
            className={"backEmblem " + svgClass}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 450"
            space="preserve"
        >
            <path d="M432.9,104.5c0,78.8-64.8,143.6-143.6,143.6H145.6V133.4c0.2-8.2,6.8-14.8,15-15h101.7 c22.3,0.2,40.7-17.7,40.9-40c0-0.3,0-0.6,0-0.9V0h-27.9v77.5c-0.1,7.1-5.9,12.8-13,13H160.5c-23.5-0.2-42.7,18.7-42.9,42.3 c0,0.2,0,0.4,0,0.6l0,0v114.7H1.9c-0.7,0-1.3-0.1-1.9-0.2V276c0,0,0,0,0,0c0.3,0,0.6,0,0.9,0h115.7C110.5,339.3,62.5,391.7,0,403.3 v27.5c78.1-11.5,139.2-76.3,145.6-155.8h143.6c94.8,0,171.6-76.8,171.6-171.6c0,0,0,0,0,0C460.3,64.7,447,28.7,424.7,0h-37.4 C415.4,26.3,432.9,63.5,432.9,104.5z" />
        </svg>
    );
};
