import React, { useLayoutEffect, useState } from "react";
import { useContext } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import { SelectBox } from "../SelectBox/SelectBox.js";

export const DateInput = (props) => {
    const { data, keyName, updateData, silentUpdateData, viewMode } = props;
    const { fieldName, currentValue, informationText, errorText, optionValue } = data;
    const { turnArrayToLines } = useContext(GlobalContext);

    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    const [isFocused, setIsFocused] = useState(false);
    const [firstFocus, setFirstFocus] = useState(false);

    /**
     * TOGGLE FIRST FOCUS
     */
    const toggleFirstFocus = () => {
        if (!firstFocus) {
            setFirstFocus(true);
        }
    };

    /**
     * PREPARE DAY - MONTH - YEARS
     * @description depending on month and year, day options are re-populate.
     * @description if an invalid day selected (ex 30th of Feb), set day null.
     */
    useLayoutEffect(() => {
        let newOptionValue = {
            month: optionValue.month,
            year: optionValue.year,
        };

        let newDays = [];
        let howManyDays = 31;
        if (currentValue?.month?.value) {
            switch (currentValue.month.value) {
                case "2":
                    if (parseInt(currentValue?.year?.value) % 4 === 0) {
                        howManyDays = 29;
                    } else {
                        howManyDays = 28;
                    }
                    break;
                case "4":
                case "6":
                case "9":
                case "11":
                    howManyDays = 30;
                    break;
                default:
            }
        }
        for (let i = 1; i < howManyDays + 1; i++) {
            newDays.push({ label: i + "", value: i + "" });
        }
        newOptionValue.day = newDays;
        silentUpdateData(keyName, newOptionValue, "optionValue");
        if (currentValue?.day?.value > howManyDays) {
            updateData(keyName, { ...currentValue, day: null });
        }
    }, [currentValue]);

    /**
     * CALL TODAY
     * @description updates store depending on changes via fields.
     */
    const callToday = () => {
        setFirstFocus(true);
        updateData(keyName, {
            day: { value: todayDay + "", label: todayDay + "" },
            month: {
                value: todayMonth + 1 + "",
                label: optionValue.month[todayMonth].label,
            },
            year: { value: todayYear + "", label: todayYear + "" },
        });
    };
    /**
     * UPDATE DATA_LOCAL
     */
    const updateData_local = (fieldName, newDataObj, propName = "currentValue") => {
        if (fieldName === "day") {
            updateData(keyName, {
                ...currentValue,
                day: newDataObj,
            });
        } else if (fieldName === "month") {
            updateData(keyName, {
                ...currentValue,
                month: newDataObj,
            });
        } else if (fieldName === "year") {
            updateData(keyName, {
                ...currentValue,
                year: newDataObj,
            });
        }
    };

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    if (viewMode === "view") {
        return (
            <div className="view_single">
                <div>{fieldName}</div>
                <div>
                    <span>
                        {currentValue
                            ? currentValue.day.label +
                              "." +
                              currentValue.month.label +
                              "." +
                              currentValue.year.label
                            : ""}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div
            id={keyName}
            className={"form_dateSelect " + (errorText && errorText.length > 0 ? "error" : "")}
        >
            {fieldName ? (
                <label
                    className={
                        "form_label" +
                        (errorText && errorText.length > 0 ? " onLoadControlError" : "")
                    }
                >
                    {fieldName}
                </label>
            ) : null}

            <div className="form_dateSelectContainer">
                <SelectBox
                    keyName={"day"}
                    updateData={updateData_local}
                    data={{
                        placeHolder: "Day",
                        savedValue: "",
                        optionValue: optionValue?.day ? optionValue.day : null,
                        currentValue: currentValue?.day ? currentValue.day : null,
                    }}
                    additionalStyle={{ marginBottom: 10 }}
                    setIsFocused2={(boo) => {
                        setIsFocused(boo);
                        if (boo) {
                            toggleFirstFocus();
                        }
                    }}
                />
                <SelectBox
                    keyName={"month"}
                    updateData={updateData_local}
                    data={{
                        placeHolder: "Month",
                        savedValue: "",
                        optionValue: optionValue?.month ? optionValue.month : null,
                        currentValue: currentValue?.month ? currentValue.month : null,
                    }}
                    additionalStyle={{ marginBottom: 10 }}
                    setIsFocused2={(boo) => {
                        setIsFocused(boo);
                        if (boo) {
                            toggleFirstFocus();
                        }
                    }}
                />
                <SelectBox
                    keyName={"year"}
                    updateData={updateData_local}
                    data={{
                        placeHolder: "Year",
                        savedValue: "",
                        optionValue: optionValue?.year ? optionValue.year : null,
                        currentValue: currentValue?.year ? currentValue.year : null,
                    }}
                    additionalStyle={{ marginBottom: 10 }}
                    setIsFocused2={(boo) => {
                        setIsFocused(boo);
                        if (boo) {
                            toggleFirstFocus();
                        }
                    }}
                />
            </div>

            {informationText ? (
                <div className={isFocused ? "form_infoText_on" : "form_infoText"}>
                    {informationText}
                </div>
            ) : null}
            {firstFocus && errorText && errorText.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
            <span
                className="font_subLink"
                style={{ marginLeft: 20 }}
                onClick={() => {
                    callToday();
                }}
            >
                Today
            </span>
        </div>
    );
};
