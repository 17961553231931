import React, { useState } from "react";

const NOT_A_DIGIT_AND_ZERO_FIRST = /\D|^0/g;
const NOTHING = "";

export const DataGridEdit = (props) => {
    const { id, value, onChange } = props;

    const [text, setText] = useState(value || "");

    const handelChange = (event) => {
        const newText = event.target.value.replace(NOT_A_DIGIT_AND_ZERO_FIRST, NOTHING)

        setText(newText);

        if (onChange) onChange(id, newText);
    };

    return <input type="text" value={text} onChange={handelChange} />;
};
