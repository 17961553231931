import React from "react";

export const Description = (props) => {
    const { data, viewMode } = props;
    const { placeHolder } = data;

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (viewMode === "view") {
        return null;
    }

    return <div className="form_descText">{placeHolder}</div>;
};
