import React, { createContext, useState, useCallback, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export const GlobalContext = createContext();
const GlobalStore = (props) => {
    // Redundant?  Removed spaces so convertsion is identical in most cases
    const statusLib = {
        DRA: { scr: "Draft", co: "draft", display: "Draft" },
        RFA: {
            scr: "ReadyForAuthorisation",
            co: "ReadyForAuthorisation",
            display: "Ready For Authorisation",
        },
        NHS: { scr: "WithNhsBsa", co: "WithNhsBsa", display: "With NHS BSA" },
        CON: { scr: "Confirmed", co: "Confirmed", display: "Confirmed" },
        REJ: { scr: "Rejected", co: "Rejected", display: "Rejected" },
        CAN: { scr: "Cancelled", co: "Cancelled", display: "Cancelled" },
        PENSUB: {
            scr: "PendingSubmission",
            co: "PendingSubmission",
            display: "Pending Submission",
        },
        PENCAN: {
            scr: "PendingCancellation",
            co: "PendingCancellation",
            display: "Pending Cancellation",
        },
    };
    const history = useHistory();
    const [pageName, setPageName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [headerTitles, setHeaderTitles] = useState({
        id: null,
        submissionType: null,
        productName: null,
        status: null,
        submissionId: null,
        currentAction: null,
    });
    const [sidebarBehavior, setSidebarBehavior] = useState({
        position: "mobile", // can be "landing" "narrow" "mobile"
        content: "hide", // can be none, createNew, hide, selectStatus, selectType, userSettings, contact
    });
    const [globalThrottleTime, setGlobalThrottleTime] = useState(150);
    const [globalAlert, setGlobalAlert] = useState();

    const showErrorLogsOnConsole = true;

    /**
     * CLEAR HEADER TITLE
     * @description sets Header title & productname null
     */
    const clearHeaderTitle = () => {
        setHeaderTitles({
            id: null,
            submissionType: null,
            productName: null,
            status: null,
            submissionId: null,
            currentAction: null,
        });
    };

    /**
     * REMOVE SPACES - ADD SPACES - START ALL CAPITILISED
     * @description make some changes on string
     * @param {string} text
     * @returns {string} newText
     */
    const removeSpaces = (text) => {
        if (!text) {
            return;
        }
        text = text.trim();
        let newText = text
            .replace(/\w+/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1);
            })
            .replace(/\s/g, "");

        return newText;
    };
    const addSpaces = (text) => {
        if (!text) {
            return;
        }
        text = text.trim();
        let newText = text.replace(/([A-Z])/g, " $1").trim();

        return newText;
    };
    const startAllCapitilised = (text) => {
        if (!text) {
            return;
        }
        text = text.trim();

        let newText = text.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

        return newText;
    };

    /**
     * TURN ARRAY TO LINES
     * @description converts array to "• item<br/>• item"
     * @param {array} array
     * @returns {string} newText
     */
    const turnArrayToLines = (array) => {
        if (!array || array.length === 0) {
            return;
        }
        let newText = array.map((item, i) => {
            return <span key={i}>• {item}</span>;
        });
        return newText;
    };

    /**
     * TYPE IDENTIFIER
     * @description an advanced version of typeOf
     * @param {*} data
     * @returns {string} "string" || "number" || "boolean" || "function" || "array" || "object" || "undefined"
     */
    const typeIdentifier = (data) => {
        let typeofData = typeof data;

        if (typeofData === "string") {
            return "string";
        } else if (typeofData === "number") {
            return "number";
        } else if (typeofData === "boolean") {
            return "boolean";
        } else if (typeofData === "function") {
            return "function";
        } else if (typeofData === "object") {
            if (Array.isArray(data)) {
                return "array";
            } else {
                return "object";
            }
        } else {
            return "undefined";
        }
    };

    /**
     * PENNY TO POUND
     * @description converts a number to pound.
     * @param {number} value
     */
    const pennyToPound = (value) => {
        return (Number(value) / 100).toFixed(2);
    };

    /**
     * GO TO HOME
     * @description pushs "/" to history
     */
    const goToHome = () => {
        if (pageName !== "homePage") {
            history.push("/");
        }
    };

    /**
     * REMOVE AN ITEM FROM AN ARRAY
     * @description indexOf + splice. For removing an item from an array easily.
     * @param {array} array
     * @param {*} item
     */
    const removeAnItemFromAnArray = (array, item) => {
        const index = array.indexOf(item);
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    };

    /**
     * IS NOT EMPTY
     * @description this function checks if data is empty or null or undefined or .length=0 or keys=0
     * @return false if {data} is empty or null or undefined or .length=0 or keys=0
     * @param {*} data
     */
    const isNotEmpty = (data) => {
        if (!data) {
            return false;
        }
        let type = typeIdentifier(data);

        if (type === "number" || type === "boolean") {
            return true;
        } else if (type === "string" || type === "array") {
            if (data.length > 0) {
                return true;
            } else {
                return false;
            }
        } else if (type === "object") {
            let keys = Object.keys(data);
            if (keys.length > 0) {
                return true;
            } else {
                return false;
            }
        }
    };

    /**
     * SETISLOADING TRUE WHEN PAGE NAME CHANGED
     */
    useLayoutEffect(() => {
        setIsLoading(true);
    }, [pageName]);

    /**
     * DISABLE IS LOADING WITH DELAY
     * @description covers CSS transition faults.
     */
    const disableIsLoadingWithDelay = useCallback(
        _.debounce(async (obj) => {
            setIsLoading(false);
        }, 500),
        [],
    );

    /**
     * CONVERT DATE to NORMAL
     * 2020-10-07T17:57:57.5131142 -> 07.Oct.2020
     */
    const convertDateForScreen = (date) => {
        let newDate = date.substring(0, 10).split("-");
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        return newDate[2] + "." + months[newDate[1] - 1] + "." + newDate[0];
    };

    /**
     * HANDLE SUBMISSIONS + HEADER FUNCTIONS
     */
    const [callSaveAndContinue, setCallSaveAndContinue] = useState(false);
    const [callPreSection, setCallPreSection] = useState(false);
    const [callNextSection, setCallNextSection] = useState(false);

    /**
     * TRIGGER GLOBAL ALERT
     * @description <obj> has to a <text> and <status> tags.
     * triggerGlobalAlert({text:"",status:"warn"});
     */
    const triggerGlobalAlert = (obj) => {
        setGlobalAlert({
            text: obj.text,
            status: obj.status,
        });
        closeGlobalAlertWithDelay();
    };

    const closeGlobalAlertWithDelay = useCallback(
        _.debounce(async () => {
            setGlobalAlert();
        }, 3000),
        [],
    );

    /**
     * ADD NEW FEATURES TO OBJECTs' - ARRAYs' PROTOTYPES
     * Global Variables
     */
    useLayoutEffect(() => {
        // Compares if two arrays are equal
        // Usage -> array1._isEqualTo(array2)
        // Returns boolean
        // eslint-disable-next-line no-extend-native
        Array.prototype._isEqualTo =
            Array.prototype._isEqualTo ||
            function (array) {
                if (!array) {
                    return false;
                }
                if (this.length !== array.length) {
                    return false;
                }

                for (var i = 0, l = this.length; i < l; i++) {
                    if (this[i] instanceof Array && array[i] instanceof Array) {
                        if (!this[i].equals(array[i])) {
                            return false;
                        }
                    } else if (this[i] !== array[i]) {
                        return false;
                    }
                }
                return true;
            };
    }, []);

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------
    return (
        <GlobalContext.Provider
            value={{
                // datasets
                statusLib,
                pageName,
                setPageName,
                headerTitles,
                setHeaderTitles,
                sidebarBehavior,
                setSidebarBehavior,
                globalThrottleTime,
                setGlobalThrottleTime,
                isLoading,
                setIsLoading,
                disableIsLoadingWithDelay,

                callSaveAndContinue,
                setCallSaveAndContinue,
                callPreSection,
                setCallPreSection,
                callNextSection,
                setCallNextSection,
                globalAlert,
                setGlobalAlert,
                triggerGlobalAlert,
                showErrorLogsOnConsole,

                // functions
                typeIdentifier,
                pennyToPound,
                goToHome,
                clearHeaderTitle,
                removeSpaces,
                addSpaces,
                startAllCapitilised,
                turnArrayToLines,
                removeAnItemFromAnArray,
                isNotEmpty,
                convertDateForScreen,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalStore;
