const today = new Date();
const todayYear = today.getFullYear();
const newYears = [];
for (let i = 0; i < 3; i++) {
    newYears.push({
        label: todayYear + i + "",
        value: todayYear + i + "",
    });
}
const newDays = [];
for (let i = 1; i < 31 + 1; i++) {
    newDays.push({ label: i + "", value: i + "" });
}

export const defaultDateOptions = {
    month: [
        { label: "Jan", value: "1" },
        { label: "Feb", value: "2" },
        { label: "Mar", value: "3" },
        { label: "Apr", value: "4" },
        { label: "May", value: "5" },
        { label: "Jun", value: "6" },
        { label: "Jul", value: "7" },
        { label: "Aug", value: "8" },
        { label: "Sep", value: "9" },
        { label: "Oct", value: "10" },
        { label: "Nov", value: "11" },
        { label: "Dec", value: "12" },
    ],
    year: newYears,
    day: newDays,
};

export const yesNoNA = [
    { value: "NA", label: "Not Applicable" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
];

export const yesNo = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
];

export const trueFalse = [
    { value: true, label: "True" },
    { value: false, label: "False" },
];