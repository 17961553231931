import React, { useContext, useState, useRef } from "react";
import { GlobalContext } from "contexts/GlobalStore.js";
import _ from "lodash";

export const MultipleTextInput = (props) => {
    const { data, keyName, updateData, viewMode } = props;
    const { placeHolder, fieldName, currentValue, errorText, informationText } = data;
    const { turnArrayToLines } = useContext(GlobalContext);
    const [localInputValue, setLocalInputValue] = useState();
    const [firstFocus, setFirstFocus] = useState(false);
    const inputZone = useRef();

    /**
     * A NEW ITEM ADDED
     * @description send new selected value through parent.
     */
    const anItemAdded = () => {
        let localInputValue2 = localInputValue?.trim();
        if (!localInputValue2) {
            return;
        }

        let newCurrentValue = _.cloneDeep(currentValue || []);
        if (newCurrentValue.indexOf(localInputValue2) >= 0) {
            // inputZone.current.value = "";
            setLocalInputValue("");
        } else {
            newCurrentValue.push(localInputValue2);
            updateData(keyName, newCurrentValue);
            setLocalInputValue("");
        }
    };

    /**
     * REMOVE AN ITEM
     * @description removes an item from Current values
     */
    const removeAnItem = (item) => {
        let newArray = (currentValue || []).filter((VAL) => {
            return VAL !== item;
        });
        updateData(keyName, newArray);
    };

    /**
     * PREPARE TYPED VALUES
     * @description prepares typedValues for currentValue
     */
    const typedValues = (currentValue || []).map((item, key) => {
        return (
            <React.Fragment key={key}>
                <div className="form_selected">{item}</div>
                <img
                    className="form_remove"
                    src={require("img/formClose.svg")}
                    onClick={() => {
                        removeAnItem(item);
                    }}
                    alt=""
                />
            </React.Fragment>
        );
    });

    //-------------------------------------------------------------
    // 		RETURN
    //-------------------------------------------------------------

    if (data.dontSendToApi && viewMode === "view") {
        return null;
    } else if (viewMode === "view") {
        return (
            <div className="view_multiple">
                <div>{fieldName}</div>
                <div>
                    {!currentValue
                        ? null
                        : currentValue.map((item, key) => {
                              return <span key={key}>{item}</span>;
                          })}
                </div>
            </div>
        );
    }

    return (
        <div
            id={keyName}
            className={"form_multi_textInput " + (errorText?.length > 0 ? "error" : "")}
        >
            {fieldName ? (
                <label
                    className={"form_label" + (errorText?.length > 0 ? " onLoadControlError" : "")}
                >
                    {fieldName}
                </label>
            ) : null}

            {typedValues.length > 0 ? typedValues : null}

            <input
                ref={inputZone}
                spellCheck="false"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder={placeHolder}
                value={localInputValue ? localInputValue : ""}
                onFocus={() => {
                    if (!firstFocus) {
                        setFirstFocus(true);
                    }
                }}
                onChange={(e) => {
                    setLocalInputValue(e.target.value);
                }}
                onKeyUp={(event) => {
                    if (event.key === "Enter") {
                        anItemAdded();
                    }
                    if (event.keyCode === 27) {
                        inputZone.current.blur();
                    }
                }}
            />
            <img
                className="form_add"
                src={require("img/formClose.svg")}
                alt=""
                onClick={() => {
                    anItemAdded();
                }}
            />
            {informationText ? <div className="form_infoText">{informationText}</div> : null}
            {firstFocus && errorText?.length > 0 ? (
                <div className="form_errorText">{turnArrayToLines(errorText)}</div>
            ) : null}
        </div>
    );
};
