import React from "react";

export const CheckBox = (props) => {
    const { text, checked, onChange } = props;

    return (
        <label className="sidebarContent_links_container">
            {text}
            <input
                type="checkbox"
                checked={checked ? checked : false}
                onChange={() => {
                    onChange();
                }}
            />

            <span className="sidebarContent_links_checkmark"></span>
        </label>
    );
};
